<section class="calender-chip" style="height: 0px;">
    <mat-form-field style="visibility: hidden; width: 0px !important;height: 0px !important;" class="example-full-width" appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
    <div class="calender-btn">{{ selectedDate }} <img class="calender-action-icon" (click)="picker.open()" src="assets/images/icons/calender.svg"></div>
</section>

