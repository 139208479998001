<section class="main-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div>
          <br/>
          <div class="row-header">
            <div class="col-lg-3">
              <div class="horizontal-line"></div>
              <h6 class="step-1">Step 1</h6>
              <h5 class="Coupon-code-title">Code & Validity</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line2"></div>
              <h6 class="Step-2">Step 2</h6>
              <h5 class="Service-type-Date">Discount Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 3</h6>
              <h5 class="Discount-type">Service Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 4</h6>
              <h5 class="Discount-type">Preview Coupon</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 b-g-white">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="Back">Back to previous screen</h6>
            <br>
            <label class="channel-type" for="">Select Services</label> <br/>
            <ng-multiselect-dropdown
              style="height: 45px; width: 100%; border-radius: 10px; padding-left: 15px; border-color: transparent; z-index: 1000; padding: 0px 50px;"
              (onSelect)="onItemSelect($event)"
              [settings]="dropdownSettings"
              (onSelectAll)="onSelectAll($event)"
              (onDeSelectAll)="onDeselectAll()"
              [data]="allServices"
              (onDeSelect)="onItemDeSelect($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <br>
        <div class="span-btn">
          <div class="btn-wrapper">
            <button class="Backk" (click)="goToUrl('add-coupons-step-2')">Back</button>
          </div>
          <div class="btn-wrapper">
            <button class="btn primary  next-btn" (click)="goToNextStep()">Save</button>
          </div>
        </div>
        <div *ngIf="message !== ''">
        <app-notifications [message]="message" [type]="messageType"></app-notifications>
      </div>
      </div>
      <div class="col-lg-2"></div>
      
    </div>
  </div>
</section>
