<app-back-btn></app-back-btn>
<div class="btn-container">
  <div class="btn-wrapper">
    <button class="btn-primary" (click)="print()">Print</button>
  </div>
</div>

<div class="barcode-container" id="barCodeContainer">
  <div class="barcode-item" *ngFor="let item of groupedClothDetails">
    <div class="store-name">{{ item.code }}</div>
    <div class="total-quantity name">{{ item.name }}</div>
    <div class="bookingType">{{ item.parenttradeName }}</div>
    <div class="totalquantity">{{ item.runningTotal }}/{{ item.serviceTotal }}</div>
    <div class="totalquantity">{{ item.delivery_date }}  {{ item.delivery_time }}</div>
  </div>
</div>