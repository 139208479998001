<div *ngIf="functionality === 1" class="add-runner">
  <div class="header">
    ADD RUNNER
  </div>
  <br />
  <div *ngIf="!typeOfMessage(message)">
    <app-notifications
      [message]="message"
      [type]="messageType"
    ></app-notifications>
  </div>
  <div *ngIf="typeOfMessage(message)">
    <div *ngFor="let e of message">
      <app-notifications [message]="e" [type]="1"></app-notifications>
    </div>
  </div>
  <div class="form-container">
    <div>
      <div class="form-flex">
        <div class="form-item">
          <div>Name<span class="text-danger">*</span></div>
          <input [(ngModel)]="name" />
        </div>
      </div>
      <div class="form-flex">
        <div class="form-item">
          <div>Phone No<span class="text-danger">*</span></div>
          <input
            type="number"
            class="inputField no-spinner"
            class="inputField"
            [(ngModel)]="mobile"
            name="mobile"
            #mobileInput="ngModel"
            (keyup)="handleChange($event, 'mobile')"
            required
            pattern="^((\\+91-?)|0)?[0-9]{10}$"
          />
        </div>
      </div>
      <div *ngIf="mobileInput.invalid && (mobileInput.dirty || mobileInput.touched)">
        <div *ngIf="mobileInput.errors?.required" class="warning-message">Phone number is required.</div>
        <div *ngIf="mobileInput.errors?.pattern" class="warning-message">Invalid phone number.</div>
      </div>
      <div class="form-item">
        <div>Password</div>
        <input type="password"  autocomplete="off" [(ngModel)]="password" id="runner-password" />
      </div>
      <div class="form-item">
        <input type="checkbox" (click)="showPassword()" style="width: auto;">
        <span style="position: relative; top: -12px; left: 6px;">Show Password</span>
      </div>
    </div>
  </div>
  <br />
  <div class="btn-wrapper">
    <button class="btn btn-primary" (click)="createRunner()">SAVE</button>
    <button class="btn btn-danger" (click)="closeModal()">CANCEL</button>
  </div>
</div>

<div *ngIf="functionality === 2" class="add-runner">
  <div class="header">EDIT RUNNER</div>
  <br />
  <div *ngIf="!typeOfMessage(message)">
    <app-notifications
      [message]="message"
      [type]="messageType"
    ></app-notifications>
  </div>
  <div *ngIf="typeOfMessage(message)">
    <div *ngFor="let e of message">
      <app-notifications [message]="e" [type]="1"></app-notifications>
    </div>
  </div>
  <div class="form-container">
    <div>
      <div class="form-flex">
        <div class="form-item">
          <div>Name<span class="text-danger">*</span></div>
          <input [(ngModel)]="name" />
        </div>
      </div>
      <div class="form-flex">
        <div class="form-item">
          <div>Phone No<span class="text-danger">*</span></div>
          <input [(ngModel)]="mobile" type="number" />
        </div>
        <div class="form-item">
          <div>Status<span class="text-danger">*</span></div>
          <!-- <input /> -->
          <select [(ngModel)]="status">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="btn-wrapper">
    <button class="btn btn-primary" (click)="updateRunner()">SAVE</button>
    <button class="btn btn-danger" (click)="closeModal()">CANCEL</button>
  </div>
</div>
