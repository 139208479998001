import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent {
  public user: any;
  public id: number | undefined;
  public email: string = '';
  public mobile: number;
  public name: string = '';
  public dob: string = '';
  public sex: string = '';
  public message: string = '';
  public messageType: number = -1;
  public assets = environment.assetUrl;
  isEmailValid: boolean = true;

  @Output() trigger = new EventEmitter<any>();
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    public dialogRef: MatDialogRef<UpdateUserComponent>
  ) {
    console.log('Received data:', data);
    if (data) {
      this.id = data.userId;
      this.user = data.user;
      console.log('Extracted id:', this.id);
      console.log('Extracted user:', this.user);
      this.updateUserDetails(this.user);
    } else {
      console.warn('Data is missing or invalid.');
    }
  }

  updateUserDetails(details: any) {
    if (details) {
      this.email = details.email || '';
      this.mobile = details.mobile || '';
      this.name = this.formatToTitleCase(details.name || '');
      this.dob = details.dob || '';
      this.sex = details.sex || '';
    } else {
      console.warn('User details are missing or invalid.');
    }
  }

  onNameChange(input: string) {
    this.name = this.formatToTitleCase(input);
    this.validateInput(this.name);
  }

  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    if (!/[a-zA-Z\s]/.test(char)) {
      event.preventDefault();
      this.errorMessage = 'Only alphabetic characters and spaces are allowed.';
    } else {
      this.errorMessage = '';
    }
  }

  formatToTitleCase(input: string): string {
    return input.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }

  validateInput(input: string) {
    if (/[^a-zA-Z\s]/.test(input)) {
      this.errorMessage = 'Only alphabetic characters and spaces are allowed.';
    } else {
      this.errorMessage = '';
    }
  }

  validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.isEmailValid = this.email === '' || emailPattern.test(this.email);
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log('Submitting with id:', this.id);
    if (this.id === undefined) {
      console.error('Cannot submit: User ID is undefined');
      this.message = 'Error: User ID is missing';
      this.messageType = 1;
      return;
    }

    const body = {
      email: this.email,
      name: this.name,
      dob: this.dob,
      sex: this.sex,
    };
  
    this.http
      .post(`${environment.SERVER_URL}webuser/${this.id}?franchise=${environment.FRANCHISE}`, body, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (data: any) => {
          this.message = data.message;
          this.messageType = 0;
          this.trigger.emit({ success: true });
          setTimeout(() => {
            this.close();
          }, 500);
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }

  onChangeSex(event: any) {
    this.sex = event.target.value;
  }

  typeOfMessage(type: string | string[]) {
    return Array.isArray(type);
  }

  ngOnInit(): void {
    console.log('Component initialized with id:', this.id);
  }
}