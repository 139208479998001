import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';
import {
  DRY_CLEANING_LIST,
  ORDER_LISTING,
} from '../../invoice/invoice-form/drycleaning-filter-constant';
import { ModalComponent } from 'src/app/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { UtilityService } from 'src/app/service/utility.service';
import * as saveAs from 'file-saver';
import html2pdf from 'html2pdf.js';
import { InvoiceService } from 'src/app/service/invoice.service';
import { ViewInvoiceComponent } from 'src/app/view-invoice/view-invoice.component';
import { ToastrService } from 'ngx-toastr';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-customer-order-listing',
  templateUrl: './customer-order-listing.component.html',
  styleUrls: ['./customer-order-listing.component.scss'],
})
export class CustomerOrderListingComponent implements OnInit {
  public isNewOrderListed = true;
  public drycleaningItemList = [];
  public orderListing = ORDER_LISTING;
  public orders: any = null;
  public type: string = 'NEW';
  public multipleBookings: any[] = [];
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;
  public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;
  public qrIcon = `${environment.assetUrl}assets/images/icons/qrcode.svg`;
  public tickCircle = `${environment.assetUrl}assets/images/icons/tick-circle.svg`;
  public tickSquare = `${environment.assetUrl}assets/images/icons/tick-square.svg`;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public sortingItems: any[] = [];
  public assets = environment.assetUrl;
  public sortQuery: string = '';
  public searchQuery: string = '';
  public filters: any[] = [];
  public selectedFilter: string = '';
  public sortLabel: string = '';
  public filterLabel: string = '';
  public finalAmount: number;

  public dateSortingOptions: string[] = ['Pickup date', 'Delivery Date'];
  public dateS: string = 'Select';
  public fromDate: string = '';
  public toDate: string = '';

  public message: any = '';
  public messageType: number = -1;

  public paymentOptions = [
    { value: 0, label: 'Pending' },
    { value: 1, label: 'COD' },
    { value: 2, label: 'PayTm' },
    { value: 3, label: 'NetBanking' },
    { value: 4, label: 'Cheque' },
    { value: 5, label: 'Not Done' },
  ];
  mobileView: boolean = false;
  dialog: any;
  orderObj: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(event.target.outerWidth <= 820) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }
  public sortingNEW: any[] = [];
  blackThemeDomain = 'store.whitetigercleaners.com';
  appDomain = '';
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogRef: MatDialog,
    private utils: UtilityService,
    private invoiceService: InvoiceService,
    private toastrService: ToastrService,
  ) {
    this.sortingItems = [
      { name: 'Pickup date DESC', value: 'PUDSC' },
      { name: 'Pickup date ASC', value: 'PUASC' },
      { name: 'Booking code DESC', value: 'OCDSC' },
      { name: 'Booking code ACS', value: 'OCACS' },
      { name: 'Invoice code ACS', value: 'INACS' },
      { name: 'Invoice code DESC', value: 'INDES' },
      { name: 'Drop date ACS', value: 'DDASC' },
      { name: 'Drop date DESC', value: 'DDDSC' },
    ];
    this.sortingNEW = [
      { name: 'Pickup date DESC', value: 'PUDSC' },
      { name: 'Pickup date ASC', value: 'PUASC' },
      { name: 'Booking code DESC', value: 'OCDSC' },
      { name: 'Booking code ACS', value: 'OCACS' },
      { name: 'Booking created at DESC', value: 'CADSC' },
      { name: 'Booking created at ASC', value: 'CAASC' },
    ];
    this.filters = [
      { name: 'Pickup assigned', value: '2' },
      { name: 'In Process', value: '3' },
      { name: 'Order ready', value: '4' },
      { name: 'Drop assigned', value: '5' },
      { name: 'Out for delivery', value: '6' },
      { name: 'Order completed', value: '7' },
    ];
  }
  sortDate(i: string) {
    this.dateS = i;
  }
  convertToDate(date:any) {
    return new Date() > new Date(date)
  }
  setDatesAndSort() {
    if (this.fromDate !== '' && this.toDate !== '' && this.dateS !== 'Select') {
      this.getData();
    }
  }
  renderPayment(mode: number) {
    const payment = this.paymentOptions.find((e) => e.value === mode);
    return payment?.label;
  }
  filter(filter: any) {
    this.selectedFilter = filter.value;
    this.filterLabel = filter.name;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }

  selectAll() {
    if (this.multipleBookings?.length !== 0) {
      this.multipleBookings = [];
    } else {
      this.orders.forEach((e: any) => {
        this.multipleBookings.push(e);
      });
    }
  }

  sort(q: any) {
    this.orders = null;
    this.sortQuery = q.value;
    this.sortLabel = q.name;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }
  ngOnInit(): void {
    this.appDomain = this.utils.extractDomainFromUrl(environment.domain);
    if(window.outerWidth <= 820) {
      this.mobileView = true;
    }
    document.documentElement.style.setProperty(
      '--search-bar-default-width',
      '40vw'
    );
    // if(window.location.search.back! =  == "true"){}
    if (window.location.search) {
      this.type = 'INPRO';
      this.isNewOrderListed = false;
    }
    this.getData();
  }
  printBarCode(id: number) {
    this.router.navigateByUrl(`barcode/${id}`);
  }
  updatePageNumber(event: any) {
    this.currentPage = event.page;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }
  handleImageError(event: any) {
    console.error('Image failed to load:', event);
  }
  
  handleImageLoad(event: any) {
    console.log('Image loaded successfully:', event);
  }
  renderIcons = (num: number) => {
   
    switch (num) {
      case 1:
        return `${environment.assetUrl}assets/images/icons/order-through/walk.svg`;
      case 2:
        return `${environment.assetUrl}assets/images/icons/order-through/phone.svg`;
      case 3:
        return `${environment.assetUrl}assets/images/icons/order-through/store.svg`;
      case 4:
        return `${environment.assetUrl}assets/images/icons/order-through/mobile.svg`;
      case 5:
        return `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`;
      case 6:
        return `${environment.assetUrl}assets/images/icons/order-through/whatsapp.svg`;
        default:
          console.warn(`Unexpected orderthrough_id: ${num}`);
          return ``;
    }
  }
  openConfirmation(
    id: number | Array<number>,
    postURL: string,
    title: string,
    body: { status: number },
    del: number = 0
  ) {
    const modal = this.dialogRef.open(ConfirmationModalComponent, {
      data: {
        bookingId: id,
        messageToDisplay: title,
        postURL,
        body,
        delete: del,
      },
    });
    modal.componentInstance.trigger.subscribe((data) => {
      this.getData();
    });
  }

  updateOrder(orderId: number | Array<number>) {
    this.openConfirmation(
      orderId,
      `bookings/updateStatus/${orderId}?franchise=${environment.FRANCHISE}`,
      'Are you sure you want to mark this order as Ready?',
      {
        status: 4,
      }
    );
  }
  
  complete(orderId: number) {
    console.log("Complete method called for orderId:", orderId);
    const dialogRef = this.openPaymentModal(orderId, this.finalAmount);
    dialogRef.afterClosed().subscribe(result => {
      console.log("Payment modal closed. Result:", result);
      if (result && result.statusResponse && result.statusResponse.status === 'success') {
        console.log("Order status update response:", result.statusResponse);
        this.updateOrderInList(orderId);
      } else {
        console.warn("Status not updated to 7, check statusResponse.");
      }
    });
  }
  openPaymentModal(bookingId: number, finalAmount: number) {
    // Find the specific order from orders array using bookingId
    const selectedOrder = this.orders.find(order => order.id === bookingId);
    
    console.log("Opening payment modal for bookingId:", bookingId);
    console.log("Selected order:", selectedOrder);
    
    // Use the final_amount from the selected order
    const orderFinalAmount = selectedOrder?.final_amount;
    
    return this.dialogRef.open(PaymentModalComponent, {
      width: '500px',
      data: { 
        bookingId: bookingId, 
        finalAmount: orderFinalAmount // Using the order's specific final amount
      }
    });
  }
  
  updateOrderInList(orderId: number) {
    console.log("Updating order status in the list for orderId:", orderId);
    const orderIndex = this.orders.findIndex(order => order.id === orderId);
    if (orderIndex !== -1) {
      this.orders[orderIndex].status = 7;
      console.log("Order status set to 7 for order at index:", orderIndex);
      this.orders = [...this.orders]; 
      this.orderObj = this.orders[orderIndex];
      console.log("Updated orderObj:", this.orderObj);
    } else {
      console.warn("Order not found in the list.");
    }
  }

  showSuccessModal() {
    this.dialog.open(SuccessModalComponent, {
      width: '300px'
    });
  }

  handleCancel(id: number) {
    this.openConfirmation(
      id,
      `bookings/updateStatus/${id}?franchise=${environment.FRANCHISE}`,
      'Please select the reason why you want to cancel this order',
      {
        status: 0,
      },
      1
    );
  }

  viewInvoice = (id: number) => {
    this.invoiceService.previewInvoice(id).subscribe((data) => {
      const reader = new FileReader();
      reader.readAsText(data);

      reader.onloadend = () => {
        this.dialogRef.open(ViewInvoiceComponent, {
            data: reader.result!.toString(),
            width: '230mm', 
            height: '230mm', 
            panelClass: 'a4-dialog'
        });
      };
    });
  }

  getFormattedDate(date: string) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const newDate = `${day} ${months[month]}`;
    if (isNaN(day) || isNaN(month)) {
      return '-';
    }
    return newDate;
  }
  lengthOfString(str: string) {
    if (!str) {
      return;
    } else {
      return str?.length;
    }
  }
  renderEntire(add: string, full: boolean) {
    if (!full) {
      return 'Read More';
    } else {
      return add.slice(62);
    }
  }

  openModal(
    id: number | number[],
    fetchURL: string,
    postURL: string,
    title: string
    ) {
    const modal = this.dialogRef.open(ModalComponent, {
      data: {
        fetchURL,
        postURL,
        bookingId: Array.isArray(id) ? id : id,
        title,
      },
    });

    modal.componentInstance.trigger.subscribe((data: any) => {
      this.multipleBookings = [];
      this.getData();
    });
  }
  isSelectedInMultiple(id: number) {
    if (this.multipleBookings.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
  assignPickup(id: number | number[]) {
    this.openModal(
      id,
      `runners?franchise=${environment.FRANCHISE}&page=1&status=1`,
      `bookings/assignPickup?franchise=${environment.FRANCHISE}`,
      'Assign Pickup'
    );
  }
  handleMultipleBookingsAssign() {
    this.assignPickup(this.multipleBookings.map(({ id }) => id));
  }
  handleSortOptions() {}
  validatePastDate(date:any) {
    if(new Date(date) >= new Date()) {
      return false;
    } else {
      return true;
    }
  }
  handleAddOrRemoveFromBookings(event: any, booking: any) {
    const isPresent = this.multipleBookings.find((e: any) => {
      return e.id === booking.id;
    });

    if (!isPresent) {
      this.multipleBookings.push(booking);
    } else {
      const index = this.multipleBookings.findIndex((e: any) => {
        return e.id === booking.id;
      });
      this.multipleBookings.splice(index, 1);
    }
  }
  handleSearch(e: any) {
    this.searchQuery = e.target.value;
    this.searchBooking();
  }
  renderFriendlyTimeSlot(time: string) {
    const timeOptions = [
      { value: '9 - 10', label: '9AM - 10AM' },
      { value: '10 - 11', label: '10AM - 11AM' },
      { value: '11 - 12', label: '11AM - 12PM' },
      { value: '12 - 13', label: '12PM - 1PM' },
      { value: '13 - 14', label: '1PM - 2PM' },
      { value: '14 - 15', label: '2PM - 3PM' },
      { value: '15 - 16', label: '3PM - 4PM' },
      { value: '16 - 17', label: '4PM - 5PM' },
      { value: '17 - 18', label: '5PM - 6PM' },
      { value: '18 - 19', label: '6PM - 7PM' },
      { value: '19 - 20', label: '7PM - 8PM' },
      { value: '20 - 21', label: '8PM - 9PM' },
    ];

    const matchingOption = timeOptions.find((option) => option.value === time);

    return matchingOption ? matchingOption.label : '';
  }
  searchBooking() {
    this.http
      .get(
        `${environment.SERVER_URL}bookings/search?franchise=${environment.FRANCHISE}&query=${this.searchQuery}&type=${this.type}&sortQuery=${this.sortQuery}&page=${this.currentPage}&filterQuery=${this.selectedFilter}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.orders = data.data;

        this.totalPages = data.totalPages;
      });
  }
  renderStatus(s: number): string {
    switch (true) {
      case s === 0:
        return 'Cancel';
      case s === 1:
        return 'New order';
      case s === 2:
        return 'Pickup assigned';
      case s === 3:
        return 'Invoice Generated';
      case s === 4:
        return 'Order ready';
      case s === 5:
        return 'Delivery Assigned';
      case s === 6:
        return 'Delivery Done';
      case s === 7:
        return 'Delivered';
    }
    return '';
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }
  displayTime = (datetime: string) => {
    if (datetime) {
      return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm A');  // Format as "30 Sep 2024, 11:06 AM"
    } else {
      return '';
    }      
  }
  formatTime = (date: string, time) => {
    if (!date || date === ''){
      return '';
    }
    const formattedDate = new Date(date);
    // //console.log(date);
    if (time){
      return moment(date).format('DD/MM/YYYY') + ' ' + time;
    } else{
      return moment(date).format('DD/MM/YYYY');
    }
    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1; // Months are zero-based
    const year = formattedDate.getFullYear();
    let hours = formattedDate.getHours();
    const minutes = formattedDate.getMinutes();
    const seconds = formattedDate.getSeconds();

    // Determine AM or PM
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour clock format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be displayed as 12

    // Pad single digits with leading zeros
    const formattedDay = this.padNumber(day);
    const formattedMonth = this.padNumber(month);
    const formattedHours = this.padNumber(hours);
    const formattedMinutes = this.padNumber(minutes);
    const formattedSeconds = this.padNumber(seconds);

    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amOrPm}`;
  }
  formatAddress = (address: string) => address.replace(/, ,/g, ',');
  getStatusDetails = (order: any) => {
    switch (order.status) {
      case 2:
        return order.pick_boy_assign_at;
      case 5:
        return order.drop_rider_assign_at;
    }
  }
  validateDate = (date: string) => {
    if (date === '0000-00-00') {
      return false;
    } else if (!date) {
      return false;
    }
    return true;
  }
  public gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }
  public resetFilters = () => {
    this.orders = null;
    this.sortQuery = '';
    this.currentPage = 1;
    this.selectedFilter = '';
    (document.querySelector('.search-user') as HTMLInputElement).value = '';
    this.toDate = '';
    this.fromDate = '';
    this.dateS = 'Select';
    this.multipleBookings = [];
    this.getData();
  }
  public getData = () => {
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${this.utils.getLocalStorageItem('token')}`
    );

    this.http
      .get(
        `${environment.SERVER_URL}bookings?franchise=${environment.FRANCHISE}&page=${this.currentPage}&type=${this.type}&sortQuery=${this.sortQuery}&filterQuery=${this.selectedFilter}&sortDate=${this.dateS}&sortFrom=${this.fromDate}&sortTo=${this.toDate}`,
        {
          headers,
        }
      )
      .subscribe((data: any) => {
        this.orders = data.data;
        this.totalPages = data.numberOfPages;
        if (data.data.length > 0) {
          this.finalAmount = data.data[0].final_amount;
          console.log("Final amount set in getData:", this.finalAmount);
        } else {
          console.log("No data found in response.");
        }
      });
  }



  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      return false;
    }
  }
  checkData(status: number, message: string) {
    this.message = '';
    this.messageType = -1;
    let validData = true;
    this.multipleBookings.forEach((e: any) => {
      if (e.status !== status) {
        validData = false;
      }
    });
    if (!validData) {
      this.message = message;
      this.messageType = 1;
    }
    return validData;
  }
  assignMultipleDrop() {
    const isDataValid = this.checkData(
      4,
      'Not all the bookings you selected can be assigned drop'
    );
    if (isDataValid) {
      const bookings = this.multipleBookings.map((e: any) => {
        return e.id;
      });
      this.assignDrop(bookings);
    }
  }
  assignMultiOrderReady = () => {
    const isDataValid = this.checkData(
      3,
      'Not all orders can be marked ready.'
    );
    if (isDataValid) {
      const booking = this.multipleBookings.map((e: any) => {
        return e.id;
      });
      this.updateOrder(booking);
    }
  }
  assignDrop = (id: number | number[]) => {
    this.openModal(id, `runners?franchise=${environment.FRANCHISE}&page=1&status=1`, `bookings/assignDrop?franchise=${environment.FRANCHISE}`, 'Assign Drop');
  }
}
