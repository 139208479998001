import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calender-select',
  templateUrl: './calender-select.component.html',
  styleUrls: ['./calender-select.component.scss']
})
export class CalenderSelectComponent implements OnInit {

  selectedDate = 'DD-MM-YYYY'
  constructor() { }

  ngOnInit(): void {
  }

  onDateChange(e: any) {
    this.selectedDate = new Date(e.target.value).toLocaleString("en-GB").split(',')[0].split('/').join('-');
  }

}
