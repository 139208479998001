<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      placeholder="Name"
      [(ngModel)]="name"
      name="name"
      (keyup)="handleChange($event, 'name')"
      required
    />
    <img src="{{ assetUrl }}assets/images/icons/name.svg" alt="name" />
  </div>
</section>

<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="number"
      class="inputField no-spinner"
      placeholder="Ph No *"
      [(ngModel)]="mobile"
      name="mobile"
      #mobileInput="ngModel"
      (keyup)="handleChange($event, 'mobile')"
      required
      pattern="^((\\+91-?)|0)?[0-9]{10}$"
    />
    <img src="{{ nameAsset }}" alt="name" />
    
  </div>
  <div *ngIf="mobileInput.invalid && (mobileInput.dirty || mobileInput.touched)">
      <div *ngIf="mobileInput.errors?.required" class="warning-message">Phone number is required.</div>
      <div *ngIf="mobileInput.errors?.pattern" class="warning-message">Invalid phone number.</div>
    </div>
</section>

<section class="input-container-section mt-30">
  <div class="input-container">
    <input

      type="text"
      class="inputField"
      placeholder="Email"
      [(ngModel)]="email"
      name="email"
      #emailInput="ngModel"
      (keyup)="handleChange($event, 'email')"
      required
      email
    />
    <img src="{{ assetUrl }}assets/images/icons/mail.svg" alt="name" />
  
  </div>
  <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
    <div *ngIf="emailInput.errors?.required" class="warning-message">Email is required.</div>
    <div *ngIf="emailInput.errors?.email" class="warning-message">Invalid email address.</div>
  </div>
</section>
