import { EventEmitter, Output, Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommentBoxComponent } from 'src/app/comment-box/comment-box.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-dry-cleaning-list',
  templateUrl: './dry-cleaning-list.component.html',
  styleUrls: ['./dry-cleaning-list.component.scss'],
})
export class DryCleaningListComponent implements OnInit {
  // public
  @Input() drycleaningList: any[] = [];
  public assets = environment.assetUrl;
  @Output() dryCleanItems = new EventEmitter();
  handleComments(index: number) {
    const dialog = this.dialogueRef.open(CommentBoxComponent);
    dialog.componentInstance.trigger.subscribe((data) => {
      console.log({ data });
      this.drycleaningList[index].note = data.note;
    });
  }
  constructor(private dialogueRef: MatDialog) {}
  ngOnInit(): void {}
  emitData() {
    this.dryCleanItems.emit(this.drycleaningList);
    console.log({ list: this.drycleaningList });
  }
  // handle change quantity based on index
  handleAddon(index: number, addon: any) {
    if (!this.drycleaningList[index].addon) {
      this.drycleaningList[index].addon = parseInt(addon.target.value);
    } else {
      this.drycleaningList[index].addon = 0;
    }
    this.emitData();
  }
  handleQty(index: number, value: number) {
    this.drycleaningList[index].quantity = value;
    this.emitData();
  }
  handleRemove(i: number) {
    this.drycleaningList.splice(i, 1);
    this.emitData();
  }
}
