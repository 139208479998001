import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { ApiService } from '../services/api.service';

interface ApiClothDetail {
  code: string;
  name: string;
  bookings_quantity: number;
  parenttrade: number;
  delivery_date: string | null;
  delivery_time: string | null;
  parenttradeName: string;
}

interface ProcessedClothDetail extends ApiClothDetail {
  runningTotal: number;
  serviceTotal: number;
}

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @ViewChild('barCodeContainer') barCodeContainer!: ElementRef;
  public bookingId: number = 0;
  public groupedClothDetails: ProcessedClothDetail[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private utils: UtilityService,
    private apiService: ApiService
  ) {}

  print() {
    const printContent = document.getElementById('barCodeContainer')?.innerHTML;
    const popupWin = window.open('', '_blank', 'width=1000,height=1000');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  color: black;
                  -webkit-print-color-adjust: exact;
                  print-color-adjust: exact;
                }
                #barCodeContainer {
                  width: 100%;
                }
                .barcode-item {
                  margin-left: 0;
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 140px;
                }
                .store-name {
                  font-size: 57px;
                  margin-bottom: 5px;
                  font-weight: bold;
                  margin-top: 10px;
                }
                .totalquantity {
                  font-size: 70px;
                  margin-bottom: 10px;
                  font-weight: bold;
                }
                .bookingType {
                  font-size: 55px;
                  margin-bottom: 10px;
                  font-weight: bold;
                  width: 100%;
                  box-sizing: border-box;
                  padding: 5px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .total {
                  font-size: 57px;
                  font-weight: bold;
                  margin-bottom: 5px;
                }
                .total-quantity {
                  font-size: 70px;
                  font-weight: bold;
                }
                .name {
                  margin-bottom: 20px;
                  font-weight: bold;
                }
                * {
                  font-weight: bold !important;
                }
              }
            </style>
          </head>
          <body onload="window.print();">
            ${printContent}
          </body>
        </html>`);
      popupWin.document.close();
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.bookingId = +params['id'];
      this.fetchData();
    });
  }

  fetchData() {
    this.apiService.generateBarcode(this.bookingId).subscribe((response) => {
      if (response.status === 'success') {
        this.processClothDetails(response.data.rows);
      }
    });
  }

  processClothDetails(clothDetails: ApiClothDetail[]) {
    const serviceGroups: { [key: string]: ApiClothDetail[] } = clothDetails.reduce((groups, item) => {
      const group = (groups[item.parenttradeName] || []);
      group.push(item);
      groups[item.parenttradeName] = group;
      return groups;
    }, {} as { [key: string]: ApiClothDetail[] });
  
    this.groupedClothDetails = [];
    
    Object.entries(serviceGroups).forEach(([service, items]) => {
      let runningTotal = 0;
      const serviceTotal = items.reduce((sum, item) => sum + item.bookings_quantity, 0);
      
      items.forEach(item => {
        for (let i = 0; i < item.bookings_quantity; i++) {
          runningTotal++;
          this.groupedClothDetails.push({
            ...item,
            runningTotal,
            serviceTotal
          });
        }
      });
    });
  }
}