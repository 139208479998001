import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input() address: string = '';
  public showAll: boolean = false;

  constructor() {}

  renderText(all: boolean) {
    if (this.address.length > 100) {
      return `${this.address.slice(0, 100)}...`;
    } else {
      return this.address;
    }
  }
  showAllText() {
    this.renderText(true);
    this.showAll = true;
  }
  close() {
    this.renderText(false);
    this.showAll = false;
  }
  ngOnInit(): void {}
}
