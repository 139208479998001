<div class="dry-cleaning-container">
  <section class="dry-cleaning-list-section">
    <div class="list-section">
      <div class="list-body">
        <div class="list-body-item">
          <div class="list-body-item-td checkbox-column">
            <div class="checkbox-option">
              <input
                (change)="handleChange($event, 'addon')"
                class="checkbox-input"
                type="checkbox"
                id="antisepticWash"
                name="antisepticWash"
                value="7"
              />
            </div>
          </div>
          <div class="list-body-item-td" style="width: 50%">
            <div class="input-container gray-text">
              <input
                type="text"
                [disabled]="'true'"
                value="{{ category }}"
                style="width: 50%"
              />
            </div>
          </div>
          <div class="list-body-item-td">
            <div class="input-container gray-text">
              <input (keyup)="handleChange($event, 'name')" type="text" />
            </div>
          </div>

          <div class="list-body-item-td">
            <app-counter (count)="handleChange($event, 'count')" [min]="1"></app-counter>
          </div>
          <div class="list-body-item-td">
            <div class="input-container">
              <input type="text" value="{{ price }}" [disabled]="true" />
            </div>
          </div>
          <!-- <div class="list-body-item-td">
            <img src="assets/images/icons/comment.svg" />
          </div> -->
        </div>
      </div>
    </div>
  </section>
</div>
