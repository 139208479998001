import { Output, Component, Input, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { Observable } from 'rxjs';
// import {} from 'stream';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
  public assets = environment.assetUrl;
  counter = 0;
  @Input() label: any;
  @Input() min: any = 0;
  @Output() count = new EventEmitter<any>();
  emitData() {
    this.count.emit(this.counter);
  }

  constructor() {}

  ngOnInit(): void {
    // console.log(this.min,'min')
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.min && this.min > 0){
      this.counter = this.min;
    }
  }
  // handleChange(type: string) {
  //   if (type === 'ADD') {
  //     this.counter += 1;
  //   } else {
  //     this.counter;
  //   }
  // }
  increase() {
    this.counter += 1;
    this.emitData();
  }

  decrease() {
    if (this.counter && this.counter >= 1) {
      this.counter = this.counter - 1;
      this.emitData();
    }
  }
}
