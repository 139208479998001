import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../service/utility.service';

interface filter {
  label: string;
  value: string;
}
@Component({
  selector: 'app-drop-customer-report',
  templateUrl: './drop-customer-report.component.html',
  styleUrls: ['./drop-customer-report.component.scss'],
})
export class DropCustomerReportComponent implements OnInit {
  constructor(private http: HttpClient, private utility: UtilityService) {}
  public data: any[] | null = [];
  public filter: string = '';
  public sortAmount: filter[] = [];
  public sortLastOrder: filter[] = [];
  public sortFirstOrder: filter[] = [];
  ngOnInit(): void {
    this.generateReport();
    this.sortAmount = [
      { label: 'Ascending', value: 'ACSAA' },
      { label: 'Descending', value: 'DCSAA' },
    ];
    this.sortLastOrder = [
      { label: 'Ascending', value: 'ACSLO' },
      { label: 'Descending', value: 'DESLO' },
    ];
    this.sortFirstOrder = [
      { label: 'Ascending', value: 'ACSFO' },
      { label: 'Descending', value: 'DESFO' },
    ];
  }
  onChange() {
    this.generateReport();
  }
  generateReport() {
    this.data = null;
    this.http
      .get(`${environment.SERVER_URL}stores/generateDCR?sort=${this.filter}`, {
        headers: {
          authorization: `Bearer ${this.utility.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.data = data.data;
      });
  }
}
