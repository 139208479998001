<section class="dry-cleaning-list-section">
  <div class="list-section">
    <div class="list-header">
      <div class="list-header-item">
        <div class="list-header-item-td"><p>Hanger</p></div>
        <div class="list-header-item-td"><p>Item</p></div>
        <div class="list-header-item-td"><p>Quantity</p></div>
        <div class="list-header-item-td"><p>Price</p></div>
        <div class="list-header-item-td"><p>Comment</p></div>
        <div class="list-header-item-td"><p>Cancel</p></div>
      </div>
    </div>
    <div class="list-body">
      <div
        class="list-body-item"
        *ngFor="let obj of drycleaningList; let i = index"
      >
        <div class="list-body-item-td checkbox-column">
          <div class="checkbox-option" (change)="handleAddon(i, $event)">
            <input
              class="checkbox-input"
              type="checkbox"
              id="antisepticWash"
              name="antisepticWash"
              value="7"
            />
          </div>
        </div>
        <div class="list-body-item-td">
          <div class="input-container gray-text">
            <input type="text" [disabled]="true" value="{{ obj.name }}" />
          </div>
        </div>
        <div class="list-body-item-td">
          <app-counter (count)="handleQty(i, $event)" [min]="1" ></app-counter>
        </div>
        <div class="list-body-item-td">
          <div class="input-container">
            <input
              [disabled]="true"
              type="text"
              value="{{
                obj.standard_price ? obj.standard_price : obj.premium_price
              }}"
            />
          </div>
        </div>
        <div class="list-body-item-td" (click)="handleComments(i)">
          <img src="{{ assets }}assets/images/icons/comment.svg" />
        </div>
        <div class="list-body-item-td" (click)="handleRemove(i)">
          <img src="{{ assets }}assets/images/icons/cross.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
