<div class="add-new-item">
  <div class="header">
    <h2>Add New Item</h2>
    <button class="close-btn" (click)="cancel()">×</button>
  </div>

  <div class="form-group">
    <label for="category-select">Choose Sub-Category</label>
    <select class="form-control" id="category-select" [(ngModel)]="selectedCategoryValue" (change)="onCategoryChange($event)">
      <option *ngFor="let category of categories" [value]="category.item_id">
        {{ category.item_text }}
      </option>
    </select>
  </div>

  <div class="form-row">
    <div class="form-group">
      <label for="item-name">Item Name</label>
      <input id="item-name" type="text" [(ngModel)]="itemName" placeholder="Curtain with Steam Press">
    </div>
    <div class="form-group">
      <label for="item-price">Item Price</label>
      <input id="item-price" type="number" [(ngModel)]="itemPrice" placeholder="₹500">
    </div>
  </div>

  <div class="form-group">
    <label>Item Quantity</label>
    <app-item-quantity [(quantity)]="itemQuantity"></app-item-quantity>
  </div>

  <div class="form-group">
    <div class="comment-section">
      <label>Comments</label>
      <div *ngIf="!showComments" class="add-comments" (click)="toggleComments()">
        Add Comments
      </div>
    </div>

    <div *ngIf="showComments" class="comments-area">
      <textarea [(ngModel)]="comments" placeholder="Lorem ipsum dolor sit amet..."></textarea>
      <div class="comment-actions">
        <button class="edit-btn">Edit</button>
        <button class="delete-btn">Delete</button>
      </div>
    </div>
  </div>

  <div class="actions">
    <button class="cancel-btn" (click)="cancel()">Cancel</button>
    <button class="add-btn" (click)="addingItem()">Add Item</button>
  </div>
</div>