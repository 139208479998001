import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-price-sidebar',
  templateUrl: './price-sidebar.component.html',
  styleUrls: ['./price-sidebar.component.scss'],
})
export class PriceSidebarComponent implements OnInit {
  @Output() trigger = new EventEmitter<any>();

  public activeTab = 1;
  public parenTrades = [];
  public ASSET_URL = environment?.assetUrl + 'assets/';
  public BASE_URL = environment.SERVER_URL;
  constructor(private http: HttpClient, private utils: UtilityService) { }
  emitData = (id: number) => {
    this.activeTab = id;
    this.trigger.emit({ service: this.activeTab });
  }
  ngOnInit(): void {
    this.getParenTrades();
  }

  getParenTrades = () => {
    const SERVICES_URL = `${this.BASE_URL}parenttrades`;
    this.http
      .get(
        SERVICES_URL,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.parenTrades = data.services;
      });
  }

  getIcon = (parenTrade) => {
    if (parenTrade.image_url){
      return this.ASSET_URL + parenTrade.image_url;
    }else{
      return this.ASSET_URL + 'services/' + parenTrade.name.replace(/\s+/g, '-').toLowerCase() + '.png';
    }
  }
}
