<section class="order-flow-section">
  <div class="fixed-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="searching-container">
            <div class="first-half-section">
              <div class="search-bar-section">
                <input
                  class="search-user"
                  (keyup)="handleSearch($event)"
                  placeholder="Search Mobile, Email, Name, Booking Code"
                />
              </div>
              <button
                class="resetBtn max-width"
                [matMenuTriggerFor]="sortMenu"
              >
                {{ sortQuery === "" ? "Sort by" : sortLabel }} &nbsp;<img
                  src="{{ dropIcon }}"
                  alt="dropdown-icon"
                />
              </button>
              
              <mat-menu #sortMenu="matMenu">
                <button mat-menu-item *ngFor="let option of sortingItems" (click)="sort(option)">
                  {{ option.name }}
                </button>
              </mat-menu>
              
              <div class="date-selection">
              <input
               
                type="date"
                [(ngModel)]="fromDate"
                [max]="today"
                (change)="setDatesAndSort()"
              />
          
              <input
                type="date"
                [(ngModel)]="toDate"
                [max]="today"
                (change)="setDatesAndSort()"
              />
            </div>
              <button class="resetBtn" (click)="resetFilters()">Reset</button>
            </div>

            <div class="second-half-section">
              <button
                mat-ripple
                class="add-order-btn"
                (click)="gotoUrl('order-flow/step1')"
              >
                Add Order <span class="vertical-line"></span>&nbsp;+
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="table-section">
            <div
              *ngIf="orders === null"
              class="loader-container"
              style="display: flex; justify-content: center"
            >
              <app-loader></app-loader>
            </div>
            <table *ngIf="orders !== null">
              <tr class="header-tr">
                <td class="header code"><b>Booking Code</b></td>
                <td class="header"><b>Instructions</b></td>
                <td class="header"><b>Customer</b></td>
                <td class="header"><b>Address</b></td>
                <td class="header"><b>Pickup</b></td>
                <td class="header" style="width: 10%"><b>Delivered</b></td>
                <td class="header"><b>Status</b></td>
              </tr>
              <tr
                class="table-row"
                *ngFor="let orderObj of orders; let i = index"
              >
                <td class="">
                  <div class="img-code">
                    <span>
                      <img src="{{ renderIcons(orderObj.orderthrough_id) }}" />
                    </span>
                    <span> {{ orderObj?.booking_code }}</span>
                  </div>

                  <button
                    (click)="viewInvoice(orderObj?.id)"
                    class="order-details"
                  >
                    Order details
                  </button>
                </td>
                <td class="address">
                  <span style="line-height: 20px">{{
                    orderObj?.suggestion ? orderObj?.suggestion : "NA"
                  }}</span>
                </td>
                <td class="customer">
                  <span class="name" style="line-height: 20px"
                    ><b>{{ orderObj?.name }}</b></span
                  ><span class="light-text">{{ orderObj?.mobile }}</span>
                  <span class="light-text">
                    {{ orderObj?.email }}
                  </span>
                </td>
                <td>
                  <app-address [address]="orderObj?.address"></app-address>
                </td>
                <td class="date" style="width: 10%">
                  <span>{{ displayTime(orderObj?.pickupDate) }}</span>
                </td>

                <td class="date" style="width: 10%">
                  <span>{{ displayTime(orderObj?.delivered_at) }}</span>
                </td>
                <td>
                  <span
                    [class]="orderObj?.status === 7 ? 'completed' : 'cancelled'"
                  >
                    {{ renderStatus(orderObj?.status) }}
                  </span>
                  <br />
                  <br />

                  <p class="date">{{ displayTime(orderObj?.delivered_at) }}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div style="display: flex; justify-content: flex-end">
        <p>
          Showing results {{ (currentPage - 1) * 30 }} to {{ currentPage * 30 }}
        </p>
      </div>
      <br />
      <div class="pagination-container">
        <app-pagination
          (page)="updatePageNumber($event)"
          [totalPages]="totalPages"
        ></app-pagination>
      </div>
    </div>
  </div>
</section>