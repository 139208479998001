<section class="customer-directory-section">
  <div class="container"></div>
  <div class="container">
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div
            style="display: flex; justify-content: center"
            *ngIf="data.length === 0"
            class="loader-container"
          >
            <app-loader></app-loader>
          </div>
          <table *ngIf="data.length !== 0">
            <tr class="header-tr">
              <td class="header" style="width: 15%">Name</td>
              <td class="header" style="width: 10%">Amount</td>

              <td class="header" style="width: 10%">Quantity</td>
              <td class="header" style="width: 10%">Validity</td>
              <td class="header" style="width: 10%">Created at</td>
              <td class="header" style="width: 07%">Terms</td>
            </tr>
            <!-- <div class="table-body-wrapper"> -->
            <tr *ngFor="let e of data; let i = index">
              <td style="width: 15%">
                <span
                  ><b class="green-text">{{ e.name }}</b></span
                >
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.amount }}</span
                ><br />
              </td>
              <td style="width: 10%">
                <span class="txt-align-center"> {{ e.quantity }}</span
                ><br />
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.validity }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center" matRipple>
                  {{ e.created_at | date }}
                </span>
              </td>
              <td style="width: 40%">
                <span class="txt-align-center" style="text-align: center">{{
                  e.terms
                }}</span>
              </td>
              <!-- <td style="width: 7%">
                <span class="txt-align-center">{{ e.including_gst }}</span>
              </td>

              <td style="width: 7%">
                <span class="txt-align-center">{{ e.excluding }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.final_amount }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.payment_status }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.amount }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.pickup_date | date }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.address }}</span>
              </td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
