import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gst-date-component',
  templateUrl: './gst-date-component.component.html',
  styleUrls: ['./gst-date-component.component.scss']
})
export class GstDateComponentComponent implements OnInit {

  @Output() dateRangeSelected = new EventEmitter<{ from: string, to: string }>();

  dropdownVisible = false;
  startDate: Date | null = null;
  endDate: Date | null = null;
  showCalendar = false;
  selected: any;
  public calendar = `${environment.assetUrl}assets/images/calendar-event-create.svg`

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  onStartDateChange(event: Date) {
    this.startDate = event;
  }

  onEndDateChange(event: Date) {
    this.endDate = event;
  }

  applyDateRange() {
    if (this.startDate && this.endDate) {
      // Set time to start of day for startDate and end of day for endDate
      const fromDate = new Date(this.startDate);
      fromDate.setHours(0, 0, 0, 0);

      const toDate = new Date(this.endDate);
      toDate.setHours(23, 59, 59, 999);

      this.dateRangeSelected.emit({
        from: this.formatDateForApi(fromDate),
        to: this.formatDateForApi(toDate)
      });
    }
    this.dropdownVisible = false;
  }

  cancelSelection() {
    this.startDate = null;
    this.endDate = null;
    this.dropdownVisible = false;
  }

  exportReport() {
    // Implement export functionality
  }

  constructor() { }

  ngOnInit(): void {
  }

  getDateRangeString(): string {
    if (this.startDate && this.endDate) {
      return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
    }
    return '';
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  formatDateForApi(date: Date): string {
    return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  }
}