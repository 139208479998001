<div mat-dialog-title>
  <div class="dialog-actions">
    <div class="left-actions">
      <!-- Add any left-aligned elements here -->
      <h2>Invoice Preview</h2>
    </div>
    <div class="right-actions">
      <button mat-button (click)="onClose()">Close</button>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div id="invoice" #invoice [innerHTML]="htmlContent"></div>
</div>
<div mat-dialog-actions style="margin-top:35px;justify-content: space-around;">
  <button class="btn primary" (click)="openPDF()">Download</button>
  <button class="btn primary-large" (click)="printWithHeaderAndFooter()">
    Print with Header and Footer
  </button>
  <button class="btn primary-large" (click)="printWithoutHeaderAndFooter()">
    Print without Header and Footer
  </button>
</div>
