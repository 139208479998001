import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit {
  id: string = '';
  address: any = {};
  message: any = '';
  @Output() trigger = new EventEmitter<any>();
  public messageType: number = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<AddAddressComponent>
  ) {
    console.log(data);
    this.id = data.id;
  }
  reciveDataFromChild(add: any) {
    this.address = add;
  }
  close() {
    this.dialogRef.close();
  }
  emitTrigger() {
    this.trigger.emit({ update: true });
  }
  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      false;
    }
    return;
  }
  submit() {
    this.message = '';
    this.messageType = -1;
    console.log(this.id);

    this.address.webuser_id = this.id;
    this.apiService.submitAddress(this.address).subscribe(
      (data: any) => {
        this.message = data.message;
        this.messageType = 0;
        this.emitTrigger();
        setTimeout(() => {
          this.close();
        }, 500);
        setTimeout(() => {
          this.close();
        }, 5000);
      },
      (err) => {
        this.message = err.error.message;
        this.messageType = 1;
      }
    );
  }
  ngOnInit(): void {}
}
