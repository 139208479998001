import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from 'src/environments/environment';
import {UtilityService} from '../../../../service/utility.service';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-order-detail-form',
  templateUrl: './order-detail-form.component.html',
  styleUrls: ['./order-detail-form.component.scss'],
})
export class OrderDetailFormComponent implements OnInit {
  // multi select options and storage
  BASE_URL: string = environment.SERVER_URL;
  ASSET_BASE_URL: string = environment.assetUrl;
  dropdownList: any = [];
  dropdownSettings: IDropdownSettings = {};
  dropIcon = `${this.ASSET_BASE_URL}../../../../../assets/images/icons/dropdown.svg`;

  // order through storage
  orderThrough: any = [];

  // Component state
  private services: any = [];
  private pickupTime = '';
  private pickupDate = '';
  private pickupId = '';
  public suggestions = '';
  public orderThroughId!: number;
  public availableDates: any = [];
  public slots: any = [];
  public timeslots: any = [];
  @Output() order = new EventEmitter<any>();
  constructor(private http: HttpClient, private utils: UtilityService , private apiService: ApiService) {}
  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      noDataAvailablePlaceholderText: 'There is no item available to show',
      allowSearchFilter: true,
    };
    this.getServices();
    this.getOrderSources();
  }
  setTimeSlots = () => {
    const todayDate = new Date();
    const month = (todayDate.getMonth() + 1);
    const day = todayDate.getDate();
    const todayDateStr = '' + todayDate.getFullYear() + '-' + (month < 10 ? ('0' + month) : month) + '-' + (day < 10 ? ('0' + day) : day);
    // tslint:disable-next-line:forin
    for (const ind in this.slots){
      const slot = this.slots[ind];
      if (slot.date === this.pickupDate && this.timeslots.indexOf(slot.combine_time) < 0){
        if (slot.date === todayDateStr){
          if (slot.start_time > (todayDate.getHours() + 2)) {
            this.timeslots.push(slot.combine_time);
          }
        }else {
          this.timeslots.push(slot.combine_time);
        }
      }
    }
  }
  setPickupId = () => {
    // tslint:disable-next-line:forin
    for (const ind in this.slots){
      const slot = this.slots[ind];
      if (slot.date === this.pickupDate && slot.combine_time === this.pickupTime){
        this.pickupId = slot.id;
      }
    }
  }
  emitData = () => {
    let bookingString = '';
    this.services.forEach((e: any, i: number) => {
      if (i === 0) {
        bookingString += e.item_text;
      } else {
        bookingString += `,${e.item_text}`;
      }
    });
    this.order.emit({
      pickup_id: this.pickupId,
      pickup_time: this.pickupTime,
      pickup_date: this.pickupDate,
      booking_type: bookingString,
      orderthrough_id: this.orderThroughId,
      suggestion: this.suggestions,
    });
  }
  handleOnChange = (v: any, p: string) => {
    const value = v.target.value;

    switch (true) {
      case p === 'PD': // pickup date
        this.pickupDate = value;
        this.setTimeSlots();
        break;
      case p === 'PT': // pickup time
        this.pickupTime = value;
        this.setPickupId();
        break;
      case p === 'OM': // Order Mode
        this.orderThroughId = parseInt(value, 10);
        break;
    }
  }
  onItemSelect = (item: any) => {
    this.services.push(item);
    this.getSlots();
    this.emitData();
  }
  onItemDeSelect = (item: any) => {
    const index = this.services.findIndex((el: any) => {
      return el.item_id === item.item_id;
    });
    this.services.splice(index, 1);
    this.getSlots();
    this.emitData();
  }
  onSelectAll = (event: any) => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dropdownList.length; i++) {
      const service = this.dropdownList[i];
      if (this.services.indexOf(service) < 0) {
        this.services.push(service);
      }
    }
    this.getSlots();
    this.emitData();
  }
  onDeselectAll = () => {
    this.services = [];
    this.getSlots();
    this.emitData();
  }
  // API CALLS
  getServices = () => {
    this.http
      .get(`${this.BASE_URL}parenttrades?franchise=${environment.FRANCHISE}`)
      .subscribe((data: any) => {
        const formattedData = data.services.map((el: any) => {
          return { ...el, item_id: el.id, item_text: el.name };
        });
        this.dropdownList = formattedData;
      });
  }
  getOrderSources = () => {
    console.log('Franchise:', environment.FRANCHISE);  // Log the current franchise
    
    this.apiService.getOrderthroughs().subscribe((data: any) => {
      // Modify orderThrough data if franchise is White Tiger
      this.orderThrough = data.data.map((item: any) => {
        if (environment.FRANCHISE === 'WHITETIGER' && item.name === 'UClean Store') {
          item.name = 'WT Store';
        }
        return item;
      });
    });
  };
  
  
  getSlots = () => {
    this.timeslots = [];
    this.availableDates = [];
    this.slots = [];
    this.pickupId = '';
    this.pickupDate = '';
    this.pickupTime = '';
    const serviceIds = [];
    if (this.services && this.services.length > 0){
      // tslint:disable-next-line:forin
      for (const index in this.services){
        const service = this.services[index];
        serviceIds.push(service.item_id);
      }
      this.http.get(`${this.BASE_URL}stores/getSlots?franchise=${environment.FRANCHISE}`, {
          params: {
            services: serviceIds,
          },
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      ).
      subscribe((data: any) => {
        this.slots = data.slots;
        // tslint:disable-next-line:forin
        for (const ind in data.slots){
          const slot = data.slots[ind];
          if (this.availableDates.indexOf(slot.date) < 0){
            this.availableDates.push(slot.date);
          }
        }
      });
    }

  }
}
