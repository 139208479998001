import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponsService } from 'src/app/service/coupons.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from 'src/app/service/utility.service';
@Component({
  selector: 'app-add-coupon-step1',
  templateUrl: './add-coupon-step1.component.html',
  styleUrls: ['./add-coupon-step1.component.scss'],
})
export class AddCouponStep1Component implements OnInit {
  public couponTitle = '';
  public query = '';
  public discountCode = '';
  public description = '';
  public message = '';
  public startDate = '';
  public endDate = '';
  public messageType = 1;
  public minDate: string;
  public selectedTemplateIndex: number | null = null;
  data: any;
  public showTemplateSection: string;
  public couponCodeExists = false;
  constructor(private router: Router, private couponService: CouponsService, public apiService: ApiService,public http: HttpClient,public utils: UtilityService) {
    this.minDate = new Date().toISOString().split('T')[0];
  }

  templateImages = [
   `${environment.assetUrl}assets/images/image.svg`
  ];

  onTemplateSelect(index: number): void {
    this.selectedTemplateIndex = index === 0 ? 0 : null;
  }

  setErrors = (title: string) => {
    this.message = title;
    this.messageType = 1;
  }
  getCoupons = () => {
    this.apiService.getCoupons().subscribe(
      (data: any) => {
        console.log({ data });
        this.data = data.data;
      },
      error => {
        console.error('Error fetching coupons:', error);
      }
    );
  }
  checkCouponCode() {
    this.discountCode = this.discountCode.toUpperCase();
    this.checkCouponExists();
  }

  checkCouponExists() {
    if (!this.discountCode) {
      this.couponCodeExists = false;
      return;
    }

    this.apiService.searchCoupons(this.discountCode).subscribe(
      (coupons) => {
        this.couponCodeExists = this.matchCouponCode(coupons);
      },
      (error) => {
        console.error('Error checking coupon:', error);
        this.couponCodeExists = false;
      }
    );
  }
  matchCouponCode(coupons: any[]): boolean {
    if (!coupons || coupons.length === 0) return false;

    return coupons.some(coupon => {
      const discountCode = coupon.discount_code.toUpperCase();
      return this.discountCode === discountCode;
    });
  }
  filterSpecialCharacters(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    // Regular expression to match special characters
    const specialCharsRegex = /[^a-zA-Z0-9]/;
    
    if (specialCharsRegex.test(char)) {
      // Prevent the default action if a special character is detected
      event.preventDefault();
    }
  }
  
  validateData(): boolean {
    if (this.couponTitle === '') {
      this.setErrors('Coupon title cannot be empty');
      return false;
    }
    if (this.description === '') {
      this.setErrors('Description cannot be empty');
      return false;
    }
    if (this.discountCode === '') {
      this.setErrors('Discount code cannot be empty');
      return false;
    }
    if (this.startDate === '') {
      this.setErrors('Start date cannot be empty');
      return false;
    }
    if (this.endDate === '') {
      this.setErrors('End date cannot be empty');
      return false;
    }
    if (this.startDate > this.endDate) {
      this.setErrors('Start date cannot be greater than end date');
      return false;
    }
    if (this.couponCodeExists) {
      this.setErrors('Coupon code already exists');
      return false;
    }
    if (!this.showTemplateSection) {
      this.setErrors('Channel Type cannot be empty');
      return false;
    }
    return true;
  }

  goToUrl = (url: string) => {
    this.message = '';
    this.messageType = -1;
    console.log(this.message);
    const dataValid = this.validateData();
    if (!dataValid) {
      return;
    } else {
      const step1Data = {
        couponTitle: this.couponTitle,
        description: this.description,
        discountCode: this.discountCode,
        start: this.startDate,
        end: this.endDate,
        showTemplateSection: this.showTemplateSection,
        selectedTemplateImage: this.showTemplateSection === '1' ? this.templateImages[0] : null
      };
      this.couponService.setData(step1Data, 1);
      this.router.navigateByUrl(url);
    }
  }
  ngOnInit(): void {
    console.log('ngOnInit called');
    this.couponService.getData(1)?.subscribe((data) => {
      console.log('Data received:', data);
      if (!data) {
        console.log('No data received');
        return;
      } else {
        this.couponTitle = data.couponTitle;
        this.discountCode = data.discountCode;
        this.description = data.description;
        this.startDate = data.start;
        this.endDate = data.end;
        this.showTemplateSection = data.showTemplateSection;
        if (this.showTemplateSection === '1') {
          this.selectedTemplateIndex = 0; // Since we're always using the first template for online
        } else {
          this.selectedTemplateIndex = null;
        }
      }
    });
  }
}
