import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { StoreResponse } from '../shared/models/StoreResponse';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  public cloths: any[] = [];
  public services: any = {};
  public addons: any[] = [];
  public selectedService = 1;
  public insights: StoreResponse[];
  public searchText: any;
  public BASE_URL = environment.SERVER_URL;

  constructor(private http: HttpClient, private utils: UtilityService) { }
  ngOnInit(): void {
    this.getInsights();
  }
  receiveDataFromChild = (data: any) => {
    this.selectedService = data.service;
    this.getInsights();
  }
  getData = () => {
    const PRICING_API_URL = `${this.BASE_URL}services/${this.selectedService}/pricing?franchise=${environment.FRANCHISE}`;
    this.http
      .get(
        PRICING_API_URL,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.cloths = data.cloths;
        this.services = data.service;
        this.addons = data.addons;
      });
  }
  // renderIcons = (id: number) => {
  //   switch (id) {
  //     case 1:
  //       return `../../assets//images/icons/pricing/men.svg`;
  //     case 2:
  //       return `../../assets//images/icons/pricing/women.svg`;
  //     case 3:
  //       return `../../assets//images/icons/pricing/household.svg`;
  //     case 4:
  //       return `../../assets//images/icons/pricing/kids.svg`;
  //     case 6 || 7 || 8 || 9:
  //       return `../../assets//images/icons/pricing/luxury.svg`;
  //   }
  //   return;
  // }
  getInsights = () => {
    console.log("this is playinggbwruwre");
    this.http
      .get(`${this.BASE_URL}stores/getInsights?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data.data);
        this.insights = data.data;
        this.getData();
      });
  }
}
