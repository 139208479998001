<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="searching-container">
        <div class="first-half-section">
          <div class="search-bar-section">
            <input
              class="search-user"
              placeholder="Search user with mobile number"
              (keyup)="handleSearch($event)"
            />
          </div>
          <button class="sort-button" [matMenuTriggerFor]="menu">
            Sort by &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="sortData(i.value)"
              *ngFor="let i of sortingItems"
            >
              {{ i.name }}
            </button>
          </mat-menu>
        </div>
        <div class="second-half-section"></div>
      </div>
    </div>
  </div>
</div>
<section class="customer-directory-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div *ngIf="users === null" class="loader-container">
            <app-loader></app-loader>
          </div>
          <table *ngIf="users !== null">
            <tr class="header-tr">
              <td class="header name"><b>Customer name</b></td>
              <td class="header contact">
                <b>Contact details</b>
              </td>
              <!-- <td class="header"><b>Address</b></td> -->
              <td class="header totalBookings"><b>Total bookings</b></td>
              <td class="header totalValue"><b>Total value</b></td>
              <td class="header customerSince"><b>Customer since</b></td>
              <td class="header"><b>Last order</b></td>
              <td class="header actions"><b>Actions</b></td>
            </tr>

            <!-- <div class="table-body-wrapper"> -->
            <tr *ngFor="let orderObj of users; let i = index">
              <td class="name">
                <span
                  ><b class="green-text">{{
                    orderObj.name ? orderObj.name : "-"
                  }}</b></span
                >
              </td>
              <td class="contact">
                <span>{{ orderObj.mobile }}</span
                ><br /><span class="light-text">{{
                  orderObj.email ? orderObj.email : ""
                }}</span>
              </td>
              <td class="totalBookings">
                <span class="light-text">{{ orderObj.total_bookings }}</span>
              </td>
              <td class="totalValue">
                <button class="dark-btn" matRipple>
                  ₹{{
                    orderObj.total_value_ordered
                      ? formatNumber(orderObj.total_value_ordered)
                      : 0
                  }}
                </button>
              </td>
              <td class="customerSince">
                <span class="txt-align-center">{{
                  getFormattedDate(orderObj.created_at)
                }}</span>
              </td>
              <td>
                <span class="txt-align-center">{{
                  getFormattedDate(orderObj.latest_booking_date)
                    ? getFormattedDate(orderObj.latest_booking_date)
                    : "-"
                }}</span>
              </td>

              <td class="actions-row">
                <p
                  class="link-btn"
                  (click)="goToUrl('dashboard/user/', orderObj.id)"
                >
                  View
                </p>
              </td>
            </tr>
            <!-- </div> -->
          </table>
        </div>
      </div>
    </div>
    <br />
    <div style="display: flex; justify-content: flex-end">
      <p *ngIf="totalPages !== 1">
        Showing results {{ (currentPage - 1) * 30 }} to
        {{ currentPage * 30 }} Out of {{ totalPages * 30 }}
      </p>
      <p *ngIf="totalPages === 1">Showing results 0 To {{ users.length }}</p>
    </div>
    <br />
    <div class="pagination-container">
      <app-pagination
        (page)="updatePageNumber($event)"
        [totalPages]="totalPages"
      ></app-pagination>
    </div>
    <br />
  </div>
</section>
