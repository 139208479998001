import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/service/utility.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-amount-modal',
  templateUrl: './amount-modal.component.html',
  styleUrls: ['./amount-modal.component.scss']
})
export class AmountModalComponent implements OnInit {
  paymentModes: Array<{ item_id: number, item_text: string, amount: number }> = [];
  amounts: {[key: string]: number} = {};
  selectedModesText: string = '';
  public bookingId: number;
  finalAmount: number;
  totalAmount: number = 0;
  showReasonInput: boolean = false;
  reason: string = '';

  constructor(
    public dialogRef: MatDialogRef<AmountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private dialog: MatDialog,
    private utils: UtilityService,
    private apiService: ApiService,
  )
  {
    this.paymentModes = data.modes;
    this.bookingId = data.bookingId;
    this.finalAmount = data.finalAmount;
  }


  ngOnInit() {
    this.paymentModes = this.data.modes.map(mode => ({...mode, amount: 0}));
    this.selectedModesText = this.data.modes.map(mode => mode.item_text).join(', ');
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  
  calculateTotal() {
    this.totalAmount = Object.values(this.amounts).reduce((sum, amount) => sum + (amount || 0), 0);
    
    // Implement the validation logic
    if (this.totalAmount < this.finalAmount) {
      this.showReasonInput = true;
    } else {
      this.showReasonInput = false;
      this.reason = '';
    }
  }

  onSubmit() {
    const paymentModes = this.paymentModes.map(mode => ({
      paymentMode: mode.item_id,
      amount: this.amounts[mode.item_text] || 0
    }));
  
    const payload: any = {
      paymentModes: paymentModes
    };
  
    if (this.showReasonInput && this.reason) {
      payload.reason = this.reason;
    }
  
    this.apiService.processPayment(this.bookingId, payload).subscribe(
      (paymentResponse: any) => {
        this.updateOrderStatus(paymentResponse);
      },
      (error) => {
        console.error('Error processing payment', error);
      }
    );
  }


updateOrderStatus(paymentResponse: any) {
  this.apiService.updateOrderStatus(this.bookingId, 7).subscribe(
    (statusResponse: any) => {
      this.dialogRef.close({ paymentResponse, statusResponse });
      this.showSuccessModal(paymentResponse, statusResponse);
    },
    (error) => {
      console.error('Error updating order status', error);
    }
  );
}

  showSuccessModal(paymentResponse: any, statusResponse: any) {
    this.dialog.open(SuccessModalComponent, {
      width: '500px',
      data: {
        paymentResponse,
        statusResponse,
        message: 'Payment processed and order completed successfully!'
      }
    });
  }
}
