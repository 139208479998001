import { Component, Inject, OnInit } from '@angular/core';
import {  ViewChild, ElementRef } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AmountModalComponent } from '../amount-modal/amount-modal.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/service/utility.service';
import { HttpClient } from '@angular/common/http';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {
  @ViewChild('modalContainer') modalContainer: ElementRef;
  paymentModes: Array<{ item_id: number, item_text: string }> = [
    { item_id: 1, item_text: 'UPI/Wallet App' },
    { item_id: 2, item_text: 'Credit/Debit card' },
    { item_id: 3, item_text: 'Bank Transfer' },
    { item_id: 4, item_text: 'Cash' }
  ];
  selectedModes: Array<{ item_id: number, item_text: string }> = [];
  dropdownSettings: IDropdownSettings = {};
  bookingId: number;
  isPending: boolean = false;
  finalAmount: number;

  constructor(
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilityService,
    private http: HttpClient,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.bookingId = data.bookingId;
    this.finalAmount = data.finalAmount;
  }
  onDropdownOpen() {
    this.modalContainer.nativeElement.style.height = '390px';
  }

  onDropdownClose() {
    this.modalContainer.nativeElement.style.height = '300px';
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 4,
      allowSearchFilter: false
    };
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  
  updateOrderStatus() {
    return this.apiService.updateOrderStatus(this.bookingId, 7);
  }
  
  onNext(): void {
    if (this.isPending) {
      this.updateOrderStatus().subscribe(
        (statusResponse: any) => {
          console.log("Order status update successful:", statusResponse);
          this.dialogRef.close({ statusResponse });
          this.openSuccessModal(statusResponse);
        },
        (error) => {
          console.error('Error updating order status', error);
          // Handle error (e.g., show an error message to the user)
        }
      );
    } else if (this.selectedModes.length > 0) {
      const amountDialogRef = this.dialog.open(AmountModalComponent, {
        width: '500px',
        data: {
          modes: this.selectedModes,
          bookingId: this.bookingId,
          finalAmount: this.finalAmount
        }
      });
  
      amountDialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(result);
      });
    }
  }

  onPendingChange(): void {
    if (this.isPending) {
      this.selectedModes = [];
    }
  }

  private openSuccessModal(statusResponse: any): void {
    const successDialogRef = this.dialog.open(SuccessModalComponent, {
      width: '500px',
      data: {
        paymentResponse: null, // No payment processed for pending orders
        statusResponse: statusResponse,
        message: 'Order marked as pending and completed successfully!'
      }
    });

    successDialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close({ success: true, statusResponse: statusResponse });
    });
  }
}