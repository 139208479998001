<section class="order-flow-section">
  <div class="container">
    <div class="searching-filter-section">
      <div class="right-section">
        <div class="group-section">
          <button
            mat-ripple
            class="group-item"
            [ngClass]="{ active: typeOfCoupon === 0 }"
          >
            Percentage
          </button>
          <button
            mat-ripple
            class="group-item"
            [ngClass]="{ active: typeOfCoupon === 1 }"
          >
            Flat rate
          </button>
        </div>
      </div>
    </div>
    <div class="main-form-container">
      <h3>CREATE COUPON</h3>
      <br />
      <div class="form-container" *ngIf="typeOfCoupon === 0">
        <form>
          <div class="form-row">
            <div class="field-container">
              <div>Discount Type</div>
              <select>
                <option>%</option>
                <option>Flat rate</option>
              </select>
            </div>
            <div class="field-container">
              <div>Minimum order value</div>
              <input type="number" />
            </div>
            <div class="field-container">
              <div>Maximum discount value</div>
              <input type="number" />
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="field-container">
              <div>Service type</div>
              <select>
                <option>%</option>
                <option>Flat rate</option>
              </select>
            </div>
            <div class="field-container">
              <div>Order no</div>
              <!-- <input type="number" /> -->
              <select>
                <option>OPT1</option>
                <option>OPT2</option>
              </select>
            </div>
            <div class="field-container">
              <div>Channel Type</div>
              <!-- <input type="number" /> -->
              <select>
                <option>OPT1</option>
                <option>OPT2</option>
              </select>
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="field-container">
              <div>Start Date</div>
              <input
                placeholder="ZYA"
                onfocus="(this.type = 'date')"
                type="date"
                [placeholder]="'myPlaceholderText'"
              />
            </div>
            <div class="field-container">
              <div>End Date</div>
              <input
                placeholder="ZYA"
                onfocus="(this.type = 'date')"
                type="date"
                [placeholder]="'myPlaceholderText'"
              />
            </div>
            <div class="field-container">
              <div>Coupon Code</div>
              <input type="text" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
