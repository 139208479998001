<section class="customer-directory-section" style="margin-top: 100px">
  <div class="container">
    <div class="field-container">     
        <div>From Date</div>
        <input
          placeholder="ZYA"
          type="date"
          [(ngModel)]="from"
          [max]="today"
          [placeholder]="'myPlaceholderText'"
          (change)="validateDates()"
        />
      </div>
      <div class="field-container">
        <div>To Date</div>
        <input
          placeholder="ZYA"
          type="date"
          [(ngModel)]="to"
          [max]="today"
          [placeholder]="'myPlaceholderText'"
          (change)="validateDates()"
        />
      </div>
      
    <!-- <div class="btn-wrapper">
        <button class="btn-primary" (click)="handleSubmit()">Add Service</button>
      </div> -->
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="generateReport()">Submit</button>
    </div>
    <!-- <input type="date" /> -->
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div *ngIf="!typeOfMessage(message)">
            <app-notifications
              [message]="message"
              [type]="messageType"
            ></app-notifications>
          </div>
          <div *ngIf="typeOfMessage(message)">
            <div *ngFor="let e of message">
              <app-notifications [message]="e" [type]="1"></app-notifications>
            </div>
          </div>
          <br />
          <table>
            <tr class="header-tr">
              <td class="header" style="width: 15%">Booking Code</td>
              <td class="header width-10">Invoice ID</td>
              <!-- <td class="header"><b>Address</b></td> -->
              <td class="header width-10">Amount</td>
              <!-- <td class="header width-10" >Discount</td> -->
              <td class="header" style="width: 15%">Name</td>
              <td class="header" style="width: 20%">Reason</td>
              <td class="header mobile">Cancelled On</td>
              <td class="header width-10">Ordered on</td>
              <!-- <td class="header" style="width: 10%">Grosss Income</td>
              <td class="header" style="width: 10%">Net income</td> -->
            </tr>
            <div
              *ngIf="data === null"
              class="loader-container"
              style="display: flex; justify-content: center"
            >
              <app-loader></app-loader>
            </div>
            <!-- <div class="table-body-wrapper"> -->
            <tr *ngFor="let e of data; let i = index">
              <td style="width: 15%">
                <span
                  ><b class="green-text">{{ e.booking_code }}</b></span
                >
              </td>
              <td style="width: 10%">
                <span>{{ e.invoice_id }}</span
                ><br />
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.amount }}</span
                ><br />
              </td>

              <td class="customer" style="width: 20%">
                <span class="name" style="line-height: 20px"
                  ><b>{{ e?.name }}</b></span
                ><span class="light-text">{{ e?.mobile }}</span>
                <span class="light-text">
                  {{ e?.email }}
                  <!-- <app-address [address]="orderObj?.email"></app-address> -->
                </span>
              </td>

              <td style="width: 20%">
                <span class="txt-align-center">
                  <!-- <app-address [address]="e.email"></app-address> -->
                  {{ e.cancellation_note }}
                </span>
              </td>
              <td class="mobile">
                <span class="txt-align-center">{{ e.cancel_at | date }}</span>
              </td>

              <td style="width: 10%">
                <span class="txt-align-center">{{ e.created_at | date }}</span>
              </td>
              <!-- <td style="width: 10%">
                <span class="txt-align-center">{{ e.grosss_income }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.net_income }}</span>
              </td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
