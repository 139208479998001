import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-coupons-table',
  templateUrl: './coupons-table.component.html',
  styleUrls: ['./coupons-table.component.scss'],
})
export class CouponsTableComponent implements OnInit {
  public data: any[] = [];
  constructor(
    private apiService: ApiService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getCoupons();
  }
  gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }
  renderStatus = (c: any) => {
    switch (true) {
      case c.status === 0:
        return 'Cancelled';
      case new Date(c.end_date).getTime() < new Date(Date.now()).getTime():
        return 'Expired';
      default:
        return 'Valid';
    }
  }
  searchCoupons = (ev: any) => {
    const query = ev.target.value;
    this.apiService.searchCoupons(query).subscribe(
      (data: any) => {
        console.log(data);
        this.data = data.data;
      },
      error => {
        console.error('Error searching coupons:', error);
      }
    );
  }
  getCoupons = () => {
    this.apiService.getCoupons().subscribe(
      (data: any) => {
        console.log({ data });
        this.data = data.data;
      },
      error => {
        console.error('Error fetching coupons:', error);
      }
    );
  }
}
