import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingServicesService {
  private bookingId$ = new BehaviorSubject<number>(0);
  setBookingId(state: number): void {
    this.bookingId$.next(state);
  }
  getBookingId() {
    return this.bookingId$.value;
  }
  bookingIdChange(): Observable<any> {
    return this.bookingId$.asObservable();
  }
  constructor() {}
}
