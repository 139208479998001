import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private booking = new BehaviorSubject<any>(null);
  booking$ = this.booking.asObservable();
  private id = new BehaviorSubject<any>(null);
  id$ = this.id.asObservable();

  updatebooking(value: any) {
    this.booking.next(value);
  }
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private utils: UtilityService
  ) {
    this.route.params.subscribe(({ id }) => {
      this.id.next(id);
    });
    this.getData();
  }
  public getData() {
    this.id$.subscribe((d) => {
      this.http
        .get(`${environment.SERVER_URL}bookings/bookingSummary/${d}`, {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        })
        .subscribe((data: any) => {
          this.updatebooking(data.data);
        });
    });

    // this.id$.subscribe((id) => {});
  }
}
