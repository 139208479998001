import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipePipe implements PipeTransform {

  transform(data: any[], searchText: string): any[] {
    if(!data) return [];
    if(!searchText) return data;

    return data.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
      });
    });
  }

}
