<div class="inc-dec-action-container">
  <span [ngStyle]="!label ? { visibility: 'hidden', width: '0px' } : {}"
    >{{ label }} :</span
  >
  <span matRipple (click)="decrease()"
    ><img src="{{ assets }}assets/images/icons/minus.svg"
  /></span>
  <input
    (keyup)="emitData()"
    type="number"
    class="counter"
    [(ngModel)]="counter"
    [min]="min"
    onkeypress="return event.charCode != 45"
  />
  <!-- <input type="number" /> -->
  <span matRipple (click)="increase()"
    ><img src="{{ assets }}assets/images/icons/plus.svg"
  /></span>
</div>
