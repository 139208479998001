<section class="main-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div >
          <br />
          <div class="row-header">
            <div class="col-lg-3">
              <div class="horizontal-line"></div>
              <h6 class="step-1">Step 1</h6>
              <h5 class="Coupon-code-title">Code & Validity</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line2"></div>
              <h6 class="Step-2">Step 2</h6>
              <h5 class="Service-type-Date">Discount Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 3</h6>
              <h5 class="Discount-type">Service Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 4</h6>
              <h5 class="Discount-type">Preview Coupon</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 b-g-white">
        <h5 class="Discount-code">Coupon Code</h5>
        <input
          [(ngModel)]="discountCode"
          (ngModelChange)="checkCouponCode()"
          (keypress)="filterSpecialCharacters($event)"
          type="text"
          class="Title"
          placeholder="Enter coupon code"
        />
        <div *ngIf="couponCodeExists" class="warning-message">
          Coupon code already exists.
        </div>
        <h5 class="Discount-code">Offer Title</h5>
        <input
          [(ngModel)]="couponTitle"
          type="text"
          class="Title"
          placeholder="Enter Offer Title (Max length:50)"
        />
        <h5 class="Discount-code">Offer Description</h5>
        <textarea
          [(ngModel)]="description"
          name=""
          id=""
          class="Description"
          placeholder="Enter Offer Description (Max length:50)"
        ></textarea>
        <div class="flllll">
          <div class="col-lg-8">
          <h5 class="Discount-code">Start Date</h5>
          <input type="date" class="Title" placeholder="Start date" [(ngModel)]="startDate" min="{{ minDate | date : 'yyyy-MM-dd' }}"/>
          </div>
          <div class="col-lg-8">
          <h5 class="Discount-code">End Date</h5>
          <input type="date" class="Title" placeholder="End date" [(ngModel)]="endDate" min="{{ minDate | date : 'yyyy-MM-dd' }}" />
          </div>
        </div>
        <br>
        <br>
        <div style="display: flex; align-items: center; gap: 10px;">
          <p style="margin: 0;">Select Channel Type for the Coupon : </p>
          <label style="display: flex; align-items: center;">
            <input type="radio" class="radio" name="templateChoice"   value="1"  [(ngModel)]="showTemplateSection">
             Online 
          </label>
          <label style="display: flex; align-items: center;">
            <input type="radio" class="radio"  name="templateChoice" value="2" [(ngModel)]="showTemplateSection">  Offline 
          </label>
        </div>
        <br>
        <br>
        <div
        class=""
        (click)="goToUrl('add-coupons-step-2')"
        style="display: flex; justify-content: center"
      >
        <button class="btn primary next">Next</button>
      </div>
        <div *ngIf="message !== ''">
          <app-notifications
            [message]="message"
            [type]="messageType"
          ></app-notifications>
        </div>
      </div>

    </div>
  </div>
</section>
