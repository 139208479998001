<section class="main-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="row">
          <br />
          <div class="row-header">
            <div class="col-lg-3">
              <div class="horizontal-line"></div>
              <h6 class="step-1">Step 1</h6>
              <h5 class="Coupon-code-title">Code & Validity</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line2"></div>
              <h6 class="Step-2">Step 2</h6>
              <h5 class="Service-type-Date">Discount Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 3</h6>
              <h5 class="Discount-type">Service Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 4</h6>
              <h5 class="Discount-type">Preview Coupon</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 b-g-white">
        <br>
        <br>
        <div class="flex-container">
          <div class="flexi-radio" (change)="handleOnChange($event)">
            <div class="flex-radio">
              <label for="Percentage">Percentage (%)</label>
              <input class="radio" name="couponType" type="radio" value="1" [(ngModel)]="couponType"/>
            </div>
            <div class="flex-radio">
              <label for="Flat rate">Flat rate</label>
              <input class="radio" name="couponType" type="radio" value="2" [(ngModel)]="couponType"/>
            </div>
          </div>

          <div class="flexi-radio model" (change)="handleChange($event)">
            <div class="flex-radio">
              <label for="First order">First order</label>
              <input class="radio" name="orderType" type="radio" value="1" [(ngModel)]="orderType"/>
            </div>
            <div class="flex-radio">
              <label for="Any order">Any order</label>
              <input class="radio" name="orderType" type="radio" value="2" [(ngModel)]="orderType"/>
            </div>
          </div>
        </div>
        <br>
        <h4 class="Discount">{{ couponType === '2' ? 'Flat Rate' : 'Discount' }}</h4>
        <input
          [(ngModel)]="discount"
          class="text discount inputField no-spin"
          type="number"
          [placeholder]="couponType === '2' ? 'Enter Flat Rate (For eg - 200rs off)' : 'Enter discount % (For eg - 20%)'"
          (keyup)="checkValue($event, 'discount')"
        />

        <h4 class="Discount" *ngIf="orderType === '2'">Usage Limit</h4>
        <input
          *ngIf="orderType === '2'"
          [(ngModel)]="usageLimit"
          class="text usageLimit"
          type="number"
          placeholder="Usage Limit (For eg - 10)"
          (keyup)="checkValue($event, 'usageLimit')"
        />

        <h4 class="Discount">Minimum Order Value</h4>
        <input
          [(ngModel)]="minimumAmount"
          class="text maxAmount inputField no-spin"
          type="number"
          placeholder="Minimum order value (For eg - 1200rs)"
          (keyup)="checkValue($event, 'maxAmount')"
        />

        <h4 class="Discount" *ngIf="couponType == '1'">Maximum Discount Value</h4>
        <input
          *ngIf="couponType == '1'"
          [(ngModel)]="maximumDiscount"
          class="text minDiscount inputField no-spin"
          type="number"
          placeholder="Max discount amount (For eg - 600rs)"
          (keyup)="checkValue($event, 'minDiscount')"
        />
        <div class="span-btn">
          <button class="Backk" (click)="goBack()">Back</button>
          <div class="">
            <button class="btn primary next-btn model" (click)="goToNextStep()">Next</button>
          </div>
        </div>
        <br/>
        <br/>
          <div *ngIf="message !== ''">
            <app-notifications
              [message]="message"
              [type]="messageType"
            ></app-notifications>
          </div>
        
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</section>
