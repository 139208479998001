import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { AppService } from 'src/app/service/app.service';
import { SidebarService } from 'src/app/service/sidebar.service';
import { UtilityService } from 'src/app/service/utility.service';
import { DRAWER_TYPES } from 'src/assets/constants/app-constant';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  drawerSubscription!: Subscription;
  currentRoute: string = window.location.pathname;
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown.svg`;
  public logoSrc = '';
  public loginImage: string;
  appDomain = '';
  public assets = environment.assetUrl;
  showMenu = false;
  constructor(
    private appService: AppService,
    private router: Router, // private router: Router,
    private utils: UtilityService,
    private http: HttpClient,
    private dialogeRef: MatDialog,
  ) {this.loginImage = this.getLoginImage();}
  

  getLoginImage(): string {
    switch (environment.FRANCHISE.toLowerCase()) {
      case 'whitetiger':
        return `${environment.assetUrl}assets/images/background/wtlogo.svg`;
      default:
        return `${environment.assetUrl}assets/images/logo.png`;
    }
  }  
  
  

  logoutUser() {
    this.dialogeRef.open(ConfirmationModalComponent, {
      data: {
        logout: true,
        messageToDisplay: 'Are you sure you want to logout?',
      },
    });
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  verifyToken() {
    const token = this.utils.getLocalStorageItem('token');
    this.http
      .get(`${environment.SERVER_URL}stores/verify?franchise=${environment.FRANCHISE}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .subscribe(
        (data) => {
          const store = data['store'];
          LogRocket.identify(store.storecode, {
            name: store.name,
            user_id: store.user_id,
          });
        },
        (err) => {
          this.router.navigateByUrl('login');
        }
      );
  }
  trackRoute() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.verifyToken();
        this.currentRoute = event.url;
      }
    });
  }
  ngOnInit(): void {
    //const url = window.location.href;
    //const url = 'https://store.whitetigercleaners.com';
    this.appDomain = this.utils.extractDomainFromUrl(environment.domain);
    if(this.appDomain == 'store.whitetigercleaners.com') {
      this.logoSrc = `../../../../../assets/images/whitetiger.png`;
    } else {
      this.logoSrc = `${environment.assetUrl}assets/images/logo.png`;
    }
   
    this.trackRoute();
    // this.activeRoute.params.subscribe((data) => {
    // });
    if (this.currentRoute !== '/login') {
      this.verifyToken();
    }
  }
  goToUrl(url: string) {
    this.router.navigateByUrl(url);
    this.appService.currentSort=null;
  }
  goToHome() {
    this.router.navigate(['/']);
  }

  handleReportRouting(event: any) {
    this.goToUrl(event);
  }
}
