import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UpdateBreakdownService {
  private dataUpdatedSource = new Subject<any>();
  dataUpdated$ = this.dataUpdatedSource.asObservable();
  constructor() {}
  updateData() {
    console.log('updating the service...');
    this.dataUpdatedSource.next('UPDATE');
  }
}
