<h2 mat-dialog-title class="text-center">Payment Result</h2>
<mat-dialog-content>
  <div class="content-container">
    <img src='../../../../assets/images/congrats.png' alt="congrats">
    <h2><b>Congratulations!</b></h2>
    <h2><b>Order is marked delivered</b></h2>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>