import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss'],
})
export class RoutingComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const urlParam = this.route.snapshot.paramMap.get('url');
    console.log({ urlParam });
    const location = window.location.href;

    // `http://localhost:4200/testlaundry-and-dry-cleaners-in-marathahalli-bangalore-uc011`;

    // this.router.navigate(
    //   any[
    //     `https://uclean.in/laundry-and-dry-cleaners-in-marathahalli-bangalore-uc011`
    //   ]
    // );
    this.router.navigateByUrl(
      'https://uclean.in/laundry-and-dry-cleaners-in-marathahalli-bangalore-uc011'
    );

    // this.router.navigate([]);

    // const urlParam = this.route.snapshot.paramMap.get('url');
  }
}
