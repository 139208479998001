<!-- create-new-order.component.html -->
<div class="create-new-order">
  <div class="form-group">
    <label>Order Mode</label>
    <select  (change)="handleOnChange($event, 'OM')"
    (change)="emitData()">
      <option value="" disabled selected>Choose Order Mode</option>
      <option *ngFor="let o of orderThrough" value="{{ o.id }}">
        {{ o.name }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label>Select Services</label>
  </div>

  <div class="form-group">
    <div class="input-container">
      <div style="width: 100%">
        <!-- This is dropdown for service selection -->
        <ng-multiselect-dropdown
          (onSelect)="onItemSelect($event)"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          (onDeSelect)="onItemDeSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelectAll)="onDeselectAll()"
        >
        </ng-multiselect-dropdown>
      </div>
  </div>
  </div>
  <div class="form-group">
  <div class="form-row" *ngIf="orderThroughId !== 1">
    <div class="form-group half-width">
      <label>Pickup Date</label>
      <div class="date-input">
        <select
        (change)="handleOnChange($event, 'PD')"
        (change)="emitData()"
      >
        <option value="" disabled selected>Select Pickup Date</option>

        <option *ngFor="let o of availableDates" value="{{ o }}">
          {{ o }}
        </option>
      </select>
      </div>
    </div>
    <div class="form-group half-width">
      <label>Pickup Time</label>
      <select   (change)="handleOnChange($event, 'PT')"
      (change)="emitData()">
        <option value="" disabled selected>Select Time...</option>
        <option *ngFor="let t of timeslots" value="{{ t }}">
          {{ t }}
        </option>
      </select>
    </div>
  </div>
</div>
  <div class="form-group">
    <label>Order Instructions:</label>
    <textarea  [(ngModel)]="suggestions"
    (change)="emitData()"placeholder="Type here..."></textarea>
  </div>
