import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { InvoiceService } from '../service/invoice.service';
import { ViewInvoiceComponent } from 'src/app/view-invoice/view-invoice.component';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
  public sortQuery: string = '';
  public sortLabel: string = 'Sort by';
  public selectedFilter: string = '';
  public currentPage: number = 1;
  public today: string = '';
  public orders: any = null;
  public totalPages: number = 0;
  public searchQuery: string = '';
  public fromDate: string = '';
  public toDate: string = '';
  public dateS: string = 'Select';
  public dateSortingOptions: string[] = ['Pickup date', 'Delivery Date'];

  public sortingItems: any[] = [
    { name: 'Pickup date DESC', value: 'PUDSC' },
    { name: 'Pickup date ASC', value: 'PUASC' },
    { name: 'Booking code DESC', value: 'OCDSC' },
    { name: 'Booking code ACS', value: 'OCACS' },
    { name: 'Invoice code ACS', value: 'INACS' },
    { name: 'Invoice code DESC', value: 'INDES' },
    { name: 'Drop date ACS', value: 'DDASC' },
    { name: 'Drop date DESC', value: 'DDDSC' },
  ];

  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;

  constructor(
    private invoiceService: InvoiceService,
    private http: HttpClient,
    private utils: UtilityService,
    private router: Router,
    private dialogRef: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setToday();
  }
  setToday() {
    const today = new Date();
    this.today = today.toISOString().split('T')[0];
  }
  sortDate(event: Event) {
    this.dateS = (event.target as HTMLSelectElement).value;
  }

  setDatesAndSort() {
    if (this.fromDate !== '' && this.toDate !== '' && this.dateS !== 'Select') {
      this.getData();
    }
    if (this.fromDate && this.toDate && this.fromDate > this.toDate) {
      this.toDate = this.fromDate;
    }
  }

  sort(q: any) {
    this.orders = null;
    this.sortQuery = q.value;
    this.sortLabel = q.name;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }

  handleSearch(e: any) {
    this.searchQuery = e.target.value;
    this.searchBooking();
  }

  searchBooking() {
    this.http
      .get(
        `${environment.SERVER_URL}bookings/search?query=${this.searchQuery}&sortQuery=${this.sortQuery}&page=${this.currentPage}&filterQuery=${this.selectedFilter}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.orders = data.data;
        this.totalPages = data.totalPages;
      });
  }

  updatePageNumber(event: any) {
    this.currentPage = event.page;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }

  getData() {
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${this.utils.getLocalStorageItem('token')}`
    );

    this.http
      .get(
        `${environment.SERVER_URL}bookings/getDeliveredOrders?franchise=${environment.FRANCHISE}&page=${this.currentPage}&sortQuery=${this.sortQuery}&sortDate=${this.dateS}&sortFrom=${this.fromDate}&sortTo=${this.toDate}`,
        { headers }
      )
      .subscribe((data: any) => {
        this.orders = data.data;
        this.totalPages = Math.ceil(data.totalBookings / 30);
      });
  }

  renderIcons = (num: number) => {
    console.log({ num });
    switch (num) {
      case 1:
        return `${environment.assetUrl}assets/images/icons/order-through/walk.svg`;
      case 2:
        return `${environment.assetUrl}assets/images/icons/order-through/phone.svg`;
      case 3:
        return `${environment.assetUrl}assets/images/icons/order-through/store.svg`;
      case 4:
        return `${environment.assetUrl}assets/images/icons/order-through/mobile.svg`;
      case 5:
        return `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`;
      case 6:
        return `${environment.assetUrl}assets/images/icons/order-through/whatsapp.svg`;
      default:
        return '';
    }
  }

  viewInvoice = (id: number) => {
    this.invoiceService.previewInvoice(id).subscribe((data) => {
      const reader = new FileReader();
      reader.readAsText(data);

      reader.onloadend = () => {
        this.dialogRef.open(ViewInvoiceComponent, {
          data: reader.result!.toString(),
          width: '230mm',
          height: '230mm',
          panelClass: 'a4-dialog'
        });
      };
    });
  }

  renderStatus(s: number): string {
    switch (true) {
      case s === 0:
        return 'Cancel';
      case s === 7:
        return 'Delivered';
      default:
        return 'Unknown';
    }
  }


  displayTime = (datetime: string) => {
    if (datetime) {
      return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm A');  // Format as "30 Sep 2024, 11:06 AM"
    } else {
      return '';
    }      
  }

  resetFilters = () => {
    this.orders = null;
    this.sortQuery = '';
    this.currentPage = 1;
    this.selectedFilter = '';
    (document.querySelector('.search-user') as HTMLInputElement).value = '';
    this.toDate = '';
    this.fromDate = '';
    this.dateS = 'Select';
    this.getData();
  }

  gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }
}