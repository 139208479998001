import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';


import { ActivatedRoute, Router } from '@angular/router';
import { DRAWER_TYPES } from 'src/assets/constants/app-constant';
import { AppService } from '../../../service/app.service';

import { environment } from 'src/environments/environment';
import { BookingService } from 'src/app/service/booking.service';
import { Subscription } from 'rxjs';
import { BookingServicesService } from 'src/app/service/booking-services.service';
import { UtilityService } from 'src/app/service/utility.service';
import { SidebarService } from 'src/app/service/sidebar.service';
import { UpdateBreakdownService } from 'src/app/update-breakdown.service';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal.component';
import { InvoiceService } from '../../../service/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { Cloths } from '../../../module/shared/component/premium-laundry/premium-laundry.component';
import {ViewInvoiceComponent} from '../../../view-invoice/view-invoice.component';
import {ApiService} from '../../../services/api.service';
import { AddNewItemComponent } from 'src/app/add-new-item/add-new-item.component';
interface Service {
  quantity: number;
  weight?: number;
  amount: number;
  add_on: number;
  discount: number;
  parenttrade_id: number;
  base_price: number;
  tax: number;
  booking_id: number;
  final_amount: number;
  addonItems?: any[];
  cloths: any[];
  customCloths?: Cloths[];
}
@Component({
  selector: 'app-invoice-container',
  templateUrl: './invoice-container.component.html',
  styleUrls: ['./invoice-container.component.scss'],
  providers: [BookingService],
})
export class InvoiceContainerComponent implements OnInit {
  private id = '';
  drawerSubscription!: Subscription;
  public order: any;
  isOrderBreakupDrawerVisibile: any;
  @Output() trigger = new EventEmitter();
  public assets = environment.assetUrl;
  public updateChild = false;
  public totalAmount = 0;
  public taxes = 0;
  public discount = 0;
  public addons = 0;
  public expressAmount = 0;
  public gst = '1';
  public store: any;
  public coupons: any[] = [];
  public message: any;
  public messageType = -1;
  public selectedCouponId: any;
  public savedCouponId: any;
  public deliveryTimes: any[] = [];
  public deliveryTime = '';
  public deliveryDate = '';
  public deliveryTimeLabel = '';
  public customerGST = '';
  public challanNo;
  public sidebarVisible = true;
  minDate: Date = new Date();
  public timings: any[] = [
    { value: '9 - 10', label: '9AM - 10AM', backendValue: '9 AM - 10 AM' },
    { value: '10 - 11', label: '10AM - 11AM', backendValue: '10 AM - 11 AM' },
    { value: '11 - 13', label: '11AM - 1PM', backendValue: '11 AM - 1 PM' },
    { value: '13 - 15', label: '1PM - 3PM', backendValue: '1 PM - 3 PM' },
    { value: '15 - 17', label: '3PM - 5PM', backendValue: '3 PM - 5 PM' },
    { value: '17 - 19', label: '5PM - 7PM', backendValue: '5 PM - 7 PM' },
    { value: '19 - 21', label: '7PM - 9PM', backendValue: '7 PM - 9 PM' },
  ];
  public bookingId!: number;
  public activeTab = 1;
  public service: Service;
  public dryCleaningData: any = null;
  public washAndFoldData: any = null;
  public washAndIronData: any = null;
  public premiumLaundryKg: any = null;
  public steamPressPremiumData: any = [];
  public premiumLaundryPerPeice: any = [];
  public steamPressRegularData: any = null;
  public customCloth = '';
  private serveicesAvailed: Array<number> = [];
  public availableServices: any = null;
  public selectedService: any = null;
  public washAndFoldPricing: any = null;
  public washAndIronPricing: any = null;
  public premiumLaundryKG: any = null;
  public standardIronPricing: any = null;
  public DCPricing: any = {};
  public shortlistedDCCloths: any = {};
  public PLPricing: any = {};
  public shortlistedPLCloths: any = {};
  public SPPricing: any = {};
  public shortlistedSPCloths: any = {};
  public clothQuery = '';
  public services: any = {};
  public ORDER_UNEDITABLE_CONDITION = 3;
  public ordermutable = true;
      // Add loading state
      private isLoading = new BehaviorSubject<boolean>(false);
      isLoading$ = this.isLoading.asObservable();
  
      // This will hold queued service updates
      private serviceUpdateQueue$ = new BehaviorSubject<any>(null);
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private appService: AppService,
    private createBookingServiceService: BookingServicesService,
    private utils: UtilityService,
    private sidebar: SidebarService,
    private updateService: UpdateBreakdownService,
    private cdr: ChangeDetectorRef,
    private invoiceService: InvoiceService,
    private dialogRef: MatDialog,
    private apiService: ApiService
  ) {
    this.route.params.subscribe((data: any) => {
      this.id = data.id;
    });
    this.sidebar.isOpen$.subscribe((data) => {
      this.isOrderBreakupDrawerVisibile = data;
    });
    this.updateService.dataUpdated$.subscribe(() => {
      // console.log('updating the breakdownn component...');
      this.getBookingSummary();
    });
    // Subscribe to the queue and process updates
    this.serviceUpdateQueue$
  .pipe(
    exhaustMap(serviceData => {
      if (!serviceData) return of(null);

      this.isLoading.next(true);
      const { serviceId } = serviceData;

      return new Observable(observer => {
        // Pass a callback to turn off the loader when complete
        this.updateBookingServiceApi(serviceId, () => {
          this.isLoading.next(false);
          observer.complete();
        });

        return () => {};
      });
    }),
    catchError(error => {
      this.isLoading.next(false);
      console.error('Queue processing error', error);
      return of(null);
    })
  )
  .subscribe();
 }

  closeOrderBreakupDrawer = () => {
    // console.log('Close');
    this.appService.drawer$.next({
      type: DRAWER_TYPES.orderBreakup,
      isVisible: false,
    });
    this.isOrderBreakupDrawerVisibile = !this.isOrderBreakupDrawerVisibile;
    this.sidebarVisible = false;
  }

  setDrawerSubscription = () => {
    this.drawerSubscription = this.appService.drawer$.subscribe(
      (drawerInfo: any) => {
        if (drawerInfo) {
          if (drawerInfo?.type === DRAWER_TYPES.orderBreakup) {
            this.isOrderBreakupDrawerVisibile = drawerInfo?.isVisible;
          }
        }
      }
    );
  }
  get isSidebarOpenIconVisible() {
    return this.router.url.includes('invoice-flow');
  }
  typeOfMessage = (type: string | string[]) => {
    return Array.isArray(type);
  }

  ngOnInit(): void {
    this.sidebarVisible = true;
    this.getBookingSummary();
    this.storeProfile();
    this.findCoupons();
    this.service = {
      add_on: 0,
      amount: 0,
      base_price: 0,
      booking_id: 0,
      discount: 0,
      parenttrade_id: 0,
      quantity: 0,
      tax: 0,
      weight: 0,
      final_amount: 0,
      addonItems: [],
      cloths: [],
      customCloths: [],
    };

    document.documentElement.style.setProperty(
      '--search-bar-default-width',
      '50vw'
    );

    setTimeout(() => {
      this.appService.drawer$.next({
        type: DRAWER_TYPES.orderBreakup,
        isVisible: true,
      });
    }, 1000);
    this.createBookingServiceService.bookingIdChange().subscribe((state) => {
      this.bookingId = state;
    });
    this.initService();
    this.setParentTrade();
    this.getAvailableService();
    this.fetchPricing();
 
  }

  openAddNewItemModal(): void {
    const dialogRef = this.dialogRef.open(AddNewItemComponent, {
      data: { bookingId: this.order?.id, selectedService: this.selectedService }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addCustomClothToService(result);
        this.updateBookingService(this.selectedService);
      }
    });
  }
  
  addCustomClothToService(newItem: any): void {
    // console.log(this.services[this.selectedService], newItem);
    // Ensure the selected service exists in the services object.
    if (!this.services[this.selectedService]) {
      this.services[this.selectedService] = {
        booking_id: this.order.id,
        cloths: {},
        customCloths: {}
      };
    }
  
    // Ensure the customCloths array is initialized.
    if (!this.services[this.selectedService].customCloths) {
      this.services[this.selectedService].customCloths = {};
    }
    // Push the customCloth object into the customCloths array.
    this.services[this.selectedService].customCloths[newItem.name] = newItem;
    // console.log(this.services[this.selectedService].customCloths);
  }
  
  deleteCustomItem = (parenttradeId: string, customItemId: string) => {
    if (!parenttradeId) {
      console.error('Service ID is missing or invalid');
      return;
    }
  
    if (!customItemId) {
      console.error('Cloth Booking ID is missing or invalid');
      return;
    }
  
    const modal = this.dialogRef.open(ConfirmationModalComponent, {
      data: {
        postURL: `clothbooking/delete?franchise=${environment.FRANCHISE}`,
        bookingId: this.order.id,
        messageToDisplay: 'Are you sure you want to delete this booking service?',
        body: {
          parenttrade_id: ""+parenttradeId,
          bookingId: this.order.id,
          id: customItemId,
        },
      },
    });
  
    modal.componentInstance.trigger.subscribe((data) => {
      this.trigger.emit('SUCCESS');
      this.updateBooking();
    });
  }

  ngOnChanges = () => {
    if (this.order?.currentValue?.bookingServices.length === 0) {
      this.discount = 0;
      this.selectedCouponId = undefined;
      this.savedCouponId = undefined;
      this.expressAmount = 0;
    }
    this.initService();
  }

  setValidTimeSlots = () => {
    const currentTime = new Date(Date.now());
    const currentHour = currentTime.getHours();
    const currentMonth = currentTime.getMonth() + 1;
    const currentYear = currentTime.getFullYear();
    const currentDate = currentTime.getDate();
    const currentDateStr = `${currentYear}-${
      currentMonth.toString().length === 1
        ? currentMonth.toString().padStart(2, '0')
        : currentMonth
    }-${
      currentDate.toString().length === 1
        ? currentDate.toString().padStart(2, '0')
        : currentDate
    }`;

    if (currentDateStr === this.deliveryDate) {
      this.deliveryTimes = this.validateTime(this.timings, currentHour);
    } else {
      const selectedHour = new Date(this.deliveryDate).getHours();
      this.deliveryTimes = this.validateTime(this.timings, selectedHour);
    }
  }

  validateTime(timings: Array<any>, currentTime: number): Array<any> {
    const filteredItems = timings.filter((e) => {
      const { value } = e;
      const lastHour = parseInt(value.split('-')[1].trim());
      if (lastHour > currentTime) {
        return e;
      }
      return;
    });
    return filteredItems;
  }
  orderStatus(s: number): string {
    switch (true) {
      case s === 0:
        return 'Canceled';
      case s === 1:
        return 'New order';
      case s === 2:
        return 'Pickup boy assigned';
      case s === 3:
        return 'In process';
      case s === 4:
        return 'Order ready';
      case s === 5:
        return 'Drop rider assigned';
      case s === 6:
        return 'Out for delivery';
      case s === 7:
        return 'Delivered';
    }
    return '';
  }
  goToUrl = (url: any) => {
    this.router.navigateByUrl(url);
  }

  getBookingSummary = () => {
    // console.log('Getting Booking from backend');
    this.http
      .get(`${environment.SERVER_URL}bookings/bookingSummary/${this.id}?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.order = data.data;
        this.gst = this.order.including_gst;
        this.challanNo = this.order.challan_number;
        this.createBookingServiceService.setBookingId(data.data.id);
        if (this.order.expressAmount) {
          this.expressAmount = this.order.express_amount;
        }
        if (this.order.bookingDetails && this.order.bookingDetails.length > 0) {
          this.customerGST = this.order.bookingDetails[0].customer_gst;
          this.deliveryDate = this.order.bookingDetails[0].delivery_date;
          this.deliveryTime = this.order.bookingDetails[0].delivery_time;
          this.setValidTimeSlots();
        }
        if (this.order.coupon_id) {
          this.savedCouponId = this.order.coupon_id;
          this.selectedCouponId = this.order.coupon_id;
        }
        this.discount = this.order.discount;
        this.initService();
      });
  }

  openOrderBreakupDrawer = () => {
    // console.log('Open');
    this.appService.drawer$.next({
      type: DRAWER_TYPES.orderBreakup,
      isVisible: true,
    });
    this.sidebar.openSidebar();
    this.sidebarVisible = true;
  }

  displaySummary = (type: number) => {
    if(!this.order){
      return;
    }
    let amount = this.order.amount;
    if (this.store && this.store.gst && this.gst === '1') {
      if(this.gst === '1'){
        amount = (amount*100/118).toFixed(2);
      }
    }

    switch (true) {
      case type === 1:
        return amount;
      case type === 2:
        return this.order.tax?.toFixed(2);
      case type === 3:
        return this.order.addon_amount;
      case type === 4:
        return this.order.final_amount;
      case type === 5:
        return this.order.express_amount;
      case type === 6:
        return this.order.discount;
      case type === 7:
        return (this.order.final_amount-this.order.tax).toFixed(2);
    }
    return this.order.tax;
  }

  calculateTax = (netAmount: number) => {
    let tax = 0;
    switch (true) {
      case this.gst === '1':
        const netAmountBeforeGST = (netAmount * 100) / 118;
        tax = netAmount - netAmountBeforeGST;
        netAmount = netAmountBeforeGST;
        return tax;
      case this.gst === '0':
        tax = (18 / 100) * netAmount;
        return tax;
      case this.gst === '2':
        tax = (6 / 100) * netAmount;
        return tax;
    }
    return 0;
  }

  storeProfile = () => {
    this.http
      .get(`${environment.SERVER_URL}stores/getInsights?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        // console.log(data.data);
        this.store = data.data.store;
        // console.log(this.store);
      });
  }
  findCoupons = () => {
    this.http
      .get(`${environment.SERVER_URL}coupns/getValidCoupons?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (data: any) => {
          this.coupons = data.data;
        },
        (err) => {}
      );
  }

  getParentTrade(id: any): string {
    id = parseInt(id);
    switch (true) {
      case id === 1:
        return 'Dry Cleaning';
      case id === 2:
        return 'Premium Laundry';
      case id === 3:
        return 'Steam Iron';
      case id === 4:
        return 'Wash & Fold';
      case id === 5:
        return 'Wash & Iron';
      case id === 6:
        return 'Home Service';
      case id === 8:
        return 'Vacuum Iron';
      case id === 9:
        return 'Premium Laundry Kg';
      case id === 10:
        return 'Standard Iron';
    }
    return '';
  }

  getlog = (event: any) => {
    // console.log(event);
    return 'Name';
  }

  setErrors = (message: string) => {
    this.message = '';
    this.messageType = -1;
    this.message = message;
    this.messageType = 1;
  }
  applyCoupon = () => {
    console.log("API Called");

    if (!this.canChangeOrder()) {
      console.log('Cannot change order');
      return;
    }

    if (!this.selectedCouponId) {
      console.log('No coupon selected');
      return;
    }

    if (this.selectedCouponId == this.savedCouponId){
      console.log('Coupon already applied');
      return;
    }

    console.log('Order ID:', this.order.id);
    console.log('Selected Coupon ID:', this.selectedCouponId);

    if (!this.order.id) {
      console.error('Order ID is missing or invalid');
      return;
    }

    if (!this.selectedCouponId) {
      console.error('Coupon ID is missing or invalid');
      return;
    }

    this.http
      .post(
        `${environment.SERVER_URL}bookings/applyCoupon?franchise=${environment.FRANCHISE}`,
        {
          bookingId: this.order.id,
          couponCode: this.selectedCouponId,
        },
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          // console.log('API response:', data);
          this.message = data.message;
          this.messageType = 0;
          this.updateBooking();
        },
        (err) => {
          console.error('API error:', err);
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }

  removeCoupon = () => {
    if (!this.canChangeOrder()){
      // console.log('Cannot change order');
      return;
    }
    this.http.post(
      `${environment.SERVER_URL}bookings/removeCoupon?franchise=${environment.FRANCHISE}`,
      {
        bookingId: this.order.id,
      },
      {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      }).subscribe(
        (data: any) => {
          this.savedCouponId = undefined;
          this.selectedCouponId = undefined;
          this.discount = 0;
          this.message = data.message;
          this.messageType = 0;
          this.updateBooking();
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }

  setDeliveryTiming = (t: string) => {
    this.message = '';
    this.messageType = -1;
    const { pick_boy_assign_at: pickup } = this.order;
    if (new Date(pickup).getDate() === new Date(this.deliveryDate).getDate()) {
      const pickupTime = new Date(pickup).toLocaleTimeString('en-US', {
        hour: '2-digit',
        hour12: false,
      });
      const deliveryTime = t.split('-')[1].trim();
      const timeDiff = +deliveryTime - +pickupTime;
      if (timeDiff < 4) {
        // console.log({ messa: this.message, type: this.messageType });
        this.setErrors('You can only deliver after 4 hours of pickup');
        return;
      }
    }

    this.deliveryTime = t;
    this.deliveryTimeLabel = this.timings.find(
      (o: { value: string }) => o.value === t
    )?.label;
  }

  viewInvoice = (id: number) => {
    this.invoiceService.previewInvoice(id).subscribe((data) => {
      const reader = new FileReader();
      reader.readAsText(data);

      reader.onloadend = () => {
        this.dialogRef.open(ViewInvoiceComponent, {
          data: reader.result!.toString(),
        });
      };
    });
  }

  deleteBookingService = (serviceId: any) => {
    if (this.canChangeOrder()){
      const modal = this.dialogRef.open(ConfirmationModalComponent, {
        data: {
          postURL: `bookingsServices/delete?franchise=${environment.FRANCHISE}`,
          bookingId: this.order.id,
          messageToDisplay:
            'Are you sure you want to delete this booking service?',
          body: {
            parenttrade_id: serviceId,
            bookingId: this.order.id,
          },
        },

      });
      modal.componentInstance.trigger.subscribe((data) => {
        this.trigger.emit('SUCCESS');
        this.updateBooking();
      });
    }else{
      console.log('Cannot change order');
    }
  }

  deleteClothBooking = (serviceId: any, clothId: any) => {
    if (!this.order.id) {
      console.error('Booking ID is missing or invalid');
      return;
    }

    if (!serviceId) {
      console.error('Service ID (parenttrade_id) is missing or invalid');
      return;
    }

    if (!clothId) {
      console.error('Cloth ID is missing or invalid');
      return;
    }

    const modal = this.dialogRef.open(ConfirmationModalComponent, {
      data: {
        postURL: `clothbooking/delete?franchise=${environment.FRANCHISE}`,
        bookingId: this.order.id,
        messageToDisplay: 'Are you sure you want to remove this item?',
        body: {
          parenttrade_id: serviceId,
          bookingId: this.order.id,
          clothId,
        },
      },
    });

    modal.componentInstance.trigger.subscribe((data) => {
      this.trigger.emit('SUCCESS');
      this.updateBooking();
    });
  }

  deleteAddon = (serviceId: any, addonId: any) => {
    if (this.canChangeOrder()){
      const modal = this.dialogRef.open(ConfirmationModalComponent, {
        data: {
          postURL: `addon/delete?franchise=${environment.FRANCHISE}`,
          bookingId: this.order.id,
          messageToDisplay:
            'Are you sure you want to remove this addon?',
          body: {
            parenttrade_id: serviceId,
            bookingId: this.order.id,
            addonId,
          },
        },
      });
      modal.componentInstance.trigger.subscribe((data) => {
        this.trigger.emit('SUCCESS');
        this.updateBooking();
      });
    }else {
      console.log('Cannot change order');
    }
  }
  saveBooking = () => {
    const that = this;
    this.http
      .post(
        `${environment.SERVER_URL}bookings/saveBooking/${this.bookingId}?franchise=${environment.FRANCHISE}`,
        {},
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          that.order.status = 3;
          that.canChangeOrder();
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }

  shouldDisplayStar = (tab: number) => {
    switch (true) {
      case tab === 1 && this.serveicesAvailed.includes(1):
        return true;
      case tab === 2 && this.serveicesAvailed.includes(2):
        return true;
      case tab === 3 && this.serveicesAvailed.includes(3):
        return true;
      case tab === 4 && this.serveicesAvailed.includes(4):
        return true;
      case tab === 5 && this.serveicesAvailed.includes(5):
        return true;
      case tab === 9 && this.serveicesAvailed.includes(9):
        return true;
      case tab === 10 && this.serveicesAvailed.includes(10):
        return true;
    }
    return false;
  }

  setParentTrade = () => {
    let parentTrade = 1;
    switch (true) {
      case this.activeTab === 1:
        parentTrade = 1; // Dry Cleaning
        break;
      case this.activeTab === 2:
        parentTrade = 2; // Premium Laundry
        break;
      case this.activeTab === 3:
        parentTrade = 3; // Steam Press
        break;
      case this.activeTab === 4:
        parentTrade = 4; // Wash and Fold
        break;
      case this.activeTab === 5:
        parentTrade = 5; // Wash and Iron
        break;
      case this.activeTab === 9:
        parentTrade = 9; // Premium Laundry KG
        break;
      case this.activeTab === 10:
        parentTrade = 10; // Standard Iron
    }
    this.selectedService = parentTrade;
  }

  fetchBookingdetails = () => {
    if (this.bookingId && this.bookingId > 0) {
      const parentTrade = this.selectedService;
      this.http
        .post(
          `${environment.SERVER_URL}bookingService/getSingleService?franchise=${environment.FRANCHISE}`,
          {
            bookingId: this.bookingId,
            parentTrade,
          },
          {
            headers: {
              authorization: `Bearer ${this.utils.getLocalStorageItem(
                'token'
              )}`,
            },
          }
        )
        .subscribe((data: any) => {
          switch (true) {
            case parentTrade === 4:
              this.washAndFoldData = data.data;
              break;
            case parentTrade === 5:
              this.washAndIronData = data.data;
              break;
            case parentTrade === 9:
              this.premiumLaundryKg = data.data;
              break;
            case parentTrade === 10:
              this.steamPressRegularData = data.data;
          }
        });
      this.getAllServices();
    }
  }

  fetchPricing = () => {
    const parenttrade = this.selectedService;
    this.http
      .get(`${environment.SERVER_URL}services/${parenttrade}/pricing?franchise=${environment.FRANCHISE}`, {
        headers: {
          Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        // console.log('Service: ', parenttrade,  data);
        if (parenttrade === 1) {
          this.DCPricing.cloths = data.cloths;
          this.DCPricing.addons = data.addons;
          this.shortlistDCCloths(undefined);
        } else if (parenttrade === 2) {
          this.PLPricing.cloths = data.cloths;
          this.PLPricing.addons = data.addons;
          this.shortlistPLCloths(undefined);
        } else if (parenttrade === 3) {
          this.SPPricing.cloths = data.cloths;
          this.SPPricing.addons = data.addons;
          this.shortlistSPCloths(undefined);
        } else if (parenttrade === 4) {
          this.washAndFoldPricing = data.service[0];
          this.washAndFoldPricing.addons = data.addons;
        } else if (parenttrade === 5) {
          this.washAndIronPricing = data.service[0];
          this.washAndIronPricing.addons = data.addons;
        } else if (parenttrade === 9) {
          this.premiumLaundryKG = data.service[0];
          this.premiumLaundryKG.addons = data.addons;
        } else if (parenttrade === 10) {
          this.standardIronPricing = data.service[0];
          this.standardIronPricing.addons = data.addons;
        }
      });
  }

  fetchClothsCreated = () => {
    const parenttrade = this.selectedService;

    this.http
      .post(
        `${environment.SERVER_URL}clothBookingDetails/findCombination?franchise=${environment.FRANCHISE}`,
        {
          bookingId: this.bookingId,
          parentTrade: parenttrade,
        },
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        switch (true) {
          case parenttrade === 1:
            this.dryCleaningData = data.data;
            break;
          case parenttrade === 3:
            this.steamPressPremiumData = data.data;
            break;
          case parenttrade === 2:
            this.premiumLaundryPerPeice = data.data;
            break;
        }
      });
  }

  emitTrigger = () => {
    this.trigger.emit({ update: true });
  }

  handleChangeOnTabs = (tab: number) => {
    this.activeTab = tab;
    this.setParentTrade();
    this.resetService();
    this.fetchClothsCreated();
    this.fetchBookingdetails();
    this.fetchPricing();
  }
  handleAddOn = (event: Event) => {
    const val = parseInt((event.target as HTMLInputElement).name);
    const addOns = [1, 2];
    if (!addOns.includes(parseInt((event.target as HTMLInputElement).name))) {
      return;
    }
    let indexOfItem = -1;
    let contains = false;

    this.service.addonItems?.forEach((el: any, i: number) => {
      if (el.id === val) {
        contains = true;
        indexOfItem = i;
      }
      return;
    });
    if (!contains) {
      this.service.addonItems?.push({
        id: val,
        quantity: 1,
      });
    } else {
      this.service.addonItems?.splice(indexOfItem, 1);
    }
  }
  getAvailableService = () => {
    this.http
      .get(`${environment.SERVER_URL}availableServices?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.availableServices = data.services;
        // console.log(this.availableServices);
      });
  }
  getAllServices = () => {
    if (this.bookingId) {
      this.http
        .get(`${environment.SERVER_URL}bookingService/${this.bookingId}?franchise=${environment.FRANCHISE}`, {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        })
        .subscribe((data: any) => {
          console.log({ data });
          const servcies = data.data.map((e: any) => {
            return e?.parenttrade_id;
          });
          this.serveicesAvailed = servcies;
        });
    }
  }

  resetState = () => {
    this.dryCleaningData = null;
    this.washAndFoldData = null;
  }

  setError = (message: string) => {
    this.message = message;
    this.messageType = 1;
  }

  checkQty = () => {
    let isValid = false;
    this.service.cloths?.forEach((e: any) => {
      if (e.quantity <= 0) {
        isValid = false;
        return;
      } else {
        isValid = true;
      }
    });

    return isValid;
  }

  checkWeightAndQuantity = () => {
    let isValid = true;
    if (!this.service.quantity) {
      isValid = false;
      this.setError('Please Add quantity to add this service');
    }
    if (!this.service.weight) {
      isValid = false;

      this.setError('Please add weight to add this service');
    }
    return isValid;
  }

  resetService = () => {
    this.service.add_on = 0;
    this.service.amount = 0;
    this.service.base_price = 0;
    this.service.parenttrade_id = 0;
    this.service.tax = 0;
    this.service.weight = 0;
    this.service.quantity = 0;
    this.service.addonItems = [];
    this.service.cloths = [];
    this.service.customCloths = [];
    this.clothQuery = '';
    const serachBox = document.querySelector('#searchbox') as HTMLInputElement;

    if (serachBox) {
      serachBox.value = '';
    }
  }
  updateNamePL = (event: any, cloth: any, itemno: any) => {
    if (cloth.custom == null) {
      cloth.custom = {};
    }
    cloth.custom[itemno] = event.target.value;
    console.log('TODO Update booking service and booking in backend: ', 2);
  }
  ngOnDestroy = () => {
    this.appService.drawer$.next({
      type: DRAWER_TYPES.orderBreakup,
      isVisible: false,
    });
  }
  addOrUpdateService = (serviceObj: any, parentTradeId: any) => {
    if (this.canChangeOrder()){
      // console.log(serviceObj);
      if (parentTradeId in this.services){
        if (parentTradeId === 1 || parentTradeId === 2 || parentTradeId === 3){
          if (serviceObj.cloth_id in this.services[parentTradeId].cloths){
            this.services[parentTradeId].cloths[serviceObj.cloth_id].quantity++;
          }else{
            if (!this.services[parentTradeId]){
              this.services[parentTradeId] = {cloths: {}};
            }
            // console.log(serviceObj);
            this.services[parentTradeId].cloths[serviceObj.cloth_id] = {quantity: 1, booking_id: this.bookingId, name: serviceObj.name,
              amount: (serviceObj.store_price ? serviceObj.store_price : serviceObj.price)};
          }
        }else{
          this.services[parentTradeId].quantity++;
          if (parentTradeId === 10){
            this.services[parentTradeId].amount = this.services[parentTradeId].quantity*this.services[parentTradeId].base_price;
          }
        }
      }else{
        if (parentTradeId === 1 || parentTradeId === 2 || parentTradeId === 3){
          if (!this.services[parentTradeId]){
            this.services[parentTradeId] = {booking_id: this.bookingId, cloths: {}};
          }
          this.services[parentTradeId].cloths[serviceObj.cloth_id] = {quantity: 1, booking_id: this.bookingId, name: serviceObj.name,
            amount: (serviceObj.store_price ? serviceObj.store_price :  serviceObj.price)};
        }else{
          const service: any = {
            parenttrade_id: parentTradeId,
            booking_id: this.bookingId,
            quantity: 1,
            weight: 1,
            addons: [],
            amount: serviceObj.price,
            base_price: serviceObj.price,
          };
          this.services[parentTradeId] = service;
        }
      }
      console.log('Updated services:', this.services);
  
      this.updateBookingService(parentTradeId);
      console.log('Service update queued for parentTradeId:', parentTradeId);
      
    } else {
      console.log('Cannot change order.');
    }
  }

  addAddOn = (serviceObj: any, addon: any) => {
    // console.log(serviceObj, this.services);
    if (this.canChangeOrder()){
      if (serviceObj.parenttrade_id in this.services){
        if (this.services[serviceObj.parenttrade_id].addons === undefined){
          this.services[serviceObj.parenttrade_id].addons = {};
        }
        if (addon.id in this.services[serviceObj.parenttrade_id].addons){
          if (addon.id === 7){
            this.services[serviceObj.parenttrade_id].addons[addon.id].count++;
            this.updateBookingService(serviceObj.parenttrade_id);
          }
        }else{
          this.services[serviceObj.parenttrade_id].addons[addon.id] = {
            parenttrade_id: serviceObj.parenttrade_id,
            booking_id: this.bookingId,
            addon_id: addon.id,
            count: (addon.id === 7 ? 1 : this.services[serviceObj.parenttrade_id].weight),
            price: addon.price,
            name: addon.name,
          };
          this.updateBookingService(serviceObj.parenttrade_id);
        }
      }
    }else{
      console.log('Cannot change order');
    }
  }

  initService = () => {
    this.services = {};
    if (this.order && this.order.bookingServices) {
      for (let i = 0; i < this.order.bookingServices.length; i++) {
        const service = this.order.bookingServices[i];
        if (
          service.parenttrade_id === 1 ||
          service.parenttrade_id === 2 ||
          service.parenttrade_id === 3
        ) {
          this.services[service.parenttrade_id] = {
            booking_id: this.order.id,
            cloths: {},
            customCloths: {},
          };
          const that = this;
          this.order.clothDetails.forEach(function (cloth) {
            if (cloth.parenttrade_id === service.parenttrade_id) {
              that.services[cloth.parenttrade_id].cloths[cloth.cloth_id] = {
                booking_id: cloth.booking_id,
                quantity: cloth.qty,
                amount: cloth.price,
                name: cloth.name,
              };
            }
          });

          this.order.customClothDetails.forEach(function (cloth) {
            if (cloth.parenttrade_id === service.parenttrade_id) {
              that.services[cloth.parenttrade_id].customCloths[cloth.custom_cloth] = {
                booking_id: cloth.booking_id,
                quantity: cloth.qty,
                amount: cloth.price,
                name: cloth.custom_cloth,
              };
            }
          });
        } else {
          this.services[service.parenttrade_id] = service;
        }
      }
      if (this.order.addOnDetails) {
        for (let i = 0; i < this.order.addOnDetails.length; i++) {
          const addons = this.order.addOnDetails[i];
          if (!this.services[addons.parenttrade_id].addons) {
            this.services[addons.parenttrade_id].addons = {};
          }
          this.services[addons.parenttrade_id].addons[addons.addon_id] = addons;
          this.services[addons.parenttrade_id].addons[addons.addon_id].price =
            addons.amount / addons.count;
        }
      }
      // console.log('Init Service', this.services);
    }
  }
  serviceActive = (serviceId, clothId) => {
    if (this.services[serviceId]) {
      if (clothId in this.services[serviceId].cloths) {
        return true;
      }
    }
    return false;
  }
  addOnActive = (serviceId, addOnId) => {
    if (serviceId && this.services[serviceId] && addOnId) {
      if (
        'addons' in this.services[serviceId] &&
        addOnId in this.services[serviceId].addons
      ) {
        return true;
      }
    }
    return false;
  }
  shortlistDCCloths = (inputField) => {
    if (inputField) {
      this.shortlistedDCCloths = { cloths: [] };
      for (const cloth of this.DCPricing.cloths) {
        if (
          cloth.name.toLowerCase().indexOf(inputField.value.toLowerCase()) >= 0
        ) {
          this.shortlistedDCCloths.cloths.push(cloth);
        }
      }
    } else {
      this.shortlistedDCCloths = this.DCPricing;
    }
  }
  shortlistPLCloths = (inputField) => {
    if (inputField) {
      this.shortlistedPLCloths = { cloths: [] };
      for (const cloth of this.PLPricing.cloths) {
        if (
          cloth.name.toLowerCase().indexOf(inputField.value.toLowerCase()) >= 0
        ) {
          this.shortlistedPLCloths.cloths.push(cloth);
        }
      }
    } else {
      this.shortlistedPLCloths = this.PLPricing;
    }
  }
  shortlistSPCloths = (inputField) => {
    if (inputField) {
      this.shortlistedSPCloths = { cloths: [] };
      for (const cloth of this.SPPricing.cloths) {
        if (
          cloth.name.toLowerCase().indexOf(inputField.value.toLowerCase()) >= 0
        ) {
          this.shortlistedSPCloths.cloths.push(cloth);
        }
      }
    } else {
      this.shortlistedSPCloths = this.SPPricing;
    }
  }
  weightChanged = (serviceId, event) => {
    if (this.canChangeOrder()){
      // update in service
      this.services[serviceId].weight = event.target.value;
      this.services[serviceId].amount = this.services[serviceId].weight*this.services[serviceId].base_price;
      // update in add ons
      for (let addOnId in this.services[serviceId].addons){
        if (addOnId !== '7'){
          this.services[serviceId].addons[addOnId].count = event.target.value;
        }
      }
      this.updateBookingService(serviceId);
    }else{
      console.log('Order status invalid');
      event.target.value = this.services[serviceId].weight;
    }
  }
  canChangeOrder = () => {
    if (this.order && this.order.status < this.ORDER_UNEDITABLE_CONDITION){
      this.ordermutable = true;
      return true;
    }
    this.ordermutable = false;
    return false;
  }
  quantityChanged = (serviceId, event) => {
    // update in service
    if (this.canChangeOrder()){
      this.services[serviceId].quantity = event.target.value;
      if (serviceId === 10){
        this.services[serviceId].amount = this.services[serviceId].quantity * this.services[serviceId].base_price;
      }
      // update in add ons
      // for (let addOnId in this.services[serviceId].addons){
      //   if (addOnId !== '7'){
      //     this.services[serviceId].addons[addOnId].count = event.target.value;
      //   }
      // }
      this.updateBookingService(serviceId);
    }else{
      console.log('Order status invalid');
      event.target.value = this.services[serviceId].weight;
    }
  }
  quantityChangedCloth = (serviceId, clothId, event) => {
    // update in service
    this.services[serviceId].cloths[clothId].quantity = event.target.value;
    // if (serviceId === 1 || serviceId === 2 || serviceId === 3){
    //   this.services[serviceId].amount = this.services[serviceId].quantity * this.services[serviceId].base_price;
    // }
    this.updateBookingService(serviceId);
  }
updateBookingServiceApi = (serviceId, onComplete?: () => void) => {
  this.apiService.updateBookingService(serviceId, this.services[serviceId]).subscribe(
    (data: any) => {
      console.log('Response: ', data);
      this.updateBooking();
    },
    error => {
      this.message = error.message;
    },
    () => {
      if (onComplete) {
        onComplete();
      }
    }
  );
}
updateBookingService = (serviceId) => {

  this.serviceUpdateQueue$.next({ serviceId: serviceId });
}


  changeDeliveryDateTime = () => {
    this.updateBooking();
    this.setValidTimeSlots();
  }
  customerGSTChange = (event: any) => {
    if (event.target.value === '') {
      return;
    }
    this.customerGST = event.target.value;
    this.updateBooking();
  }
  challanNumberChange = (event: any) => {
    if (event.target.value === '') {
      return;
    }
    this.challanNo = parseInt(event.target.value);
    this.updateBooking();
  }
  expressAmountChange = (event: any) => {
    if (event.target.value === '') {
      return;
    }
    this.expressAmount = parseInt(event.target.value);
    this.cdr.detectChanges();
    this.updateBooking();
  }
  gstChange = (event) => {
    // console.log(event);
    this.updateBooking();
    // save the booking in backend with out changing the status
  }
  updateBooking = () => {
    this.http
      .post(
        `${environment.SERVER_URL}bookings/updateBooking/${this.bookingId}?franchise=${environment.FRANCHISE}`,
        {
          challanNo: this.challanNo,
          customerGST: this.customerGST,
          deliveryDate: this.deliveryDate,
          deliveryTime: this.deliveryTime,
          expressAmount: this.expressAmount,
          gst: this.gst,
        },
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.getBookingSummary();
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  numSequence = (n: number) => {
    let arr = [];
    for (let i = 1; i <= n; i++) {
      arr.push(i);
    }
    return arr;
  }
}
