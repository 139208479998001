import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Input() message: string = '';
  @Input() type: number = 1; // 0 for success, 1 for error
  public typeofMessage = 0; // 0 for string, 1 for array
  public notificationIcon: string = ''; // Dynamic icon path

  constructor() {
    this.message = '';
  }

  ngOnInit(): void {
    this.setNotificationIcon();
    this.setAutoDissmiss();
  }

  ngOnChanges(): void {
    this.typeofMessage = Array.isArray(this.message) ? 1 : 0;
    
    if (this.message !== '') {
      this.setAutoDissmiss();
    }
    this.setNotificationIcon();
  }

  // Dismiss notification
  dismissNotification(): void {
    this.message = '';
    this.type = 0;
  }

  // Set notification icon based on type
  setNotificationIcon(): void {
    this.notificationIcon = this.type === 0
      ? `${environment.assetUrl}assets/images/icons/success-notification.svg`
      : `${environment.assetUrl}assets/images/icons/error-notification.svg`;
  }

  // Get dynamic class for the close modal button
  getCloseModalClass(): string {
    return this.type === 0 ? 'closeModal success' : 'closeModal error';
  }

  // Set auto-dismiss timer
  private setAutoDissmiss(): void {
    setTimeout(() => {
      this.dismissNotification();
    }, 5000); // Changed to 5 seconds (5000 ms) instead of 5000000
  }
}