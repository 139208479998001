import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-address-form',
  templateUrl: './add-address-form.component.html',
  styleUrls: ['./add-address-form.component.scss'],
})
export class AddAddressFormComponent implements OnInit {
  name: string;
  errorMessage: string;
  constructor() {}

  public activeAddressTypeIndex = 0;
  public flatInfo = '';
  public apartmentRoad = '';
  public pincode = '';
  public landmark = '';
  public contactPerson = '';
  public contactNumber = '';
  public pocketInfo = '';
  public assetUrl = environment.assetUrl;

  @Output() address = new EventEmitter<any>();

  emmitData = () => {
    this.address.emit({
      flat_info: this.flatInfo,
      street_info: this.apartmentRoad,
      pincode: this.pincode,
      contact_person: this.contactPerson,
      contact_number: this.contactNumber,
      landmark: this.landmark,
      pocket_info: this.pocketInfo,
      type: this.addressTypeList[this.activeAddressTypeIndex].name,
      city_id: 3,
    });
  }

  assignValues = (event: Event, prop: string) => {
    const target = event.target as HTMLInputElement;
    switch (true) {
      case prop === 'flat':
        this.flatInfo = target.value;
        break;
      case prop === 'apart':
        this.apartmentRoad = target.value;
        break;
      case prop === 'land':
        this.landmark = target.value;
        break;
      case prop === 'cNum':
        this.validatePhoneNumber(target, 'contactNumber');
        break;
      case prop === 'pocket':
        this.pocketInfo = target.value;
        break;
    }
    this.emmitData();
  }
  onNameChange(input: string) {
    this.name = this.formatToTitleCase(input);
    this.validateInput(this.name);
  }

  onKeyPress(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    if (!/[a-zA-Z\s]/.test(char)) {
      event.preventDefault();
      this.errorMessage = 'Only alphabetic characters and spaces are allowed.';
    } else {
      this.errorMessage = '';
    }
  }

  formatToTitleCase(input: string): string {
    return input.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }

  validateInput(input: string) {
    if (/[^a-zA-Z\s]/.test(input)) {
      this.errorMessage = 'Only alphabetic characters and spaces are allowed.';
    } else {
      this.errorMessage = '';
    }
  }
  validatePhoneNumber(inputElement: HTMLInputElement, propertyName: 'contactNumber' | 'contactPerson') {
    const input = inputElement.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    this[propertyName] = numericInput.slice(0, 10);
    inputElement.value = this[propertyName];
  }

  validatePincode(event: Event) {
    const target = event.target as HTMLInputElement;
    const input = target.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    this.pincode = numericInput.slice(0, 6);
    target.value = this.pincode;
    this.emmitData();
  }

  resetForm() {
    document.querySelectorAll('.inputField').forEach((el: HTMLInputElement) => {
      el.value = '';
    });
    this.flatInfo = '';
    this.apartmentRoad = '';
    this.pincode = '';
    this.landmark = '';
    this.contactPerson = '';
    this.contactNumber = '';
    this.pocketInfo = '';
  }

  public addressTypeList = [
    {
      name: 'Home',
      icon: `${environment.assetUrl}assets/images/icons/home.svg`,
    },
    {
      name: 'Work',
      icon: `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`,
    },
    {
      name: 'Family',
      icon: `${environment.assetUrl}assets/images/icons/family.svg`,
    },
    { name: 'Others', icon: `${environment.assetUrl}assets/images/icons/others.svg` },
    { name: 'Hotel', icon: `${environment.assetUrl}assets/images/icons/hotelIcon.svg` },
  ];

  ngOnInit(): void {}
}