import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { TabGroupComponent } from './component/tab-group/tab-group.component';
import { CounterComponent } from './component/counter/counter.component';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { SearchBarComponent } from './component/search-bar/search-bar.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownMenuComponent } from './component/dropdown-menu/dropdown-menu.component';
import { CalenderSelectComponent } from './component/calender-select/calender-select.component';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    TabGroupComponent,
    CounterComponent,
    SearchBarComponent,
    DropdownMenuComponent,
    CalenderSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialUIModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    TabGroupComponent,
    CounterComponent,
    SearchBarComponent,
    DropdownMenuComponent,
    CalenderSelectComponent
  ]
})
export class SharedModule { }
