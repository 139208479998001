import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DRY_CLEANING_LIST } from '../../invoice/invoice-form/drycleaning-filter-constant';

@Component({
  selector: 'app-order-flow-wrapper',
  templateUrl: './order-flow-wrapper.component.html',
  styleUrls: ['./order-flow-wrapper.component.scss']
})
export class OrderFlowWrapperComponent implements OnInit {

  public drycleaningItemList = DRY_CLEANING_LIST;

  constructor(
    private router: Router,
    private readonly viewportRuler: ViewportRuler) { }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--search-bar-default-width', '80vw');
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const { width, height } = this.viewportRuler.getViewportSize();
    console.log(width);
  }

  onSearchBarItemClick(event: any) {
    this.router.navigateByUrl('/order-flow/step2');
  }
}
