import { Input, EventEmitter, Output, Component, OnInit } from '@angular/core';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() totalPages: number = 0;
  public currentPage: number = 1;
  @Output() page = new EventEmitter<any>();
  updateCurrentPage(pageNumber: number) {
    if (pageNumber === -1 || pageNumber === 0 || pageNumber > this.totalPages) {
      return;
    }
    this.currentPage = pageNumber;
    this.emitData();
  }
  emitData() {
    this.page.emit({
      page: this.currentPage,
    });
  }
  constructor() {}
  getPageNumbers(): number[] {
    let pages: number[] = [];

    if (this.totalPages <= 5) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1); // always include the first page

      if (this.currentPage <= 3) {
        // Display page numbers 2, 3, 4, 5, ...
        for (let i = 2; i <= 5; i++) {
          pages.push(i);
        }
        if (this.totalPages > 5) {
          pages.push(-1); // add the '...' element
          pages.push(this.totalPages); // add the last page number
        }
      } else if (this.currentPage >= this.totalPages - 2) {
        // Display page numbers ..., totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages
        if (this.totalPages > 5) {
          pages.push(-1); // add the '...' element
        }
        for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Display page numbers ..., currentPage - 1, currentPage, currentPage + 1, ..., totalPages
        if (this.totalPages > 5) {
          pages.push(-1); // add the '...' element
        }
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pages.push(i);
        }
        if (this.totalPages > 5) {
          pages.push(-1); // add the '...' element
          pages.push(this.totalPages); // add the last page number
        }
      }
    }

    return pages;
  }
  ngOnInit(): void {}
}
