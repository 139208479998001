<div #modalContainer class="modal-container">
  <h2>Choose Payment Mode</h2>
  <div class="">
    <label>
      <input type="checkbox" [(ngModel)]="isPending" (change)="onPendingChange()"> Pending
    </label>
  </div>
  
  <div class="payment-mode-dropdown" [hidden]="isPending">
   <label>Payment Mode</label> 
    <ng-multiselect-dropdown
      [placeholder]="'Choose Payment Mode'"
      [settings]="dropdownSettings"
      [data]="paymentModes"
      [(ngModel)]="selectedModes"
      [disabled]="isPending"
      (onOpen)="onDropdownOpen()"
      (onClose)="onDropdownClose()"
      class=""
      style="height: 45px; width: 100%; border-radius: 10px; padding-left: 15px; border-color: transparent; z-index: 1000; padding: 0px 50px;"
    >
    </ng-multiselect-dropdown>
  </div>

  <div class="bottom">
    <div class="button-container">
      <button class="btn primary" (click)="onCancel()">Cancel</button>
      <button class="btn primary" (click)="onNext()" [disabled]="selectedModes.length === 0 && !isPending">
        {{ isPending ? 'Confirm' : 'Next' }}
      </button>
    </div>
  </div>
  
</div>