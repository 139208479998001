import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable, merge, iif, EMPTY, of } from 'rxjs';
import {
  map,
  filter,
  mapTo,
  startWith,
  switchMap,
  delay,
} from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatInput } from '@angular/material/input';
import {
  CdkConnectedOverlay,
  ConnectedPosition,
  ScrollStrategyOptions,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ESCAPE } from '@angular/cdk/keycodes';
import { OverlayReference } from '@angular/cdk/overlay/overlay-reference';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholderName = "Search Item's";
  @Input() itemList: any[] = [];
  @Input() functionality: number = 0; // 1 = Mobile number search , 0 = cloth search ,  2 = order search
  @Input() search: any;
  @Input() resultType: 'DRY_CLEANING' | 'IRONING' | '' = '';
  availableGroupCharList: any[] = [];
  groupedItemList: any[] = [];
  selectedItems: any = [];
  @Output() itemsSelected = new EventEmitter<any>();
  showPanel$!: Observable<boolean>;
  searchCtrl = new FormControl();
  filteredItems$!: Observable<any[]>;
  isCaseSensitive: boolean = false;

  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: -21,
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'no-enogh-space-at-bottom',
    },
  ];

  scrollStrategy!: ScrollStrategy;

  @ViewChild(MatInput, { read: ElementRef, static: true })
  private inputEl!: ElementRef;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private connectedOverlay!: CdkConnectedOverlay;

  private isPanelVisible$!: Observable<boolean>;
  private isPanelHidden$!: Observable<boolean>;
  private isOverlayDetached$!: Observable<void>;
  @Output() onItemClickEvent = new EventEmitter<any>();

  constructor(
    private focusMonitor: FocusMonitor,
    private scrollStrategies: ScrollStrategyOptions,
    private http: HttpClient,
    private router: Router
  ) {}
  emitData() {
    this.itemsSelected.emit(this.selectedItems);
  }

  ngOnInit(): void {
    this.scrollStrategy = new ConfirmScrollStrategy(this.inputEl);

    this.isPanelVisible$ = this.focusMonitor.monitor(this.inputEl).pipe(
      filter((focused) => !!focused),
      mapTo(true)
    );
    this.isOverlayDetached$ = this.isPanelVisible$.pipe(
      delay(0),
      switchMap(() =>
        iif(
          () => !!this.connectedOverlay.overlayRef,
          this.connectedOverlay.overlayRef.detachments(),
          EMPTY
        )
      )
    );
    this.isPanelHidden$ = merge(
      this.isOverlayDetached$,
      this.connectedOverlay.backdropClick
    ).pipe(mapTo(false));
    this.showPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);
    this.filteredItems$ = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((item) => {
        return item ? this._filterItems(item) : this.groupedItemList.slice();
      })
    );
  }
  handleRouteUser(id: string) {
    if (id === 'NEW') {
      this.router.navigateByUrl(`order-flow/step2?query=${this.search}`);
    } else {
      this.router.navigateByUrl(`dashboard/user/${id}`);
    }
  }

  ngOnChanges() {
    if (this.itemList.length !== 0) {
      const groupedList = this.groupIt(this.itemList);
      const chars: any = Object.keys(groupedList);
      chars.sort((a: any, b: any) => (a < b ? -1 : 1));

      this.availableGroupCharList = Object.keys(chars);
      this.groupedItemList = []; // clear the array
      chars.forEach((char: any) => {
        this.groupedItemList.push(...groupedList[char]);
      });
      this.filteredItems$ = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map((item) => {
          return item ? this._filterItems(item) : this.groupedItemList.slice();
        })
      );
    } else {
      this.groupedItemList = [];
      this.filteredItems$ = of(this.groupedItemList.slice());
    }
  }

  setCaseSensitive({ checked }: MatSlideToggleChange) {
    this.isCaseSensitive = checked;
  }

  private _filterItems(value: string): any[] {
    const filterValue = this.caseCheck(value);

    return this.groupedItemList.filter(
      (item) => this.caseCheck(item.name)?.indexOf(filterValue) === 0
    );
  }

  private caseCheck(value: string) {
    return this.isCaseSensitive ? value : value?.toLowerCase();
  }
  checkIfSelectedItemPresent(item: any) {
    let isPreset: boolean = false;
    this.selectedItems.forEach((el: any) => {
      if (el.id === item.id) {
        isPreset = true;
        return;
      }
    });
    return isPreset;
  }
  onItemClick(item: any, isAlreadyAdded: boolean) {
    if (this.functionality === 1) {
      this.handleRouteUser(item.id);
    } else if (this.functionality === 0) {
      if (!isAlreadyAdded) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = this.selectedItems.filter(
          (elm: any) => elm.id !== item?.id
        );
      }
      this.emitData();
    } else if (this.functionality === 2) {
    }
  }
  groupIt(list: any[]) {
    const groups: any = {};

    list.forEach((item) => {
      const firstLetter = item.name?.charAt(0).toUpperCase();
      groups[firstLetter] = groups[firstLetter] || [];
      if (
        !groups[firstLetter].some(
          (groupedItem: any) => groupedItem.id === item.id
        )
      ) {
        groups[firstLetter].push(item);
      }
    });

    return groups;
  }
}

class ConfirmScrollStrategy implements ScrollStrategy {
  _overlay!: OverlayReference;

  constructor(private inputRef: ElementRef) {}

  attach(overlayRef: OverlayReference) {
    this._overlay = overlayRef;
  }

  enable() {
    document.addEventListener('scroll', this.scrollListener);
  }

  disable() {
    document.removeEventListener('scroll', this.scrollListener);
  }

  private scrollListener = () => {
    this._overlay.updatePosition();
  };
}
