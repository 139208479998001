<div class="diaglog">
  <div style="display: flex; justify-content: flex-end">
    <div (click)="close()" class="cross-btn">X</div>
  </div>
  <div *ngIf="!typeOfMessage(message)">
    <app-notifications
      [message]="message"
      [type]="messageType"
    ></app-notifications>
  </div>
  <div *ngIf="typeOfMessage(message)">
    <div *ngFor="let e of message">
      <app-notifications [message]="e" [type]="1"></app-notifications>
    </div>
  </div>
  <div style="font-size: 16px; text-align: center">
    <p class="heading">Update User</p>
  </div>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <input type="text" [(ngModel)]="name" (ngModelChange)="onNameChange($event)"   value="{{ name }}" (keypress)="onKeyPress($event)" placeholder="Enter name" />
      <img src="{{ assets }}assets/images/icons/name.svg" alt="name" />
    </div>
    
  </section>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <input
        type="number"
        placeholder="Ph No"
        value="{{ mobile }}"
        [disabled]="true"
      />
      <!-- //change env -->
      <img src="../../assets/images/disabled/disabled-phone.svg" alt="name" />
    </div>
  </section>
  <section class="input-container-section mt-30">
    <div class="input-container" [ngClass]="{'error': !isEmailValid && email !== ''}">
      <img [src]="isEmailValid || email === '' ? 'assets/images/icons/mail.svg' : 'assets/images/error/mail-error.svg'" alt="mail" class="icon" />
      <input type="text" placeholder="Email" [(ngModel)]="email" (ngModelChange)="validateEmail()" />
    </div>
    <div *ngIf="!isEmailValid && email !== ''" class="error-message">
      <img src="../../assets/images/error/errror.svg" class="error-img" alt="">
      Please enter a valid email
    </div>
  </section>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <input
        type="date"
        placeholder="Date of birth"
        value="{{ dob }}"
        [(ngModel)]="dob"
      />
      <img src="{{ assets }}assets/images/icons/dob.svg" alt="name" />
    </div>
  </section>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <select (change)="onChangeSex($event)">
        <option value="" disabled selected hidden>Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>

      <img src="{{ assets }}assets/images/icons/gender.svg" alt="name" />
    </div>
  </section>

  <br />
  <div class="btn-container">
    <div class="btn-wrapper">
      <button (click)="onSubmit()" class="btn-primary">
        Update User
      </button>
    <button class="cancel" (click)="close()">Cancel</button>
    </div>

    
  </div>
</div>
