import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface CustomCloth {
  booking_id: number,
  quantity: number,
  name: string,
  amount: number, 
  note: string,
  subtrade_id: number
}

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.scss']
})
export class AddNewItemComponent implements OnInit {
  subCategory: string = 'Household';
  itemName: string = '';
  itemPrice: number | null = null;
  itemQuantity: number = 1;
  comments: string = '';
  showComments: boolean = false;
  newItem : CustomCloth = {
    booking_id: 0,
    quantity: 0,
    name: '',
    amount: 0,
    note: '',
    subtrade_id: 0
  }

  dailogData :any = null;

  categories: any[] = [
    { item_id: 1, item_text: "Men" },
    { item_id: 2, item_text: "Women" },
    { item_id: 3, item_text: "Household" },
    { item_id: 4, item_text: "Kids" },
    { item_id: 6, item_text: "Women Luxury" },
    { item_id: 7, item_text: "Men Luxury" },
    { item_id: 8, item_text: "Household Luxury" }
  ];
  selectedCategory: any = null;
  selectedCategoryValue : any = null;

  constructor(
    private dialogRef: MatDialogRef<AddNewItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dailogData = data;
  }

  ngOnInit() {
    // Set default selection to 'Household'
     this.selectedCategory = this.categories.find(cat => cat.item_text === 'Household');
     this.selectedCategoryValue = this.selectedCategory  ? this.selectedCategory.item_id : null
  }

  onCategoryChange(event: any) {
    const selectedId = parseInt(event.target.value);
    this.selectedCategoryValue = selectedId;
  }

  incrementQuantity() {
    this.itemQuantity++;
  }

  decrementQuantity() {
    if (this.itemQuantity > 0) {
      this.itemQuantity--;
    }
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  addingItem() {
    if (!this.selectedCategory) {
      console.error('No category selected');
      return;
    }
    this.newItem.booking_id = this.dailogData?.bookingId;
    this.newItem.quantity = this.itemQuantity || 1;
    this.newItem.name = this.itemName || 'Custom Cloth';
    this.newItem.amount = this.itemPrice || 0;
    this.newItem.note = this.comments;
    this.newItem.subtrade_id = this.selectedCategoryValue || 0;

    this.dialogRef.close(this.newItem);
  }

  cancel() {
    this.dialogRef.close();
  }
}