<div class="container">
  <div class="section">
    <div *ngIf="!typeOfMessage(message)">
      <app-notifications
        [message]="message"
        [type]="messageType"
      ></app-notifications>
    </div>
    <div *ngIf="typeOfMessage(message)">
      <div *ngFor="let e of message">
        <app-notifications [message]="e" [type]="1"></app-notifications>
      </div>
    </div>
    <div class="header">
      <div (click)="closeModal()" class="closeModalContainer">
        <div class="cancel-btn">X</div>
      </div>
      <h6>{{ title }}</h6>
    </div>
  </div>

  <br />

  <div class="table-section mt-20">
    <div class="assign-container">
      <div *ngFor="let r of fetchedItems; let i = index" class="assign-item">
        <div class="assign-left">
          <div class="assign-left-img-container">
            <div>{{ renderNameImg(r.name) }}</div>
          </div>
          <div class="assign-left-details">
            <div>{{ r.name ? r.name.toUpperCase() : "-" }}</div>
            <div>{{ r.mobile }}</div>
          </div>
        </div>
  
          <button
            [ngClass]="getButtonClass(r)"
            (click)="handleSelect(r)"
          >
            {{ selectedItem.id === r.id ? 'Assigned' : 'Assign' }}
          </button>             
      </div>
    </div>
  </div>

  <br />
  <hr />
  <div class="options-container">
    <div>
      Send SMS to Rider
      <input
        ng-checked="notifyPickupBoy"
        (change)="notifyPickupBoy = !notifyPickupBoy"
        type="checkbox"
      />
    </div>
    <div>
      Send SMS to Customer for Pickup
      <input
        ng-checked="notifyCustomer"
        (change)="notifyCustomer = !notifyCustomer"
        type="checkbox"
      />
    </div>
  </div>
  <br />

  
  <button class="btn primary-large" (click)="handlePost()" [disabled]="!selectedItem.id">
    Assign to {{ selectedItem.name.toUpperCase() }}
  </button>
  
</div>