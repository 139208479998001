import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';

const MAT_MODULES = [
  MatRippleModule,
  MatInputModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  MatDatepickerModule,
  MatRadioModule
];

@NgModule({
  exports: [
    ...MAT_MODULES
  ]
})
export class MaterialUIModule { }
