import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-detail-form',
  templateUrl: './customer-detail-form.component.html',
  styleUrls: ['./customer-detail-form.component.scss'],
})
export class CustomerDetailFormComponent implements OnInit {
  @Input() customerDetails: any;
  public name: string = '';
  public email: string = '';
  public mobile: string = '';
  public dob: string = '';
  public sex: string = '';
  public nameAsset: string = `${environment.assetUrl}assets/images/icons/phone.svg`;
  public assetUrl = environment.assetUrl;
  @Output() updateObj = new EventEmitter<any>();

  emitData() {
    this.updateObj.emit({
      mobile: this.mobile,
      email: this.email,
      sex: this.sex,
      dob: this.dob,
      name: this.name,
      password: '',
    });
  }

  handleChange(e: any, prop: string | number) {
    switch (true) {
      case prop === 'name':
        this.name = e.target.value;
        break;
      case prop === 'email':
        this.email = e.target.value;
        break;
      case prop === 'mobile':
        this.mobile = e.target.value;
        break;
      case prop === 'dob':
        this.dob = e.target.value;
        break;
      case prop === 'sex':
        this.sex = e.target.value;
    }
    this.emitData();
  }

  ngOnInit(): void {
    const queryParams = window.location.search.slice(1).split('=');
    if (queryParams.length > 1) {
      this.mobile = queryParams[1];
    } else {
      this.mobile = ''; 
    }
  }
}
