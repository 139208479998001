<section class="order-flow-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="white-container">
          <div class="search-bar-section">
            <app-search-bar
              [placeholderName]="'Search Mobile Number of the User'"
              (keyup)="searchUser($event)"
              [itemList]="drycleaningItemList"
              [search]="query"
              [functionality]="1"
              (onSearchBarItemClick)="onSearchBarItemClick($event)"
            
            ></app-search-bar>
          </div>
          <!-- Commented out table section, uncomment if needed -->
        </div>
      </div>
    </div>
  </div>
</section>
