const category = {
  men: 'MEN',
  women: 'WOMEN',
  kids: 'KIDS',
  womenLuxury: 'WOMEN_LUXURY',
  menLuxury: 'MEN_LUXURY',
  houseHold: 'HOUSE_HOLD',
};

export const DRY_CLEANING_LIST = [
  {
    id: 1,
    name: 'Quit Single',
    category: category.men,
  },
  {
    id: 2,
    name: 'Quit Double',
    category: category.men,
  },
  {
    id: 3,
    name: 'Quit Cover',
    category: category.men,
  },
  {
    id: 4,
    name: 'Women Cat1',
    category: category.women,
  },
  {
    id: 5,
    name: 'Kids Cat1',
    category: category.kids,
  },
  {
    id: 6,
    name: 'Womenluxury Cat1',
    category: category.womenLuxury,
  },
  {
    id: 7,
    name: 'Men Cat1',
    category: category.menLuxury,
  },
  {
    id: 8,
    name: 'Household Cat1',
    category: category.houseHold,
  },
];

export const ORDER_LISTING = [
  {
    orderNo: 'UC002-4112',
    orderStatus: 'Delivery pending',
    orderDate: '25 Nov  12:11',
    customerDetail: {
      name: 'Gunjan Taneja',
      mob: '9999999999',
      mail: 'Gunjan@uclean.in',
    },
    orderAddress:
      'room 19, Parveen Tokas Apts D77/c, Bhagwan Mahaveer Road, Sector B roadD77/C',
    pickup: {
      date: '29 Jun',
      time: '7 AM - 9 AM',
    },
  },
  {
    orderNo: 'UC002-4112',
    orderStatus: 'Delivery pending',
    orderDate: '25 Nov  12:11',
    customerDetail: {
      name: 'Gunjan Taneja',
      mob: '9999999999',
      mail: '',
    },
    orderAddress:
      'room 19, Parveen Tokas Apts D77/c, Bhagwan Mahaveer Road, Sector B roadD77/C',
    pickup: {
      date: '29 Jun',
      time: '7 AM - 9 AM',
    },
  },
  {
    orderNo: 'UC002-4112',
    orderStatus: 'Delivery pending',
    orderDate: '25 Nov  12:11',
    customerDetail: {
      name: 'Gunjan Taneja',
      mob: '9999999999',
      mail: '',
    },
    orderAddress:
      'room 19, Parveen Tokas Apts D77/c, Bhagwan Mahaveer Road, Sector B roadD77/C',
    pickup: {
      date: '29 Jun',
      time: '7 AM - 9 AM',
    },
  },
  {
    orderNo: 'UC002-4112',
    orderStatus: 'Delivery pending',
    orderDate: '25 Nov  12:11',
    customerDetail: {
      name: 'Gunjan Taneja',
      mob: '9999999999',
      mail: '',
    },
    orderAddress:
      'room 19, Parveen Tokas Apts D77/c, Bhagwan Mahaveer Road, Sector B roadD77/C',
    pickup: {
      date: '29 Jun',
      time: '7 AM - 9 AM',
    },
  },
  {
    orderNo: 'UC002-4112',
    orderStatus: 'Delivery pending',
    orderDate: '25 Nov  12:11',
    customerDetail: {
      name: 'Gunjan Taneja',
      mob: '9999999999',
      mail: '',
    },
    orderAddress:
      'room 19, Parveen Tokas Apts D77/c, Bhagwan Mahaveer Road, Sector B roadD77/C',
    pickup: {
      date: '29 Jun',
      time: '7 AM - 9 AM',
    },
  },
];
