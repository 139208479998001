import {
    Component,
    OnInit,
    ElementRef,
    Inject,
    ViewChild,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import * as html2pdf from 'html2pdf.js';
import { environment } from 'src/environments/environment';



@Component({
    selector: 'app-view-invoice',
    templateUrl: './view-invoice.component.html',
    styleUrls: ['./view-invoice.component.scss'],
})
export class ViewInvoiceComponent implements OnInit {
    public htmlContent: string;
    public css: string;
    @ViewChild('invoice') invoiceElement: ElementRef;

    // htmlContent1: string = '<!-- Your HTML invoice content here -->';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ViewInvoiceComponent>
    ) {
      const element = document.createElement('div');
      element.innerHTML = data;
      const bodyElement = element.querySelector('div.invoice-container');
      this.htmlContent = bodyElement ? bodyElement.innerHTML : '';
      const styleElement = element.querySelector('style');
      this.css = styleElement ? styleElement.innerText : '';
    }

 
    ngOnInit(): void {
      this.applyStyles();
      this.updateHeaderImage();
    }
  
    private applyStyles(): void {
      const styleSheet = document.createElement('style');
      styleSheet.innerText = this.css;
      document.head.appendChild(styleSheet);
    }
    private updateHeaderImage(): void {
      console.log('Franchise:', environment.FRANCHISE);
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.htmlContent, 'text/html');
    
      // Update header image for both invoice-body and terms-page
      const headerImg = doc.querySelector('#dynamicImage') as HTMLImageElement;
      if (headerImg) {
        // Set image source based on franchise
        if (environment.FRANCHISE === 'WHITETIGER') {
          headerImg.src = `${environment.assetUrl}assets/images/WT.png`;
        } else {
          headerImg.src = `${environment.assetUrl}assets/images/uc-white.png`;
        }
      }
      
              // Update the web link text as well
              const webLink = doc.querySelector('.text-secondary-color.web') as HTMLAnchorElement;
              if (webLink) {
                webLink.textContent = environment.FRANCHISE === 'WHITETIGER' ? 'www.whitetigercleaners.com' : 'www.uclean.in';
              }
      // Change image specifically for the terms page
      const termsHeaderImg = doc.querySelector('.terms-header #dynamicImage') as HTMLImageElement;
      if (termsHeaderImg) {
        termsHeaderImg.src = environment.FRANCHISE === 'WHITETIGER' 
          ? `${environment.assetUrl}assets/images/WT.png`
          : `${environment.assetUrl}assets/images/uc-white.png`; // This will be the default for the terms page
      }
    
      // Update footer phone number and email based on the franchise for both sections
      const phoneText = doc.querySelectorAll('.phone-link .text-secondary-color') as NodeListOf<HTMLElement>;
      const emailText = doc.querySelectorAll('.email-link .text-secondary-color') as NodeListOf<HTMLElement>;
    
      phoneText.forEach(phone => {
        if (environment.FRANCHISE === 'WHITETIGER') {
          phone.textContent = '9891759911';  // Example number for White Tiger
        } else {
          phone.textContent = '9999759911';  // Default UClean number
        }
      });
    
      emailText.forEach(email => {
        if (environment.FRANCHISE === 'WHITETIGER') {
          email.textContent = 'info@whitetigercleaners.com';  // Example email for White Tiger
        } else {
          email.textContent = 'support@uclean.in';  // Default UClean email
        }
      });
    
      // Update footer bottom disclaimer for both sections
      const footerDisclaimer = doc.querySelectorAll('.footer-bottom .disclaimer') as NodeListOf<HTMLElement>;
      footerDisclaimer.forEach(disclaimer => {
        disclaimer.textContent = environment.FRANCHISE === 'WHITETIGER'
          ? 'Disclaimer: I hereby acknowledge that I accept White Tiger service terms & conditions*'
          : 'Disclaimer: I hereby acknowledge that I accept the service terms & conditions*';
      });
    
      // Update background color of .invoice-body and .terms-page .body-header to black for WHITETIGER
      const invoiceHeader = doc.querySelector('.invoice-body .body-header') as HTMLElement;
      const termsHeader = doc.querySelector('.terms-page .body-header') as HTMLElement;
    
      if (invoiceHeader) {
        invoiceHeader.style.backgroundColor = environment.FRANCHISE === 'WHITETIGER' ? 'black' : '#003A26';
      }
    
      if (termsHeader) {
        termsHeader.style.backgroundColor = environment.FRANCHISE === 'WHITETIGER' ? 'black' : '#003A26';
      }
    
      // Update background color of .invoice-body and .terms-page .body-footer to black for WHITETIGER
      const invoiceBodyFooter = doc.querySelector('.invoice-body .body-footer') as HTMLElement;
      const termsBodyFooter = doc.querySelector('.terms-page .body-footer') as HTMLElement;
    
      if (invoiceBodyFooter) {
        invoiceBodyFooter.style.backgroundColor = environment.FRANCHISE === 'WHITETIGER' ? 'black' : '#003A26'; // Black for WHITETIGER, default color for UClean
      }
    
      if (termsBodyFooter) {
        termsBodyFooter.style.backgroundColor = environment.FRANCHISE === 'WHITETIGER' ? 'black' : '#003A26'; // Black for WHITETIGER, default color for UClean
      }
    
      // Update the text color of elements with .text-secondary-color to white for WHITETIGER
      const textSecondaryColor = doc.querySelectorAll('.text-secondary-color') as NodeListOf<HTMLElement>;
      textSecondaryColor.forEach(element => {
        element.style.color = environment.FRANCHISE === 'WHITETIGER' ? 'white' : '#74b944';  // White for WHITETIGER, green for UClean
      });
    
      // Update the htmlContent with the modified HTML
      this.htmlContent = doc.body.innerHTML;
    
      // Force change detection to render the updated HTML
      setTimeout(() => {
        if (this.invoiceElement) {
          this.invoiceElement.nativeElement.innerHTML = this.htmlContent;
        }
      });
    }

    private getFranchiseSpecificCSS(): string {
      const isWhiteTiger = environment.FRANCHISE === 'WHITETIGER';
      return `
          #dynamicImage {
              content: url(${isWhiteTiger 
                  ? `${environment.assetUrl}assets/images/WT.png`
                  : `${environment.assetUrl}assets/images/uc-white.png`}) !important;
          }
          .phone-link .text-secondary-color {
              color: ${isWhiteTiger ? 'white' : '#74b944'} !important;
          }
          .email-link .text-secondary-color {
              color: ${isWhiteTiger ? 'white' : '#74b944'} !important;
          }
          .invoice-body .body-header {
              background-color: ${isWhiteTiger ? 'black' : '#003A26'} !important;
          }
          .terms-page .body-header {
              background-color: ${isWhiteTiger ? 'black' : '#003A26'} !important;
          }
          .invoice-body .body-footer {
              background-color: ${isWhiteTiger ? 'black' : '#003A26'} !important;
          }
          .terms-page .body-footer {
              background-color: ${isWhiteTiger ? 'black' : '#003A26'} !important;
          }
          .text-secondary-color {
              color: ${isWhiteTiger ? 'white' : '#74b944'} !important;
          }
      `;
  }

  private applyFranchiseChanges(element: HTMLElement): void {
      const isWhiteTiger = environment.FRANCHISE === 'WHITETIGER';
      
      const phoneTexts = element.querySelectorAll('.phone-link .text-secondary-color') as NodeListOf<HTMLElement>;
      phoneTexts.forEach(phone => {
          phone.textContent = isWhiteTiger ? '9891759911' : '9999759911';
      });

      const emailTexts = element.querySelectorAll('.email-link .text-secondary-color') as NodeListOf<HTMLElement>;
      emailTexts.forEach(email => {
          email.textContent = isWhiteTiger ? 'info@whitetigercleaners.com' : 'support@uclean.in';
      });

      const footerDisclaimers = element.querySelectorAll('.footer-bottom .disclaimer') as NodeListOf<HTMLElement>;
      footerDisclaimers.forEach(disclaimer => {
          disclaimer.textContent = isWhiteTiger
              ? 'Disclaimer: I hereby acknowledge that I accept White Tiger service terms & conditions*'
              : 'Disclaimer: I hereby acknowledge that I accept the service terms & conditions*';
      });
  }


    onClose = () => {
        this.dialogRef.close();
    }
    processImagesInElement = async (element) => {
        // Helper function to convert an image Blob to a base64 string
        const toBase64 = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        // Extract all image elements within the provided element
        const images = element.querySelectorAll('img');
        for (let img of images) {
            const imgURL = img.src;

            try {
                // Fetch the image
                const response = await fetch(imgURL);
                if (!response.ok) throw new Error(`Failed to fetch image: ${imgURL}`);

                // Convert the image response to a Blob
                const imageBlob = await response.blob();

                // Convert the Blob to a base64 string
                const base64Data = await toBase64(imageBlob);

                // Replace the image source with the base64 data
                img.src = base64Data;
            } catch (error) {
                console.error('Error processing image:', error);
            }
        }
    }
    public async openPDF(): Promise<void> {
      const DATA = document.getElementById('invoice');
      if (!DATA) {
          console.error('Invoice element not found');
          return;
      }

      // Apply franchise-specific changes
      this.applyFranchiseChanges(DATA);

      // Dynamically create and add CSS
      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `
          .body-footer {
              page-break-after: always;
          }
          ${this.getFranchiseSpecificCSS()}
      `;
      document.head.appendChild(style);

      try {
          await this.processImagesInElement(DATA);
          console.log('Images have been processed and updated with base64 data.');

          const options = {
              filename: 'Download-invoice.pdf',
              image: { type: 'jpeg', quality: 0.95 },
              html2canvas: { 
                  scale: 1.5,
                  useCORS: true
              },
              jsPDF: { 
                  unit: 'mm', 
                  format: 'a4', 
                  orientation: 'portrait',
                  compress: true
              },
          };

          await html2pdf().set(options).from(DATA).save();
          console.log('PDF generated and saved successfully');
      } catch (error) {
          console.error('Error generating PDF:', error);
      } finally {
          // Remove the dynamically added style
          document.head.removeChild(style);
      }
  }

  printWithHeaderAndFooter(): void {
    const divContents = this.htmlContent;
    const cssContent = this.css + `
          @page {
              margin: 10;
          }
          @media print {
              body { 
                  margin: 8.5mm !important;
                  font-family: "Poppins" !important;
              }
        footer {
          position: fixed;
          bottom: 0;
        }
        header {
          position: fixed;
          top: 0;
        }
        li {
          margin-bottom: 10px !important;
          font-size: 12.5px !important;
        }
        .web {
          padding-left: 400px !important;
        }
        .terms-header {
          display: none !important;
        }
        .text {
          font-weight: 500 !important;
          font-size: 20px !important;
        }
        .icon {
          width: 20px !important;
          height: 20px !important;
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
        
                .invoice-body-inner {
                margin-top: 70px !important;
        overflow: visible !important;
      }
      .page-break {
        page-break-before: always !important;
      }
        .invoice-body {
          font-family: Poppins, sans-serif !important;
        } 
             .table-padding {
             margin-top: 70px !important;
      }
        .body-footer {
          padding-top: 27.97px !important;
          padding-left: 90px !important;
        }
        .logo-image{
           width: 225px !important;
height: 64.89px !important;
        }
        .footer {
          position: absolute !important;
          bottom: 0 !important;
        }
        .page-text {
          display: none !important;
        }
        .terms-footer {
          display: none !important;
        }
        .terms-page {
          page-break-before: always;
        }
        .tnc-body {
          margin-top: 100px !important;
        }

        .custom-block{
          margim-top: 60px !important;
        }
      
      }
    `;

    const content = window.open('', '', 'width=800,height=600');
    if (content) {
        content.document.write(`
            <html>
                <head>
                    <style>${cssContent}</style>
                </head>
                <body>${divContents}</body>
            </html>
        `);
        content.document.close();

        this.applyFranchiseChanges(content.document.body);

        // Preload the image
        const img = new Image();
        img.onload = () => {
            // Once the image is loaded, set the src of the actual image in the document
            const headerImg = content.document.querySelector('#dynamicImage') as HTMLImageElement;
            if (headerImg) {
                headerImg.src = img.src;
                headerImg.onload = () => {
                    // Apply page break logic and trigger print after image is set
                    this.applyPageBreakLogic(content.document);
                    this.triggerPrint(content);
                };
            } else {
                console.error('Dynamic image element not found');
                this.triggerPrint(content);
            }
        };
        img.onerror = () => {
            console.error('Failed to load image');
            this.triggerPrint(content);
        };

        // Set the source of the image to load
        if (environment.FRANCHISE === 'WHITETIGER') {
            img.src = `${environment.assetUrl}assets/images/WT.png`;
        } else {
            img.src = `${environment.assetUrl}assets/images/uc-black.png`;
        }
    } else {
        console.error('Failed to open new window for printing');
    }
}

// Helper method to trigger print
private triggerPrint(content: Window): void {
    setTimeout(() => {
        content.print();
        content.close();
    }, 250);
}

private applyPageBreakLogic(doc: Document): void {
    const table = doc.querySelector('table') as HTMLTableElement;
    const customBlock = doc.querySelector('.custom-block') as HTMLElement;
    
    if (table && customBlock) {
        const maxHeightPx = 500;
        
        if (table.offsetHeight > maxHeightPx) {
            const pageBreak = doc.createElement('div');
            pageBreak.className = 'page-break';
            
            const tablePadding = doc.createElement('div');
            tablePadding.className = 'table-padding';
            
            customBlock.parentNode.insertBefore(tablePadding, customBlock);
            table.parentNode.insertBefore(pageBreak, table.nextSibling);
        }
    }
}
  

    public printWithoutHeaderAndFooter(): void {
        const divContents = this.htmlContent;
        const cssContent = this.css + `
        @page {
          margin: 80;
          @top-center {
            content: none;
          }
          @bottom-center {
            content: none;
          }
        }
      `;
        const content = window.open('', '');
        content.document.write(`
      <html>
        <head>
          <style>
            ${cssContent}
            @media print {
            body{
            margin-top: 50px;
                        }
               .terms-page{
            display:none !important
            }
              body {
                margin: 0;
                padding: 0;
              }
              header {
                display: none !important;
              }
              .footer-bottom{
                display: none !important;
              }
              .body-footer{
                display: none !important;
              }
              #invoice, #invoice * {
                visibility: visible;
              }
              #invoice {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              }
            }
          </style>
        </head>
        <body>${divContents}</body>
      </html>
    `);
        content.print();
        content.document.close();
    }
}
