<section class="order-flow-section">
    <div class="container">
        <div class="row">
            
            <div class="header">
                <h2>Customer ID: {{currentUser.id}}</h2>
            </div>

            <div class="search-bar-section">
                    <div *ngIf="!typeOfMessage(message)">
                        <app-notifications
                                [message]="message"
                                [type]="messageType"
                        ></app-notifications>
                    </div>
                    <div *ngIf="typeOfMessage(message)">
                        <div *ngFor="let e of message">
                            <app-notifications [message]="e" [type]="1"></app-notifications>
                        </div>
                    </div>
                </div>
            <div class="col-md-8">
                
                <!-- Customer Details Column -->
                <div class="white-container">
                    <div class="section-header">
                        <p class="topic-head">Customer Details</p>
                        <span (click)="openUpdateUser()">Edit Info</span>
                    </div>
                    <app-customer-address-detail-form
                        (addAddressTrigger)="openAddressModal()"
                        [userDetails]="currentUser"
                        [userAddresses]="customerAddress"
                        (addressId)="reciveDataFromChild($event, 'ADDID')"
                    ></app-customer-address-detail-form>

                    <div class="section-header">
                        <p class="topic-head">Customer Activity</p>
                    </div>
                    <app-customer-activity-details
                        [userHistory]="customerHistory"
                    ></app-customer-activity-details>

                    <!-- Order History -->
                    <div class="section-header">
                        <p class="topic-head">Order History</p>
                    </div>
                    <div class="table-container">
                        <table class="order-table">
                            <thead>
                              <tr>
                                <th class="order-id-col">Order ID</th>
                                <th (click)="sortAscending('created_at')">
                                  Order Date
                                  <span class="sort-icon">{{ getSortIcon('created_at') }}</span>
                                </th>
                                <th (click)="sortAscending('delivered_at')">
                                  Delivery Date
                                  <span class="sort-icon">{{ getSortIcon('delivered_at') }}</span>
                                </th>
                                <th class="address-col">Address</th>
                                <th (click)="sortAscending('status')">
                                  Status
                                  <span class="sort-icon">{{ getSortIcon('status') }}</span>
                                </th>
                                <th class="invoice-col">
                                    Invoice 
                                  </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let order of customerPreviousOrders">
                                <td class="order-id-col">
                                  <div class="order-id">
                                    <img src="{{ renderIcons(order.orderthrough_id) }}" alt="WhatsApp" class="whatsapp-icon" />
                                    <span class="order-code">{{ order.booking_code }}</span>
                                  </div>
                                  <div class="order-type">{{order.booking_type}}</div>
                                </td>
                                <td>{{ order.created_at | date:'MMM dd, yyyy' }}<br>{{ order.created_at | date:'h:mm a' }} - {{ order.created_at | date:'h:mm a':'UTC+1' }}</td>
                                <td>{{ order.delivered_at ? (order.delivered_at | date:'MMM dd, yyyy') : '-' }}<br>{{ order.delivered_at ? ((order.delivered_at | date:'h:mm a') + ' - ' + (order.delivered_at | date:'h:mm a':'UTC+1')) : '' }}</td>
                                <td class="address-col">{{ order.address }}</td>
                                <td>
                                  <span class="status-badge" [ngClass]="{
                                    'new-order': order.status === 1,
                                    'cancelled': order.status === 0,
                                    'delivered': order.status === 7,
                                    'in-process': order.status === 3,
                                    'pickup-assigned': order.status === 2
                                  }">{{ renderStatus(order.status) }}</span>
                                </td>
                                <td class="invoice-col">
                                    <div class="invoice-code" (click)="viewInvoice(order?.id)">
                                      {{order.invoice_id}}
                                    </div>
                                    <ng-container *ngIf="order.final_amount">
                                      Final amount: {{order.final_amount}}
                                    </ng-container>
                                  </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                </div>
            </div>
            <div class="col-md-4">
                <!-- Create Order Column -->
                <div class="white-container">
                    <div class="section-header">
                        <p class="topic-head">Create Order</p>
                    </div>
                    <app-order-detail-form
                        (order)="reciveDataFromChild($event, 'ORDER')"
                    ></app-order-detail-form>
                    <div class="btn-wrapper">
                        <button class="submit-button" (click)="handleCreateOrder()">
                            Add Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>