<section class="customer-directory-section" style="margin-top: 100px">
  <div class="container">
    <!-- <div class="field-container">
      <div>From Date</div>
      <input
        placeholder="ZYA"
        onfocus="(this.type = 'date')"
        type="date"
        [(ngModel)]="from"
        [placeholder]="'myPlaceholderText'"
      /> -->
    <!-- </div> -->
    <div class="field-container">
      <div class="decription">Showing Results for</div>
      <input placeholder="ZYA" type="number"
      [(ngModel)]="year" [placeholder]="'Enter the year'"
      [max]="currentYear" (input)="validateYear($event)"
     />
    </div>

    <div class="btn-wrapper">
      <button class="btn-primary" (click)="generateReport()">Submit</button>
    </div>
    <!-- <input type="date" /> -->
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <table>
            <tr class="header-tr">
              <td class="header" style="width: 13%">Month</td>
              <td class="header" style="width: 13%">Wash & Fold</td>
              <td class="header" style="width: 13%">Wash & Iron</td>
              <td class="header" style="width: 13%">Steam Pressing</td>
              <td class="header" style="width: 13%">Standard Iron</td>
              <td class="header" style="width: 13%">Premium Laundry</td>
              <td class="header" style="width: 13%">Dry Cleaning</td>
            </tr>
            <!-- <div class="table-body-wrapper"> -->
            <div
              *ngIf="data === null"
              class="loader-container"
              style="display: flex; justify-content: center"
            >
              <app-loader></app-loader>
            </div>
            <tr *ngFor="let e of data; let i = index">
              <td style="width: 13%">
                <span
                  ><b class="green-text">{{ getMonth(e.month) }}</b></span
                >
              </td>
              <td style="width: 13%">
                <span class="txt-align-center">{{ e.Wash_N_Fold }}</span
                ><br />
              </td>
              <td style="width: 13%">
                <span class="txt-align-center">{{ e.Wash_N_Iron }}</span
                ><br />
              </td>
              <td style="width: 13%">
                <span class="txt-align-center">{{ e.Steam_Pressing }}</span>
              </td>
              <td style="width: 13%">
                <span class="txt-align-center" matRipple>
                  {{ e.Standard_Iron }}
                </span>
              </td>
              <td style="width: 13%">
                <span class="txt-align-center">{{ e.Premium_Laundry }}</span>
              </td>
              <td style="width: 13%">
                <span class="txt-align-center">{{ e.Drycleaning }}</span>
              </td>

              <!-- <td style="width: 7%">
                <span class="txt-align-center">{{ e.excluding }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.final_amount }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.payment_status }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.amount }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.pickup_date | date }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.address }}</span>
              </td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
