<div *ngIf="address && address.length <= 50">{{ address }}</div>
<div *ngIf="address && address.length > 50">
  <div class="ellipse" style="position: relative" *ngIf="!showAll">
    {{ address.slice(0, 80) }}...
  </div>
  <div *ngIf="showAll">{{ address }}</div>
  <button class="view" (click)="showAll = !showAll">
    {{ showAll ? "Show less" : "Show more" }}
  </button>
</div>
