import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss'],
})
export class BackBtnComponent implements OnInit {
  constructor(private location: Location, private Router: Router) {}

  goBack() {
    this.Router.navigateByUrl('/dashboard?back=true');
    // this.location.back();
  }
  ngOnInit(): void {}
}
