import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-item-quantity',
  templateUrl: './item-quantity.component.html',
  styleUrls: ['./item-quantity.component.scss']
})
export class ItemQuantityComponent {
  @Input() quantity: number = 0;
  @Output() quantityChange = new EventEmitter<number>();

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (!isNaN(value)) {
      this.quantity = value;
      this.emitQuantityChange();
    }
  }

  private emitQuantityChange() {
    this.quantityChange.emit(this.quantity);
  }
}