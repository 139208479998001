import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-daily-sales-report',
  templateUrl: './daily-sales-report.component.html',
  styleUrls: ['./daily-sales-report.component.scss'],
})
export class DailySalesReportComponent implements OnInit {
  from: string = '';
  to: string = '';
  message: string = '';
  selectedReport: string = 'DSR';
  messageType: number = 0;
  data;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    // Initialize component
  }
  public export = `${environment.assetUrl}assets/images/export.svg`;
  onDateRangeSelected(dateRange: { from: string, to: string }) {
    this.from = dateRange.from;
    this.to = dateRange.to;
    this.generateReport();
  }
  exportReport() {
    if (!this.data) {
      this.message = 'No data available to export';
      this.messageType = 2; // Error message type
      return;
    }

    let exportData: any[];
    let filename: string;

    switch (this.selectedReport) {
      case 'DSR':
        exportData = this.prepareDSRExportData();
        filename = `DSR_Report_${this.from}_to_${this.to}.xlsx`;
        break;
      case 'Collection':
        exportData = this.prepareCollectionExportData();
        filename = `Collection_Report_${this.from}_to_${this.to}.xlsx`;
        break;
      case 'Quantity':
        exportData = this.prepareQuantityExportData();
        filename = `Quantity_Report_${this.from}_to_${this.to}.xlsx`;
        break;
      default:
        this.message = 'Invalid report type';
        this.messageType = 2; // Error message type
        return;
    }

    // Create worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    // Save the file
    XLSX.writeFile(wb, filename);

    // Optional: Show success message
    this.message = 'Report exported successfully';
    this.messageType = 1; // Success message type
  }

  private prepareDSRExportData(): any[] {
    return this.data.map(item => ({
      'Date': item.odate,
      'D.C. Sale': item.dc_sale,
      'PL/KG Sale': item.premium_laundry,
      'Laundry (W & F) Sale': item.laundry_wash_fold,
      'Laundry (W & I) Sale': item.laundry_wash_iron,
      'S.P. Sale': item.steam_press,
      'S.I. Sale': item.standard_iron,
      'Fabric Softener': item.addon_1,
      'Antiseptic Wash': item.addon_2,
      'Hanger Packing': item.addon_3,
      'Discount': item.discount,
      'Net Amount': this.calculateNetAmount(item),
      'GST (18%)': item.tax,
      'Total': item.total_sale
    }));
  }

  private prepareCollectionExportData(): any[] {
    return this.data.dailySummary.map(item => ({
      'Date': item.date,
      'Cash': item.paymentModes['4']?.amount || 0,
      'Credit/Debit Card': item.paymentModes['2']?.amount || 0,
      'UPI/Wallet': item.paymentModes['1']?.amount || 0,
      'Bank Transfer': item.paymentModes['3']?.amount || 0,
      'Total Payment Received': 
        (item.paymentModes['4']?.amount || 0) + 
        (item.paymentModes['2']?.amount || 0) + 
        (item.paymentModes['1']?.amount || 0) + 
        (item.paymentModes['3']?.amount || 0)
    }));
  }

  private prepareQuantityExportData(): any[] {
    return this.data.map(item => ({
      'Date': item.date,
      'D.C. Qty': item.dc_quantity,
      'PI/Kg Qty Pcs.': item.pi_kg_quantity_pcs,
      'PI/Kg Qty KG': item.pi_kg_quantity_kg,
      'Laundry (W & I) Qty Pcs.': item.laundry_quantity_pcs,
      'Laundry (W & F) Qty KG': item.laundry_quantity_kg,
      'S.P. Qty': item.sp_quantity,
      'S.I. Qty': item.si_quantity
    }));
  }


  generateReport() {
    this.data = null;
    this.apiService.generateDSR(this.from, this.to).subscribe(
      (response: any) => {
        this.data = this.processData(response.data);
        console.log(this.data);
      },
      (error) => {
        console.error('Error generating DSR:', error);
      }
    );
  }

  generateCollectionReport() {
    console.log('Generating Collection Report for:', this.from, this.to); // Log the date range
  
    this.apiService.generateCollectionReport(this.from, this.to).subscribe(
      (response: any) => {
        console.log('Raw Collection Report Response:', response); // Log raw response
        this.data = this.processCollectionData(response.data);
        console.log('Processed Collection Data:', this.data); // Log processed data
      },
      (error) => {
        console.error('Error generating Collection Report:', error);
      }
    );
  }
  
  
  processCollectionData(rawData: any): any {
    console.log('Processing Raw Collection Data:', rawData); // Log raw data before processing
    
    return {
      dailySummary: rawData.dailySummary.map(item => ({
        ...item,
        date: new Date(item.date).toISOString().split('T')[0]
      })),
      overallSummary: rawData.overallSummary
    };
  }
  

  processData(rawData: any[]): any[] {
    return rawData.map(item => ({
      ...item,
      odate: new Date(item.odate).toISOString().split('T')[0]
    }));
  }
  generateQuantityReport() {
    console.log('Generating Quantity Report for:', this.from, this.to); // Log the date range

    this.apiService.generateQuantityReport(this.from, this.to).subscribe(
      (response: any) => {
        console.log('Raw Quantity Report Response:', response); // Log raw response
        this.data = this.processQuantityData(response.data);
        console.log('Processed Quantity Data:', this.data); // Log processed data
      },
      (error) => {
        console.error('Error generating Quantity Report:', error);
      }
    );
  }

  processQuantityData(rawData: any): any {
    console.log('Processing Raw Quantity Data:', rawData); // Log raw data before processing
  
    return rawData.map(item => ({
      date: new Date(item.odate).toISOString().split('T')[0],  // Using 'odate' as the date field
      dc_quantity: item.dc_sale_quantity,
      pi_kg_quantity_pcs: item.premium_laundry_quantity,      
      pi_kg_quantity_kg: item.premium_laundry_kg_quantity,    
      laundry_quantity_pcs: item.laundry_wash_iron_quantity,  
      laundry_quantity_kg: item.laundry_wash_fold_quantity,   
      sp_quantity: item.steam_press_quantity,               
      si_quantity: item.standard_iron_quantity                      
    }));
  }
  

  calculateGST(item: any): number {
    return this.calculateNetAmount(item) * 0.18; // 18% GST
  }

  calculateTotal(item: any): number {
    return this.calculateNetAmount(item) + this.calculateGST(item);
  }

  getParentTradeName(id: number): string {
    switch (id) {
      case 1: return "Dry cleaning";
      case 2: case 9: return "Premium Laundry";
      case 3: case 10: return "Steam Press";
      case 4: return "Laundry - Wash & Fold";
      case 5: return "Laundry - Wash & Iron";
      case 6: return "Home Service";
      case 8: return "Vacuum Iron";
      default: return "Unknown";
    }
  }
  calculateNetAmount(item: any): number {
    return (
      item.dc_sale +
      item.premium_laundry +
      item.laundry_wash_fold +
      item.laundry_wash_iron +
      item.steam_press +
      item.standard_iron +
      item.addon_1 +
      item.addon_2 +
      item.addon_3 +
      item.express_sale -
      item.discount
    );
  }
  showReport(reportType: string) {
    this.selectedReport = reportType;

    switch (reportType) {
      case 'DSR':
        this.generateReport(); // DSR Report
        break;
      case 'Collection':
        this.generateCollectionReport(); // Collection Report
        break;
      case 'Quantity':
        this.generateQuantityReport(); // Quantity Report
        break;
      default:
        console.error('Unknown report type selected');
    }
  }

}