import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss'],
})
export class CommentBoxComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: any;
  public notes: string = '';
  @Output() trigger = new EventEmitter<any>();

  constructor(private dialogRef: MatDialogRef<CommentBoxComponent>) {}
  emitData() {
    this.trigger.emit({ note: this.notes });
  }
  handleClose() {
    this.dialogRef.close();
  }

  handleSubmit() {
    this.emitData();
    this.handleClose();
  }
  ngOnInit(): void {}
}
