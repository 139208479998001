import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import {  } from 'stream';

@Component({
  selector: 'app-customer-address-detail-form',
  templateUrl: './customer-address-detail-form.component.html',
  styleUrls: ['./customer-address-detail-form.component.scss'],
})
export class CustomerAddressDetailFormComponent implements OnInit {
  @Input() userDetails: any;
  @Input() userAddresses: any;
  #addressId: number = 0;
  @Output() addAddressTrigger = new EventEmitter<any>();
  @Output() addressId = new EventEmitter<any>();

  formatAddress = (address: string) => address.replace(/, ,/g, ',');
  // public house =
  addAddress = () => {
    this.addAddressTrigger.emit({
      trigger: true,
    });
  }
  emitData = () => {
    this.addressId.emit({
      address_id: this.#addressId,
    });
  }
  handleChangeOnRadio = (e: any) => {
    this.#addressId = e.value;
  }
  constructor() {}

  ngOnInit(): void {
    console.log(this.userDetails);
  }
}
