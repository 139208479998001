<div class="comment-box">
  <h3>Add comments</h3>

  <textarea [(ngModel)]="notes" (change)="emitData()"></textarea>
  <br />
  <div class="btn-container">
    <button class="cancel" (click)="handleClose()">Cancel</button>
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="handleSubmit()">Add</button>
    </div>
  </div>
</div>
