<div class="section partition">
  <br />
  <div class="activity-grid">
    <div class="activity-item">
      <span class="label">TOTAL ORDERS</span>
      <span class="label">{{ userHistory?.numberOfOrders }}</span>
    </div>
    
    <div class="activity-item">
      <span class="label">CUSTOMER SINCE</span>
      <span class="label">{{ userHistory?.customerSince | date:'MMM dd, yyyy' | uppercase }}</span>
    </div>
    
    <div class="activity-item">
      <span class="label">TOTAL ORDERS VALUE</span>
      <span class="label">{{ userHistory?.totalAmountOrderd | number : "1.1-1"  }}</span>
    </div>
    
    <div class="activity-item">
      <span class="label">LAST ORDER</span>
      <span class="label">{{ userHistory?.latestOrder | date:'MMM dd, yyyy' | uppercase }}</span>
    </div>
  </div>
</div>
