import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  isArray(obj: any) {
    if (Array.isArray(obj)) {
      return true;
    } else {
      return false;
    }
  }
  setLocalStorage(value: string, key: string) {
    localStorage.setItem(key, value);
  }
  getLocalStorageItem(key: string) {
    return localStorage.getItem(key);
  }
  deleteLocalStorage(key: string) {
    localStorage.removeItem(key);
  }
  roundOffNumber(int: number) {
    return Math.floor(int);
  }
  getFranchise(): any {
    if(window.location.origin == 'https://store.ucleanlaundry.com') {
      return 'UCLEAN';
    } else if (window.location.origin == 'https://store.whitetigerlaundry.com') {
      return 'WHITETIGER';
    }
  }
  extractDomainFromUrl(url: string): string {
    const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im);
    return match ? match[1] : '';
  }

  constructor() {}
}
