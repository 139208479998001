import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreateBookingService {
  private booking$ = new BehaviorSubject<any>({});

  public getData(): Observable<any> {
    return this.booking$;
  }
  public setData(data: any) {
    console.log({ setD: data });
    this.booking$.next(data);
  }
}
