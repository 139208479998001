import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CouponsService } from 'src/app/service/coupons.service';

@Component({
  selector: 'app-add-coupon-step2',
  templateUrl: './add-coupon-step2.component.html',
  styleUrls: ['./add-coupon-step2.component.scss'],
})
export class AddCouponStep2Component implements OnInit {
  @ViewChild('discountInput') discountInput!: ElementRef;

  public couponType: string; // flat or Percentage
  public discount: number; // discount percentage or value
  public minimumAmount: number;  // Order min value on which discount is applicable
  public maximumDiscount: number; // max discount
  public usageLimit: number ; // how many times coupon can be used
  public orderType: string ; // first order or any order

  public message = '';
  public messageType = 1;
  public isChecked = true;

  constructor(public couponService: CouponsService, private router: Router) {}
  ngOnInit(): void {
    this.couponService.getData(2).subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.discount = data.discount;
        this.maximumDiscount = data.maximumDiscount;
        this.minimumAmount = data.minimumAmount;
        this.couponType = data.couponType;
        this.usageLimit = data.usageLimit;
        this.orderType = data.orderType;
      }
    });
  }
  handleChange = (type: any) => {
    if (type.target.value === 1){
      this.usageLimit = 1;
    }
    this.isChecked = !this.isChecked;
  }
  handleOnChange = (type: any) => {
    this.couponType = type.target.value;
    this.isChecked = !this.isChecked;
  }
  setError = (title: string) => {
    this.message = title;
    this.messageType = 1;
    return false;
  }
  validateData = () => {
    switch (true) {
      case !this.couponType:
        return this.setError('You need to select between type of coupon');
      case !this.orderType:
          return this.setError('You need to select between First Order or Any Order');  
      case this.orderType ==='2' && !this.usageLimit:
            return this.setError('Usage Limit cannot be 0');     
      case !this.discount:
        return this.setError('Discount amount/percentage cannot be 0');
      case this.couponType === '1' && !this.maximumDiscount:
        return this.setError('Maximum discount cannot be 0');
      case !this.minimumAmount:
        return this.setError('Minimum amount cannot be 0');
    }
    return true;
  }
  goToNextStep = () => {
    const dataValid = this.validateData();
    if (!dataValid) {
      console.log(this.message);
      return;
    } else {
      const step2Data = {
        couponType: parseInt(this.couponType),
        discount: this.discount,
        maximumDiscount: this.maximumDiscount,
        minimumAmount: this.minimumAmount,
        usageLimit: this.usageLimit,
        orderType: parseInt(this.orderType),
      };
      this.couponService.setData(step2Data, 2);
      this.router.navigateByUrl('add-coupons-step-3');
    }
  }
  checkValue = (val: any, c: string) => {
    const { value } = val.target;
    const element: any = document.querySelector(`.${c}`);
    if (+value < 0) {
      element.value = 0;
      return;
    }
    // const element  = document.querySelector
  }
  goBack = () => {
    this.router.navigateByUrl('add-coupons-step-1');
  }
}
