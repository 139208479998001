import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-package-table',
  templateUrl: './package-table.component.html',
  styleUrls: ['./package-table.component.scss'],
})
export class PackageTableComponent implements OnInit {
  public data: any[] = [];

  constructor(private http: HttpClient, private Utils: UtilityService) {}
  ngOnInit(): void {
    this.getPackages();
  }

  getPackages() {
    this.http
      .get(`${environment.SERVER_URL}packages?franchise=${environment.FRANCHISE}`, {
        headers: {
          Authorization: `Bearer ${this.Utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.data = data.data;
      });
  }
}
