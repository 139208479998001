import { Output, Input, Component, OnInit, EventEmitter } from '@angular/core';
// import { EventEmitter } from 'stream';

export interface Cloths {
  name: string;
  quantity: number;
  price: number;
  category: string;
  hanger?: number;
}
@Component({
  selector: 'app-premium-laundry',
  templateUrl: './premium-laundry.component.html',
  styleUrls: ['./premium-laundry.component.scss'],
})
export class PremiumLaundryComponent implements OnInit {
  @Input() price: number = 0;
  @Input() category = '';
  @Output() clothObj = new EventEmitter<any>();
  private cloth: Cloths;
  constructor() {
    this.cloth = {
      category: '',
      name: '',
      price: 0,
      quantity: 0,
      hanger: 0,
    };
  }

  emitData() {
    this.clothObj.emit({
      price: this.price,
      category: this.category,
      name: this.cloth.name,
      quantity: this.cloth.quantity,
      hanger: this.cloth.hanger,
    });
  }
  handleChange(value: any, type: string) {
    switch (true) {
      case type === 'count':
        this.cloth.quantity = value;
        break;
      case type === 'name':
        this.cloth.name = value.target.value;
        break;
      case type == 'addon':
        !this.cloth.hanger
          ? (this.cloth.hanger = parseInt(value.target.value))
          : (this.cloth.hanger = 0);
    }
    this.emitData();
  }
  ngOnInit(): void {}
}
