import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-activity-details',
  templateUrl: './customer-activity-details.component.html',
  styleUrls: ['./customer-activity-details.component.scss'],
})
export class CustomerActivityDetailsComponent implements OnInit {
  @Input() userHistory: any;
  constructor() {}

  ngOnInit(): void {}
}
