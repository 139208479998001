<section class="customer-directory-section">
  <br />

  <div class="container">
    <div class="field-container margin-left">
      <div>Sort by amount</div>
      <!-- <input type="date" [placeholder]="'myPlaceholderText'" /> -->
      <select class="select-items" [(ngModel)]="filter" (change)="onChange()">
        <option disabled selected>Select</option>
        <option *ngFor="let i of sortAmount" value="{{ i.value }}">
          {{ i.label }}
        </option>
      </select>
    </div>
    <div class="field-container margin-left">
      <div>Sort by Last Order</div>
      <!-- <input type="date" [placeholder]="'myPlaceholderText'" /> -->
      <select class="select-items" [(ngModel)]="filter" (change)="onChange()">
        <option disabled selected>Select</option>
        <option *ngFor="let i of sortLastOrder" value="{{ i.value }}">
          {{ i.label }}
        </option>
      </select>
    </div>
    <div class="field-container margin-left">
      <div>Sort by First Order</div>
      <!-- <input type="date" [placeholder]="'myPlaceholderText'" /> -->
      <select class="select-items" [(ngModel)]="filter" (change)="onChange()">
        <option disabled selected>Sort by First Order</option>
        <option *ngFor="let i of sortFirstOrder" value="{{ i.value }}">
          {{ i.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <table>
            <tr class="header-tr">
              <td class="header" style="width: 20%">Name</td>
              <td class="header" style="width: 20%">Mobile</td>
              <td class="header" style="width: 20%">Average Amount</td>
              <td class="header" style="width: 20%">First Order</td>
              <td class="header" style="width: 20%">Last Order</td>
              <!-- <td class="header" style="width: 7%">Excluding GST</td>
              <td class="header" style="width: 7%">Final Amount</td>
              <td class="header" style="width: 7%">Payment Status</td>
              <td class="header" style="width: 7%">Amount</td>
              <td class="header" style="width: 10%">Pickup Date</td>
              <td class="header" style="width: 10%">Address</td> -->
            </tr>
            <div
              *ngIf="data === null"
              class="loader-container"
              style="display: flex; justify-content: center"
            >
              <app-loader></app-loader>
            </div>
            <!-- <div class="table-body-wrapper"> -->
            <tr *ngFor="let e of data; let i = index">
              <td style="width: 20%">
                <span
                  ><b class="green-text">{{ e.customer_name }}</b></span
                >
              </td>
              <td style="width: 20%">
                <span class="txt-align-center">{{ e.customer_mobile }}</span
                ><br />
              </td>
              <td style="width: 20%">
                <span class="txt-align-center">{{ e.avg_billing_amount }}</span
                ><br />
              </td>
              <td style="width: 20%">
                <span class="txt-align-center">{{ e.first_order | date }}</span>
              </td>
              <td style="width: 20%">
                <span class="txt-align-center" matRipple>
                  {{ e.last_order | date }}
                </span>
              </td>
              <!-- <td style="width: 7%">
                <span class="txt-align-center">{{ e.discount }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.including_gst }}</span>
              </td>

              <td style="width: 7%">
                <span class="txt-align-center">{{ e.excluding }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.final_amount }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.payment_status }}</span>
              </td>
              <td style="width: 7%">
                <span class="txt-align-center">{{ e.amount }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.pickup_date | date }}</span>
              </td>
              <td style="width: 10%">
                <span class="txt-align-center">{{ e.address }}</span>
              </td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
