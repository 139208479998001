import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  get isSuccess(): boolean {
    return this.data.status === 'success';
  }

  get needsReason(): boolean {
    return this.data.status === 'warning' && this.data.message.includes('Please provide a reason');
  }

  onClose(): void {
    this.dialogRef.close();
  }
}