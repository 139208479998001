<div class="sidebar">
  <div class="sidebar-heading">
  </div>
  <br />
  <div
    class="sidebar-item"
    (click)="emitData(parenTrade?.id)"
    [ngClass]="{ active: activeTab === parenTrade?.id }"
    *ngFor="let parenTrade of parenTrades"
  >
    <img [src]=getIcon(parenTrade) />
    <span>{{parenTrade?.name}}</span>
  </div>
</div>
