<div class="customer-detail-section">
  <div class="detail-item">
    <div>
      <p>NAME</p>
    </div>
    <span>-</span>
    <div>
      <p>{{ userDetails?.name }}</p>
    </div>
  </div>
  <div class="detail-item">
    <div>
      <p>EMAIL</p>
    </div>
    <span>-</span>
    <div>
      <p>{{ userDetails?.email }}</p>
    </div>
  </div>
  <div class="detail-item">
    <div>
      <p>PH NO</p>
    </div>
    <span>-</span>
    <p>+91 {{ userDetails?.mobile }}</p>
  </div>
</div>
<div class="address-container">
  <div class="section-header">
    <p class="topic-head">Addresses</p>
    <span (click)="addAddress()">Add Address</span>
  </div>
  <div style="margin-left: -5px" class="add-address-input">
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
    >
      <mat-radio-button
        (change)="handleChangeOnRadio($event)"
        (change)="emitData()"
        class="example-radio-button"
        *ngFor="let a of userAddresses; let i = index"
        [value]="a.id"
      >
        <div>
          {{ formatAddress(a?.address) }}
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
