<section class="order-flow-section">
  <div *ngIf="!typeOfMessage(message)">
    <app-notifications
      [message]="message"
      [type]="messageType"
    ></app-notifications>
  </div>
  <div *ngIf="typeOfMessage(message)">
    <div *ngFor="let e of message">
      <app-notifications [message]="e" [type]="1"></app-notifications>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="white-container">
          <ng-container *ngTemplateOutlet="box"></ng-container>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #box>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="box box1">
          <div class="section-header">
            <span>1</span>
            <p>Customer Details</p>
          </div>
          <app-customer-detail-form
            (updateObj)="reciveDataFromChild($event, 'user')"
          ></app-customer-detail-form>
          <div class="btn-wrapper">
            <button class="btn-primary" (click)="handleAddUser()">
              Add user
            </button>
          </div>
        </div>
      </div>

<!--      <div class="col-md-4">-->
<!--        <div class="box box2">-->
<!--          <div class="section-header">-->
<!--            <span>2</span>-->
<!--            <p>Add Address</p>-->
<!--          </div>-->
<!--          <app-add-address-form-->
<!--            (address)="reciveDataFromChild($event, 'add')"-->
<!--          ></app-add-address-form>-->
<!--        </div>-->
<!--        <div class="btn-wrapper">-->
<!--          <button-->
<!--            class="btn-primary"-->
<!--            (click)="handleAddAddress()"-->
<!--            [disabled]="formStage !== 2"-->
<!--          >-->
<!--            Add address-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-4">-->
<!--        <div class="box box3">-->
<!--          <div class="section-header">-->
<!--            <span>3</span>-->
<!--            <p>Order Details</p>-->
<!--          </div>-->
<!--          <app-order-detail-form-->
<!--            (order)="reciveDataFromChild($event, 'order')"-->
<!--          ></app-order-detail-form>-->
<!--        </div>-->
<!--        <div class="btn-wrapper">-->
<!--          <button-->
<!--            class="btn-primary"-->
<!--            [disabled]="formStage !== 1"-->
<!--            (click)="handleAddBooking()"-->
<!--          >-->
<!--            Confirm Order-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</ng-template>
