<div class="container">
  <div class="section">
    <div style="display: flex; justify-content: flex-end">
      <mat-icon class="closeBtn" (click)="closeModal()">close</mat-icon>
    </div>
    <div *ngIf="!typeOfMessage(message)">
      <app-notifications
        [message]="message"
        [type]="messageType"
      ></app-notifications>
    </div>
    <div *ngIf="typeOfMessage(message)">
      <div *ngFor="let e of message">
        <app-notifications [message]="e" [type]="1"></app-notifications>
      </div>
    </div>
    <h6>{{ messageToDisplay }}</h6>
  </div>
  <!-- <button>Clickme</button> -->
  <br />
  <div *ngIf="delete === 1">
    <!-- <p></p> -->
    <select [(ngModel)]="reason">
      <option selected disabled>Please select the reason</option>
      <option *ngFor="let i of reasons" value="{{ i }}">{{ i }}</option>
    </select>
  </div>
  <br />
  <div
    class="bodySection"
    style="display: flex; align-items: center; justify-content: space-around"
  >
    <div class="item">
      <div class="btn-wrapper">
        <button (click)="closeModal()">Cancel</button>
      </div>
    </div>
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="handlePost()">Go ahead</button>
    </div>
  </div>
  <br />
</div>
