<div class="pagination-container">
  <div
    class="pagination-btn"
    (click)="updateCurrentPage(currentPage - 1)"
  >
    Prev
  </div>
  <div class="pagination-page-number">
    <div *ngFor="let page of getPageNumbers(); let i = index">
      <div
        class="page-number"
        [ngClass]="{ active: currentPage === page }"
        (click)="updateCurrentPage(page)"
        *ngIf="i < 5 || page === -1"
      >
        {{ page === -1 ? "..." : page }}
      </div>
      <!-- <div class="page-number" *ngIf="i === totalPages - 1">{{ page }}</div> -->
    </div>
  </div>
  <div
    class="page-number"
    (click)="updateCurrentPage(totalPages)"
    *ngIf="totalPages > 5"
    [ngClass]="{ active: currentPage === totalPages }"
  >
    {{ totalPages }}
  </div>
  <div
    class="pagination-btn"
    (click)="updateCurrentPage(currentPage + 1)"
  >
    Next
  </div>
</div>
