<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'flat')"
      (keyup)="emmitData()"
      placeholder="House/ flat number/ Floor No"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'apart')"
      (keyup)="emmitData()"
      placeholder="Apartment/ Road / Area"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'pin')"
      (input)="validatePincode($event)"
      [(ngModel)]="pincode"
      maxlength="6"
      placeholder="Area pincode *"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'land')"
      (keyup)="emmitData()"
      placeholder="Landmark"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
<input
class="inputField"
[(ngModel)]="name" (ngModelChange)="onNameChange($event)"   value="{{ name }}" (keypress)="onKeyPress($event)" placeholder="Enter name"
placeholder="Contact Person Name"
/>
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
    class="inputField no-spinner"
    type="tel"
    (input)="assignValues($event, 'cNum')"
    placeholder="Contact person number"
  />
  
  </div>
</section>
<section class="save-as-section">
  <p class="save-as-header">Save As</p>
  <ul class="address-list-container">
    <li
      class="address"
      [ngClass]="{ active: i === activeAddressTypeIndex }"
      *ngFor="let addressType of addressTypeList; let i = index"
      (click)="activeAddressTypeIndex = i"
    >
      <img [src]="addressType?.icon" />
      <p class="address-type-name">{{ addressType?.name }}</p>
    </li>
  </ul>
</section>
