import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  private stepOnedata = new BehaviorSubject<any>(null);
  private stepTwoData = new BehaviorSubject<any>(null);
  private stepThreeData = new BehaviorSubject<any>(null);

  constructor() {}
  setData = (data: any, step: number) => {
    switch (true) {
      case step === 1:
        this.stepOnedata.next(data);
        break;
      case step === 2:
        this.stepTwoData.next(data);
        break;
      case step === 3:
        this.stepThreeData.next(data);
        break;
    }
  }
  getData = (step: number) => {
    switch (true) {
      case step === 1:
        return this.stepOnedata.asObservable();
      case step === 2:
        return this.stepTwoData.asObservable();
      case step === 3:
        return this.stepThreeData.asObservable();
    }
    return;
  }
}
