<div>
  <div style="display: flex;justify-content: space-around;">
    <div></div>
    <div>
      <p class="add-address">Add address</p>
    </div>
    <div>
      <button class="cancel" style="color: black; border: none">
        <mat-icon class="" (click)="close()">clear</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!typeOfMessage(message)">
    <app-notifications
      [message]="message"
      [type]="messageType"
    ></app-notifications>
  </div>
  <div *ngIf="typeOfMessage(message)">
    <div *ngFor="let e of message">
      <app-notifications [message]="e" [type]="1"></app-notifications>
    </div>
  </div>
  <div style="margin-top: -30px">
    <app-add-address-form
      (address)="reciveDataFromChild($event)"
    ></app-add-address-form>
  </div>
  <div class="btn-container">
    <button class="cancel" (click)="close()">Cancel</button>
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="submit()">Add</button>
    </div>
  </div>
</div>
