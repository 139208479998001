<div class="modal-container">
  <h2>Invoice Amount: {{ finalAmount }}</h2>
  <div class="payment-mode-display">
    <label>Payment Mode</label>
    <div class="selected-modes">
      {{selectedModesText}}
    </div>
  </div>
  <div class="amount-inputs">
    <label>Enter Amount Collected</label>
    <span *ngFor="let mode of data.modes">
      <input class="no-spinner" type="number" [placeholder]="mode.item_text + ' Amount'" [(ngModel)]="amounts[mode.item_text]" (ngModelChange)="calculateTotal()">
    </span>
  </div>
  <div *ngIf="showReasonInput"  class="amount-inputs">
    <label>Reason for Less Payment</label>
    <input type="text" [(ngModel)]="reason" placeholder="Enter reason">
  </div>
  <div class="button-container">
    <button class="btn primary" (click)="onCancel()">Cancel</button>
    <button class="btn primary" (click)="onSubmit()">Confirm</button>
  </div>
</div>