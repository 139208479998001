<button class="sort-button" [matMenuTriggerFor]="menu">
  {{ dropdownName }}&nbsp;<img
    src="{{ assets }}assets/images/icons/dropdown.svg"
    alt="dropdown-icon"
  />
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>
