<div class="price">
  <div class="price-left">
    <app-price-sidebar
      (trigger)="receiveDataFromChild($event)"
    ></app-price-sidebar>
  </div>
  <div class="price-right">
    <div class="searchBarContainer"></div>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="searching-container">
              <div class="first-half-section">
                <div class="search-bar-section">
                  <input type="text"
                         class="search-user"
                         placeholder="Search Cloths"
                         [(ngModel)]="searchText"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <section class="customer-directory-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <table *ngIf="cloths && cloths.length > 0">
                <tr class="header-tr">
                  <td class="header name"><b>Sr No</b></td>
                  <td class="header">Type</td>
                  <td class="header"><b>Cloth</b></td>
                  <td class="header"><b>Price</b></td>
                </tr>
                <tr *ngFor="let cloth of cloths | searchPipe: searchText; let i = index">
                  <td class="">
                    <span><b class="green-text">{{ i + 1 }}</b></span>
                  </td>
                  <td class="">
                    <div class="clothType">
                      <span>{{ cloth?.category }}</span>
                    </div>
                  </td>
                  <td>
                    <span>{{ cloth?.name }}</span>
                  </td>
                  <td>Rs. {{ cloth.store_price ? cloth.store_price : cloth.price }}</td>
                </tr>
                <tr *ngIf="cloths.length == 0">
                  <th colspan="4" align="center" style="width: 100%">No Data Found.</th>
                </tr>
              </table>
              <table *ngIf="services && services.length > 0">
                <tr class="header-tr">
                  <td class="header">Service</td>
                  <td class="header"><b>Price</b></td>
                </tr>
                <tr *ngFor="let service of services | searchPipe: searchText;">
                  <td class="">
                    <div class="clothType">
                      <span>{{ service?.name }}</span>
                    </div>
                  </td>
                  <td>
                    Rs. {{ service.store_price ? service.store_price : service.price }}
                    {{service.quantity_based_service ? (service.parenttrade_id == 10 ? 'Per Item' : 'Per KG'): ''}}
                  </td>
                </tr>
              </table>
              <br/>
              <table *ngIf="addons && addons.length > 0">
                <tr class="header-tr">
                  <td class="header">Addons</td>
                  <td class="header"><b>Price</b></td>
                </tr>
                <tr *ngFor="let addon of addons | searchPipe: searchText;">
                  <td class="">
                    <div class="clothType">
                      <span>{{ addon?.name }}</span>
                    </div>
                  </td>
                  <td>
                    Rs. {{ addon.store_price ? addon.store_price : addon.price }}
                    {{addon.type == '1' ? 'Per KG' : 'Per Item'}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
